import React from 'react'

import {
    NotificationBox,
    NotificationText,
    NotificationTitle,
    CheckedCircle,
    NotificationContent
} from "./styles";


const BlueNotificationBox = (props) => {
    const checkPic = "/images/components/NotificationBlueBox/CheckNotification.png"
  return (
    <NotificationBox onClick = {props.onClick}>
        <CheckedCircle src = {checkPic}></CheckedCircle>
        <NotificationContent>
            <NotificationTitle>{props.notificationTitle}</NotificationTitle>
            <NotificationText>{props.notificationText}</NotificationText>
        </NotificationContent>
    </NotificationBox>
  )
}

export default BlueNotificationBox