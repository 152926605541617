import React, { useState, useEffect } from "react";
import PageWrapper from "../PageWrapper";
import {
  FlexContainer,
  ICard,
} from "./styles";

import { useHistory } from "react-router-dom";

import Meet from "../../components/Meet";
import YourTherapistCard from "../../components/YourTherapistCard";

// import yourTherapist from "../../data/people/yourTherapist";
// import susie from "../../data/people/susie";

import {
  sendCancellation,
  sendRescheduleRequest,
} from "../../utils/UserAppApi/emailSenders";
import { useStore } from "../../utils/Zustand/store";
import {
  ReadDBProfile,
  ReadDBSessions,
  UpdateDBSession,
  GetUserTherapists,
  UpdateDBProfile,
} from "../../utils/UserAppApi/profileOperations";
import useAsyncEffect from "use-async-effect";
import moment from "moment";

const Dashboard = () => {
  // susie is only profile
  const susie = useStore((state) => state.profile);
  const updateProfile = useStore((state) => state.updateProfile);

  // therapist getter and setter
  const therapist = useStore((state) => state.therapist);
  const updateTherapist = useStore((state) => state.updateTherapist);

  // profileId is used as a reference, but set should only be used for initial linking from authentication to Zustand.
  const profileId = useStore((state) => state.profileId);
  // const setProfileId = useStore(state => state.setProfileId);

  const firstConsecutiveLogin = useStore(
    (state) => state.firstConsecutiveLogin
  );
  const setFirstConsecutiveLogin = useStore(
    (state) => state.setFirstConsecutiveLogin
  );

  const consecutiveWeeks = useStore((state) => state.consecutiveWeeks);
  const setConsecutiveWeeks = useStore((state) => state.setConsecutiveWeeks);

  //add sessions request
  const addAdditionalSessionsRequest = useStore(
    (state) => state.addSessionsRequest
  );
  const sessionsAlreadyRequested = useStore((state) => state.sessionsRequested);
  const setSessionsRequested = useStore((state) => state.setSessionsRequested);

  //msgtomarvin state in zustand
  // const msgToMarvin = useStore(state => state.msgToMarvin);
  const updateMsg = useStore((state) => state.updateMsg);

  //session zustand state
  const sessions = useStore((state) => state.sessions);
  const updateSessions = useStore((state) => state.updateSessions);


  // grab updateGuestSession function from store
  const updateGuestSession = useStore((state) => state.updateGuestSession);



  // When the user logs in, gets all their data

  console.log("This should happen once?");
  const [loadFromDBFinished, setLoadFromDBFinished] = useState(false);
  useAsyncEffect(async () => {
    console.log("ProfileId: ", profileId);
    const profileFromDB = await ReadDBProfile(profileId);
    const sessionsFromDB = await ReadDBSessions(profileId);

    // Therapist not yet wired, needs to be completed in being passed to where it is needed (yourTherapistCard)
    const therapistsFromDB = await GetUserTherapists(profileId);
    const therapistFromDB = therapistsFromDB[0];

    // console.log("In dashboard, fromdb spread: ", {...profileFromDB});
    // console.log("In dashboard, sessionsFromDB: ", sessionsFromDB);
    let unstringifiedRace;
    if (profileFromDB.race) {
      unstringifiedRace = JSON.parse(profileFromDB.race);
    }
    // console.log("Being passed to updateProfile: ", { ...profileFromDB, race: unstringifiedRace });
    // console.log("Here's a moment converted from an ISO string: ", moment("2021-12-12T08:30:00+0000").format('MMM Do YYYY, h:mm:ss a'));
    // console.log("here's the new therapist: ", therapistFromDB);
    updateTherapist({ ...therapistFromDB });
    if (sessionsFromDB.error){
      console.log("Error in API")
    } else {
      updateSessions(sessionsFromDB);
    }
    updateProfile({ ...profileFromDB, race: unstringifiedRace });
    setLoadFromDBFinished((prevstate) => !prevstate.loadFromDBFinished);
  }, []);

  useEffect(() => {
    console.log(susie);
  }, [susie]);

  const [calculationFinished, setCalculationFinished] = useState(false);
  // calculates the user's consecutive weeks logged in
  useEffect(() => {
    let currDate = Math.floor(Date.now() / 1000 / 60 / 60 / 24); // Number of days since January 1, 1970 00:00:00 UTC.
    let daysSinceFirst = currDate - firstConsecutiveLogin;
    let weeksSinceFirst = Math.ceil(daysSinceFirst / 7);

    // This is the first time the user is ever logging in OR it has been more than two weeks since they last logged in
    if (firstConsecutiveLogin === 0 || weeksSinceFirst > consecutiveWeeks + 1) {
      setFirstConsecutiveLogin(currDate);
      setConsecutiveWeeks(1);

    } // It has been one week since the last login.
    else if (weeksSinceFirst === consecutiveWeeks + 1) {
      setConsecutiveWeeks(consecutiveWeeks + 1);
    }

    setCalculationFinished((prevstate) => !prevstate.calculationFinished);
  }, [loadFromDBFinished]);

  useEffect(() => {
    UpdateDBProfile({ consecutiveWeeks, firstConsecutiveLogin }, profileId);
  }, [calculationFinished]);

  const submitRescheduleRequest = (sessionObject) => {
    updateSessionVal(sessionObject, "rescheduled");
    // sessionObject has values:
    // date._d: Moment,
    // sessionId: int,
    // descr: String
    console.log("Rescheduling!");
    console.log(
      "date: ",
      moment(sessionObject.sessionDate).format("MMMM Do YYYY, h:mm a")
    );
    console.log("id: ", sessionObject.id);
    console.log("descr: ", sessionObject.descr);

    const emailContent = {
      subject: `New Session Reschedule Request`,
      userId: profileId,
      fullName: susie.firstName + " " + susie.lastName,
      userEmail: susie.email,
      userPhone: susie.phoneNumber,
      sessionId: sessionObject.id,
      date: moment(sessionObject.sessionDate).format("MMMM Do YYYY, h:mm a"),
      message: sessionObject.descr,
    };
    sendRescheduleRequest(emailContent);
    console.log("Done");
  };

  const updateSessionVal = (sessionObj, propertyName) => {
    console.log(
      `Updating val in sessions with session id`,
      sessionObj.sessionId
    );
    //Determine which property and set to true
    if (propertyName === "canceled") {
      sessionObj.requestedCancel = true;
      UpdateDBSession(sessionObj.id, { requestedCancel: true });
    } else if (propertyName === "rescheduled") {
      sessionObj.requestedReschedule = true;
      UpdateDBSession(sessionObj.id, { requestedReschedule: true });
    }

    let newTmpSessions = sessions.filter(
      (session) => session.id != sessionObj.id
    );

    newTmpSessions.push(sessionObj);

    //grab old zustand session array
    updateSessions(newTmpSessions);
  };

  useEffect(() => {
    console.log(`here is the zustand sessionsOBj `, sessions);
    // console.log(object)
  }, [sessions]);

  const submitCancellation = (sessionObject) => {
    updateSessionVal(sessionObject, "canceled");

    console.log("Canceling!");
    console.log(
      moment(sessionObject.sessionDate).format("MMMM Do YYYY, h:mm a")
    );
    console.log(sessionObject.id);

    const emailContent = {
      subject: `New Session Cancellation`,
      userId: profileId,
      fullName: susie.firstName + " " + susie.lastName,
      userEmail: susie.email,
      userPhone: susie.phoneNumber,
      sessionId: sessionObject.id,
      date: moment(sessionObject.sessionDate).format("MMMM Do YYYY, h:mm a"),
    };
    sendCancellation(emailContent);
  };

  // Combines the boolean toggle and list push into one function
  const submitSessionsRequest = (request) => {
    addAdditionalSessionsRequest(request);
    setSessionsRequested(true);
  };


  //handle history for routing
  let history = useHistory();

  const meetMTOptionHandler = () => history.push("/meetMT");

  const meetCTOptionHandler = () => history.push("/meetCT");

  const marvinWavesHandler = (sessionLink) => {
    console.log(`sessionLink: ${sessionLink}`)
    history.push(`${sessionLink}`);
    updateGuestSession(false);
  };

  const tab = "meet";

  return (

        <PageWrapper  tab = {tab}
                      title={"Hi " + susie.firstName + "!"}
                      consWeeksLogged={consecutiveWeeks}>
          <FlexContainer>
            <ICard>
              <Meet
                marvinWavesHandler={marvinWavesHandler}
                sessions={sessions}
                addRescheduleRequest={(rescheduleRequest) =>
                  submitRescheduleRequest(rescheduleRequest)
                }
                addSessionsRequest={(requestForSessions) =>
                  submitSessionsRequest(requestForSessions)
                }
                cancelSession={(sessionObj) => submitCancellation(sessionObj)}
                sessionsAlreadyRequested={sessionsAlreadyRequested}
              />
            </ICard>
            <ICard>
              <YourTherapistCard
                {...therapist}
                sessions={sessions}
                handleViewDetailsButton={meetMTOptionHandler}
                handleContactButton={meetCTOptionHandler}
              />
            </ICard>
          </FlexContainer>
        </PageWrapper>

  );
};

export default Dashboard;
