import { useState } from "react";

import AdjustableSpacer from "../AdjustableSpacer";
import SearchBar from "../SearchBar";
import ExerciseCard from "../ExerciseCard";
import PlayVideo from "../PlayVideo";
// import Banner from "../Banner";

import {
  DisplayExerciseBox,
  ExercisesList,
  PlayButton,
  VideoBox,
  DetailsBox,
  Icon,
  ExerciseType,
  IconTypeRow,
  ExerciseTitle,
  ExerciseDescr,
  PlayIcon,
  ExerciseNarratedBy,
  PosterImg,
} from "./styles";

const GrowCard = (props) => {
  const [filteredExercises, setFilteredExercises] = useState(
    props.exerciseCardArray.sort((a, b) =>
      a.exerciseName.localeCompare(b.exerciseName)
    )
  );

  const handleSearch = (searchKey) => {
    // Sets the "Filtered exercises" array to only contain elements with a title or description
    // that contains the searchKey.
    setFilteredExercises(
      props.exerciseCardArray
        .filter((card) => {
          return (
            card.exerciseName.toLowerCase().includes(searchKey.toLowerCase()) ||
            card.exerciseDescription
              .toLowerCase()
              .includes(searchKey.toLowerCase())
          );
        })
        .sort((a, b) => a.exerciseName.localeCompare(b.exerciseName))
    );
  };

  //Handler for when an exercise is opened, should display
  const [videoState, playVideo] = useState(false);
  const [videoIndex, setVideoIndex] = useState(0);

  const handlePlayVideo = () => {
    playVideo(true);
  };
  const handleStopVideo = () => {
    playVideo(false);
  };

  // const videoArray = props.videoArray;
  // console.log(props.exerciseCardArray[videoIndex]);

  return (
    <>
      {props.tab === "growVideo" ? (
        <>
          <AdjustableSpacer height="37px" />
          <DisplayExerciseBox>
            <VideoBox>
              {videoState ? (
                <PlayVideo
                  controls={true}
                  showVideo={videoState}
                  hideVideo={handleStopVideo}
                  url={filteredExercises[videoIndex].video}
                >
                  {/* <source  src = {props.ExerciseVideo ? props.ExerciseVideo : "images/pages/VideoSession/tumor_growth.mp4"}/> */}
                  {/* <source  src={filteredExercises[videoIndex].video}/>      */}
                </PlayVideo>
              ) : (
                <>
                  {/* <PosterImg src="images/components/ExerciseCard/VidBG.svg"/> */}
                  <PosterImg src={filteredExercises[videoIndex].poster} />
                  <PlayButton onClick={handlePlayVideo}>
                    <PlayIcon src="/images/components/ExerciseCard/PlayIcon.svg" />
                  </PlayButton>
                </>
              )}
            </VideoBox>
            <AdjustableSpacer width="3%" />
            <DetailsBox>
              <IconTypeRow>
                <Icon></Icon>
                <AdjustableSpacer width="12px" />
                <ExerciseType>
                  {filteredExercises[videoIndex].exerciseType
                    ? filteredExercises[videoIndex].exerciseType
                    : "Visualization video"}
                </ExerciseType>
              </IconTypeRow>
              <AdjustableSpacer height="23px" />
              <ExerciseTitle>
                {filteredExercises[videoIndex].exerciseTitle
                  ? filteredExercises[videoIndex].exerciseTitle
                  : "The art of visualization"}
              </ExerciseTitle>
              <AdjustableSpacer height="15px" />
              <ExerciseDescr>
                {filteredExercises[videoIndex].exerciseDescr
                  ? filteredExercises[videoIndex].exerciseDescr
                  : "Learn the art of visualization, the most important skill to achieve your peak performance. See yourself competing at the highest level, absorbing the emotions and becoming one with your thoughts, words, and actions."}
              </ExerciseDescr>
              <AdjustableSpacer height="15px" />
              <ExerciseNarratedBy>
                {filteredExercises[videoIndex].narrated
                  ? filteredExercises[videoIndex].narrated
                  : "Narrated by: Diana McNab."}
              </ExerciseNarratedBy>
              <ExerciseNarratedBy>
                Visuals by: Life through Fiction Productions
              </ExerciseNarratedBy>
            </DetailsBox>
          </DisplayExerciseBox>
        </>
      ) : (
        <>
          {/*<Banner type="therapist" /> */}
          <AdjustableSpacer height="20px" />
          <SearchBar handleSearch={handleSearch} />
          <AdjustableSpacer height="20px" />
          <ExercisesList>
            {/* Sort from https://stackoverflow.com/questions/8900732/sort-objects-in-an-array-alphabetically-on-one-property-of-the-array */}
            {filteredExercises.map((val, index) => {
              return (
                <ExerciseCard
                  handleOpenExercise={() => {
                    props.growVideoOptionHandler();
                    setVideoIndex(index);
                  }}
                  {...val}
                  // topPic = "images/components/ExerciseCard/powerAnimal.svg"
                  // icon = "images/components/ExerciseCard/video.svg"
                  // exerciseDetails = "Visualization video • 5 min."
                  // exerciseName = "The art of visualization"
                  // exerciseDescription = "Quisque velit nisi, pretium ut lacinia in, elementum id enim."
                />
              );
            })}
            <AdjustableSpacer height="36px" width="100%" />
          </ExercisesList>
        </>
      )}
    </>
  );
};

GrowCard.defaultProps = {
  exerciseCardArray: [],
  // videoArray: [],
};

export default GrowCard;
