import styled from "styled-components";

export const ChatBoxContainer = styled.div`
  /* Rectangle 117 */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  min-width: 352px;
  height: 70vh;
  left: 0%;
  // right: 30px;
  bottom: 100px;
  background: rgba(0, 0, 0, 0.75);

  /* Blur/ Video Session BG */
  backdrop-filter: blur(66px);

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  overflow-y: hidden;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Footer = styled.div``;

export const TypeBoxContainer = styled.div`
  position: relative;
`;
export const CBTitle = styled.span`
  /* Chat */
  /* Mobile / Header / 2 */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #ffffff;
`;

export const TypeBox = styled.textarea`
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
  height: 74px;
  padding: 16px 62px 16px 16px;
  box-sizing: border-box;
  resize: none;

  background: transparent;
  border: 1px solid transparent;
  border-color: #ffffff;
  border-radius: 16px;

  :focus {
    outline: none;
  }

  /* Mobile/Input Field */
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 110%;

  /* identical to box height, or 14px */
  letter-spacing: 0.25px;

  /* Flat / White */

  color: #fafafa;
  mix-blend-mode: normal;
  opacity: 0.8;

  &::placeholder {
    color: #fafafa;
  }
`;

export const SendBtn = styled.img`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 16px;
  bottom: 28px;

  border-radius: 100px;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const CloseImg = styled.img`
  /* Close Chat */
  position: absolute;
  width: 40px;
  height: 40px;
  right: 0px;
  top: 10.5px;
  &:hover {
    cursor: pointer;
  }
`;
