import styled from "styled-components";

export const OuterVideoBox = styled.div`
  position: relative;
  width: 258px;
  height: 145px;
  /* Gray / 1 */
  background: #333333;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
 /* border: red solid 5px; */
 

  width: ${(props) => (props.width ? props.width : "258px")};

  height: ${(props) => (props.height ? props.height : "145px")};
`;


export const StopCameraBox = styled.div`   
  width: ${props => props.tiled ? "100%" : "258px"};
  height: ${props => props.tiled ? "100%" : "145px"};
  /* Gray / 1 */
  background: #333333;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

`;



export const FilmVidViewOverLay = styled.div`  
    position: absolute ;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% ;
    z-index:6;
    display:flex ;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:hover{
        background: rgba(0, 0, 0, 0.75);
        border-radius: 6px;
        z-index:5;
    }
`;

export const CameraOffIcon = styled.img`
  width: 22px;
  height: 19px;
`;

export const MicrophoneOffIcon = styled.img`
  width: 17px;
  height: 23px;

`;

export const OnlyMicMutedIcon = styled.img`
  width: 17px;
  height: 23px;
  background-image: url("images/pages/VideoSession/SlashedMicrophone.svg");
  z-index: 5;
`;

//Styled components for cameraOn state
export const MicRow = styled.div`
  position: absolute;
  display: flex;
  align-self: flex-end;
  height: 23px;
  z-index: 3;
  bottom: 10px;
  left: 15px;
`;



//Styled Components for cameraOff State
export const MicCamRow = styled.div`
  display: flex;
  align-self: flex-end;
  height: 23px;
  margin-left: 15px;
  margin-bottom: 10px;
  margin-right: auto;
`;



export const StopCameraBoxNameCircle = styled.div`
  /* Gradients/Blue Purple */
  background: linear-gradient(to top left, #52b1fc, #4e73fe);
  height: 62px;
  width: 62px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

export const StopCameraBoxInitial = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;

  color: #ffffff;
`;

export const NameLabel = styled.input`
  /* background:transparent; */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: normal;
  border: none;
  top: 79px;
  left: 111px;
  z-index: 3;
`;

//************************User Video**************************
export const UserVideo = styled.video`
  // width: 258px;
  // height: 145px;
  display: ${(props) => (props.displayVid ? "initial" : "none")};
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 6px;
  object-fit: cover;
  transform: ${(props) =>
    props.desktop ?  "rotateY(0deg)" : "rotateY(180deg)"};

  /* position: relative; */
 
`;

//*************************HideSelfView******************


export const HideSelfViewIcon = styled.img`
  display: none;
  /* background-image: url(; */
  height: 35px;
  width: 35px;
  background-size: contain;
  position: absolute;
  // margin-left: 116.5px;
  // margin-top: 75px;
  top: 79px;
  left: 111px;
  z-index: 3;

  ${FilmVidViewOverLay}:hover & {
    display: initial;
  }
`;

export const HideSelfText = styled.div`
  /* BottomNav */
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  // text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
  width: 83px;
  position: absolute;
  top: 122px;
  left: 90px;
  z-index: 3;
  bottom: 0px;
  display: none;
  ${FilmVidViewOverLay}:hover & {
    display: initial;
  }
`;


export const ShowSelfViewIcon = styled.img`
  height: 35px;
  width: 35px;
  background-size: contain;
  z-index: 3;
  display: none;

  ${FilmVidViewOverLay}:hover & {
    display: initial;
  }
`;



export const ShowSelfText = styled.div`
  /* BottomNav */
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;
  text-align: center;
  letter-spacing: 0.01em;

  color: #ffffff;
  display: none;
  ${FilmVidViewOverLay}:hover & {
    display: initial;
  }
`;

