import styled from "styled-components";

export const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 450px;
  height: 296px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Up - Height >111 */
  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
`;

export const Title = styled.div`
  /* Desktop/Header/3 */
  align-items: left;
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const SaveBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 8px 24px; */
  width: 168px;
  height: 39px;

  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;

  /* Buttons/Primary Disabled */
  background: ${(props) =>
    props.changesMade
      ? "linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, #6ADCB3 -14.42%, #6ACCDC 99.59%);"
      : "linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, rgba(106, 220, 179, 0.6) -14.42%, rgba(106, 204, 220, 0.6) 99.59%);"};
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Disabled */
  box-shadow: ${(props) =>
    props.changesMade
      ? "0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 223, 219, 0.7);"
      : "box-shadow: inset 2px 2px 4px rgba(115, 208, 208, 0.3), inset -2px -2px 4px rgba(213, 249, 241, 0.4);"};
  border-radius: 75px;
`;

export const SaveBtnTxt = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  color: #fefefe;

  mix-blend-mode: normal;
`;

export const StdBoxContainer = styled.div`
  width: 318px;
`;

export const SaveChangesBtn = styled.button`
  /* all:unset; */
  width: 168px;
  height: 39px;
  /* padding: 8px 24px; */

  /* Buttons/Primary Disabled */
  background: ${(props) =>
    props.changesMade
      ? "linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, #6ADCB3 -14.42%, #6ACCDC 99.59%);"
      : "linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, rgba(106, 220, 179, 0.6) -14.42%, rgba(106, 204, 220, 0.6) 99.59%);"};
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Disabled */
  box-shadow: ${(props) =>
    props.changesMade
      ? "0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 223, 219, 0.7);"
      : "box-shadow: inset 2px 2px 4px rgba(115, 208, 208, 0.3), inset -2px -2px 4px rgba(213, 249, 241, 0.4);"};
  border-radius: 75px;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  border: none;

  /* Font  */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fefefe;
  mix-blend-mode: normal;

  /* Alignment */
  align-self: center;
  text-align: center;
  &:hover {
    &:enabled {
      cursor: pointer;

      /* Buttons/Primary Hover */
      background: linear-gradient(
          342.94deg,
          rgba(255, 255, 255, 0.4) -182.89%,
          rgba(0, 0, 0, 0.4) 132.93%
        ),
        linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
      background-blend-mode: soft-light, normal;

      /* Buttons/Primary Small /Hover */
      box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
        inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
    }
  }
`;

export const NeedHelpText = styled.p`
  color: #333333;
  
  font-family: Raleway;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  margin: 0;
`;