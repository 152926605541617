import { useRef, useEffect, useState } from "react";

import { OuterVideoBox, UserVideo, InitialCircle, BG } from "./styles";

const VideoView = (props) => {

  const localVideoRef = useRef(null);

  const {displayName, sharing, cameraOn, desktopStream, videoStream} = props.focusedParticipant;
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    console.log(`Focused participant has changed!!`)

    if(videoStream && localVideoRef.current){
      console.log(`Setting to videotrack`);
      localVideoRef.current.srcObject = videoStream;
      setIsDesktop(false);
    }
    if(desktopStream && localVideoRef.current){
      console.log(`Switching to desktop stream`);
      localVideoRef.current.srcObject = desktopStream; 
      setIsDesktop(true);
    }
  }, [props.focusedParticipant]);

  
  useEffect(() => {
    console.log(`isDesktop Value: `, isDesktop)
  }, [isDesktop])

  const showFullVidViewProps = () => {
    console.log(`Here is fullvidview props:`);
    console.log(props);
  };

  return (
    <OuterVideoBox onClick={showFullVidViewProps} >
      <UserVideo
        desktop={isDesktop}
        autoPlay="1"
        muted
        displayVid={(cameraOn || isDesktop)}
        ref={localVideoRef}
        focusedParticipant = {props.focusedParticipant}
      />

      {(cameraOn || isDesktop)? null : (
        <>
          <BG>
            <InitialCircle color={props.color}>
              
              {displayName ? displayName.slice(0,1) : "M"}
            </InitialCircle>
          </BG>
        </>
      )}
    </OuterVideoBox>
  );
};

export default VideoView;
