import styled from "styled-components";

export const ButtonBox = styled.div`
  width: 103px;
  height: 27px;
`;

export const Text = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  /* Gray / 3 */
  color: #a2aecb;
  &:hover {
    color: #333333;
  }
`;

export const TextActive = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  color: #fafafa;
`;

export const InactiveButton = styled.div`
  /* White Elements/Default */
  width: 103px;
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ButtonActive = styled.div`
  width: 103px;
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  /* padding-right: 15px;
    padding-left: 15px;
  */

  /* Gradients/Nav Selected */
  background: linear-gradient(
      321.07deg,
      rgba(0, 0, 0, 0.6) 1.82%,
      rgba(255, 255, 255, 0) 98.83%
    ),
    #618cf1;
  background-blend-mode: soft-light, normal;

  /* Desktop/Down - Nav Selected */
  box-shadow: inset 5px 5px 16px #3d6ad3, inset -0.5px -0.5px 9px #658ff2;
  border-radius: 14px;
`;
