import styled from "styled-components";

export const ExerciseCardContainer = styled.div`
  // display: flex;
  // flex-direction: column;
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Up - Height >111 */
  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  width: 244px;
  height: 357px;
  &:hover {
    cursor: pointer;
    /* White Elements/Desktop White Hover >50 */
    background: linear-gradient(
        338.32deg,
        rgba(255, 255, 255, 0.12) 9.36%,
        rgba(92, 112, 162, 0.12) 87.33%
      ),
      #e3e8f0;

    /* Desktop White/Up - Height >111 */
    box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
      inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
    border-radius: 16px;
  }

  overflow-y: hidden;
  margin-right: 36px;
  margin-bottom: 36px;
`;

export const TopPic = styled.div`
  background-image: url(${(props) =>
    props.bg
      ? props.bg
      : "images/components/ExerciseCard/ExampleExercise.png"});
  background-size: contain;
  width: 244px;
  height: 207px;
  background-repeat: no-repeat;
`;

export const BottomBox = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: flex-start;
  padding: 0px 20px;
`;

export const DetailsStrip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
`;

export const Icon = styled.div`
  background-image: url(${(props) =>
    props.icon ? props.icon : "images/components/ExerciseCard/camera.svg"});
  background-size: contain;
  width: 15px;
  height: 15px;
`;

export const ExerciseDetails = styled.span`
  width: 150px;
  /* height: 19px; */

  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  /* identical to box height */

  /* Gray / 1 */
  color: #333333;
`;

export const ExerciseName = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;

  /* Gray / 1 */
  color: #333333;
`;

export const ExerciseDescription = styled.span`
  /* Desktop / Body / 2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* width: 196px; */
  /* Gray / 1 */
  color: #333333;
  word-wrap: break-word;
`;

export const DisplayBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const VideoBox = styled.div``;

export const PlayButton = styled.div`
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-shadow: -1.66667px -1.66667px 5px rgba(36, 79, 145, 0.06),
    2.5px 2.5px 10px rgba(40, 81, 104, 0.22),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 100px;

  &hover {
    cursor: pointer;
    /* White Elements/Desktop White Hover >50 */
    background: linear-gradient(
        338.32deg,
        rgba(255, 255, 255, 0.12) 9.36%,
        rgba(92, 112, 162, 0.12) 87.33%
      ),
      #e3e8f0;
    box-shadow: 0px 6px 14px rgba(68, 79, 108, 0.44),
      inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  }
`;
