// Accepts an image file, encodes to base64, performs a callback with the result.
const encodeBase64String = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        let Base64 = reader.result;
        console.log("Executing callback with: ", {Base64})
        callback(Base64)
    };
        reader.onerror = (error) => {
        console.log("error: ", error);
    };
};

export default encodeBase64String;