export const genderIdentitiesList = [
  "Male",
  "Female",
  "Trans Male",
  "Trans Female",
  "Intersex/Nonbinary",
  "Other",
];

export default genderIdentitiesList;
