import styled from "styled-components";

export const DashBoardContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: row ;
  justify-content: center;
  align-items: center;
  background: url("/images/components/Dashboard/DashboardBG.svg") no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

`;


export const MarvinIcon = styled.image`
      width: 300px;
      height: 400px; 

`;


export const ErrorTitle = styled.div`   

    /* Mobile/Button/1 */
    font-family: Syne;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
`;


export const ErrorText = styled.div`  

    /* Mobile/Button/1 */
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;

`;