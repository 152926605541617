import AdjustableSpacer from "../AdjustableSpacer";
import SessionList from "../SessionList";
import MeetZero from "../MeetZeroState";
import moment from "moment";

import { useStore } from "../../utils/Zustand/store";

import {
  RoundedRec,
  MeetHeader,
  MarginBox,
  ContentBox,
  Group643,
  MarvinWaves,
  RowOne,
  DateBox,
  Date,
  Time,
} from "./styles";
import { useEffect } from "react";

const Meet = (props) => {
  console.log("Props.sessions is: ", props.sessions);
  
  let now = moment();
  let sortedSessions;
  let zeroState = false;

  const updateSessionRoom = useStore((state) => state.updateSessionRoom);
  
  const sessionRoom = useStore((state) => state.sessionRoom);


  // Filters out all sessions that are canceled, rescheduled, or have already passed
  const filteredSessions = props.sessions.filter(
    (session) =>
      session.requestedCancel === false && 
      session.requestedReschedule === false && 
      moment(session.sessionDate).isSameOrAfter(now.subtract(1, "h"))
  );

  const numSessions = filteredSessions.length;
  
  // If the number of sessions after filtering is 0, then set zeroState 
  if (numSessions < 1) {
    zeroState = true;
  } else {
    //Sort props.sessions by sessionDate
    sortedSessions = filteredSessions.sort((a, b) => {
      return moment(a.sessionDate).diff(b.sessionDate);
    });
  }
  

  return (
    <>
      {zeroState ? (
        <MeetZero></MeetZero>
      ) : (
        <RoundedRec>
          <MarginBox>
            <AdjustableSpacer width="45px" />
            <ContentBox>
              <AdjustableSpacer height="25px" />
              <MeetHeader>Your next Marvin session</MeetHeader>
              <AdjustableSpacer height="16px" />
              <RowOne>
                <Group643 ></Group643>
                <MarvinWaves onClick = {() => props.marvinWavesHandler(sortedSessions[0].sessionLink)}></MarvinWaves>
                <DateBox>
                  <Date>
                    {moment(sortedSessions[0].sessionDate).format("dddd")},{" "}
                    {moment(sortedSessions[0].sessionDate).format("MMMM")}{" "}
                    {moment(sortedSessions[0].sessionDate).format("D")}
                  </Date>
                  <Time>
                    {moment(sortedSessions[0].sessionDate).format("h")}:
                    {moment(sortedSessions[0].sessionDate).format("mm")}{" "}
                    {moment(sortedSessions[0].sessionDate).format("A")}
                  </Time>
                </DateBox>
              </RowOne>
              <SessionList
                numSessions={numSessions}
                addSessionsRequest={(requestForSessions) =>
                  props.addSessionsRequest(requestForSessions)
                }
                sortedSessions={sortedSessions}
                cancelSession={props.cancelSession}
                addRescheduleRequest={(rescheduleRequest) =>
                  props.addRescheduleRequest(rescheduleRequest)
                }
                {...props}
                sessionsAlreadyRequested={props.sessionsAlreadyRequested}
              ></SessionList>
            </ContentBox>
            <AdjustableSpacer width="45px" />
          </MarginBox>
        </RoundedRec>
      )}{" "}
    </>
  );
};
export default Meet;
