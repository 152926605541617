export const initialVideoParams = {// mediasoup params
        encodings: [
          {
            rid: 'r0',
            maxBitrate: 100000,
            scalabilityMode: 'S1T3',
          },
          {
            rid: 'r1',
            maxBitrate: 300000,
            scalabilityMode: 'S1T3',
          },
          {
            rid: 'r2',
            maxBitrate: 900000,
            scalabilityMode: 'S1T3',
          },
        ],
        // https://mediasoup.org/documentation/v3/mediasoup-client/api/#ProducerCodecOptions
        codecOptions: {
          videoGoogleStartBitrate: 1000
        }
    }
export const initialAudioParams = {
  codecOptions:
    {
      opusStereo : 1,
      opusDtx    : 1
    }
}

export const initialUserMedia = {
  audio: true,
  video: {
  width: {
      min: 640,
      ideal: 1920,
      max: 1920,
  },
  height: {
      min: 480,
      ideal: 1080,
      max: 1080,
  }
  }
}


export const initialDisplayParams = {
  video: {
    cursor: "always"
  },
    audio: false
}