import AdjustableSpacer from "../AdjustableSpacer";

import {
  MarginBox,
  Content,
  DetailsRow,
  DayCircle,
  Day,
  Details,
  Date,
  Time,
  X,
  BackButton,
  Warning,
  CancelButton,
  CancelText,
  DateTimeBox,
} from "./styles";

import moment from "moment";

const CancelBox = (props) => {
  // console.log(props.sessionObj);
  const day = moment(props.sessionObj.sessionDate).format("D");
  const month = moment(props.sessionObj.sessionDate).format("MMMM");
  const hour = moment(props.sessionObj.sessionDate).format("h");
  const minute = moment(props.sessionObj.sessionDate).format("mm");
  const dayCycle = moment(props.sessionObj.sessionDate).format("A");
  const dayOfWeek = moment(props.sessionObj.sessionDate).format("dddd");
  let timeDiff = moment(props.sessionObj.sessionDate).diff(
    props.currDate,
    "hours"
  );
  console.log("this si the timediff of this session foirm currdate");
  console.log(timeDiff);

  let beforeTwentyFourHrs = false;
  if (timeDiff < 24) beforeTwentyFourHrs = true;
  else beforeTwentyFourHrs = false;

  return (
    <MarginBox>
      <AdjustableSpacer width="20px" />
      <Content>
        <AdjustableSpacer height="15px" />
        <DetailsRow>
          <DateTimeBox>
            <DayCircle>
              <Day>{day}</Day>
            </DayCircle>
            <AdjustableSpacer width="23px" />
            <Details>
              <Date>
                Cancel {dayOfWeek}, {month} {day}
              </Date>
              {/* <AdjustableSpacer height = "3px"/> */}
              <Time>
                {hour}:{minute} {dayCycle}
              </Time>
            </Details>
          </DateTimeBox>
          {/* <AdjustableSpacer width = "90px"/> */}
          <BackButton>
            <X onClick={props.handleBack}></X>
          </BackButton>
        </DetailsRow>
        <AdjustableSpacer height="20px" />
        <>
          {beforeTwentyFourHrs ? (
            <Warning>
              Are you sure you want to cancel your session on {dayOfWeek},{" "}
              {month} {day} at {hour}:{minute} {dayCycle}? Since this
              appointment is less than 24 hours away, a $40 fee will be charged
              to your account to compensate your therapist for their time.
            </Warning>
          ) : (
            <Warning>
              {/* Are you sure you want to cancel your session on 
                                          {dayOfWeek}, {month} {day} at {hour}:{minute} {dayCycle}? */}
              Are you sure you want to cancel your session?
            </Warning>
          )}
        </>
        <AdjustableSpacer height="20px" />
        <CancelButton
          onClick={() => props.submitCancelRequest(props.sessionObj)}
        >
          <CancelText>
            {" "}
            {beforeTwentyFourHrs
              ? "I understand, cancel this session"
              : "Cancel this session"}
          </CancelText>
        </CancelButton>
      </Content>
      <AdjustableSpacer width="20px" />
    </MarginBox>
  );
};

export default CancelBox;
