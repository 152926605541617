import { FilmstripBarContainer } from "./styles.js";

import FilmVidView from "../../components/FilmVidView";

const FilmstripBar = (props) => {
  return (
    <FilmstripBarContainer update={props.update}>
      <>
        {props.filmStripList.map((val, index) => (
          <FilmVidView
            key={index}
            videoStream={val.videoStream}
            audioStream={val.audioStream}
            userId={props.remotePeersKeys[index]}
            handleFocusedParticipant={props.handleFocusedParticipant}
            micOn={val.micOn}
            cameraOn={val.cameraOn}
            displayName={val.displayName}
            update={props.update}
            // supportHiding={0}
            // initial={val.displayName.slice(0, 1)}
            desktopStream={val.desktopStream}
            fullScreenMode = {props.fullScreenMode}
          />
        ))}
      </>
    </FilmstripBarContainer>
  );
};

export default FilmstripBar;
