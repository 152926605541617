
import styled from "styled-components";

export const MobileBox = styled.div`

  display: none;
  @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5%;
      width: 375px;
      height: 674px;

    }
  
`;


export const MobileTitle = styled.div`
  /* Mobile/Header/1 */
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 5%;
  margin-top: 3%;
  /* Gray/1 */

  color: #333333;
`;

export const MobileMarvin = styled.img`
  width: 315px;
  height: 315px;
  border-radius: 16px;
  margin-bottom: 5%;
  @media screen and (max-width: 360px){
    width: 280px;
    height: 280px;
  }
`;

export const MobileDescription = styled.div`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  /* Gray/1 */
  color: #333333;
  margin-bottom: 5%;
  display: none;
  text-align: center ;

  @media screen and (max-width: 600px){
      display: initial;
  }

`;
export const TabletDescription = styled.div`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  /* Gray/1 */
  color: #333333;
  margin-bottom: 5%;
  display: none;
  @media screen and (min-width:601px){
    display: initial;
  }

`;

export const MobileButton = styled.div` 
  width: 327px;
  height: 49px;

  background: linear-gradient(19.39deg, #6ACCDC 5.07%, #6ADCB3 117.43%);

  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 208, 223, 0.7);
  border-radius: 75px;
  
  &:hover{
    cursor: pointer;
  } 
  display: none ;
  @media screen and (max-width: 600px){
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  }

`;
export const TabletButton = styled.div` 

  width: 327px;
  height: 49px;

  background: linear-gradient(19.39deg, #6ACCDC 5.07%, #6ADCB3 117.43%);
  /* Up / Primary Button */

  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 208, 223, 0.7);
  border-radius: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
  } 
  @media screen and (max-width: 600px){
    display: none;
  }

`;

export const MobileButtonText = styled.div`
  /* Mobile/Button/1 */

  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #FEFEFE;

  mix-blend-mode: normal
`;