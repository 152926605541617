import Input from "../InputBoxWithLabel";


import PrimaryIconButtonXL from "../PrimaryIconButtonXL";
import AdjustableSpacer from "../AdjustableSpacer";
import {
  Background,
  WaitingRoomContainer,
  VidSession,
  ScreenPreviewContainer,
  VidToolsNavbar,
  BeginSessionContainer,
  VideoBG,
  HeaderContainer,
  NameCircle,
  Name,
  BottomButton,
  Icon,
  TextBox,
  ButtonBox,
  Disclaimer
} from "./styles";

import { useEffect, useRef } from "react";

const WaitingRoom = (props) => {
  const videoRef = useRef(null);
  const videoAspectRef = useRef(null);

  const videoTrackRef = useRef(null);
  const audioTrackRef = useRef(null);

  useEffect(() => {
    return () => {
      if (videoTrackRef.current) videoTrackRef.current.stop();

      if (audioTrackRef.current) audioTrackRef.current.stop();

      if (videoRef.current) videoRef.current.srcObject = null;

      console.log("Cleaningup");
    };
  }, []);

  useEffect(() => {
    console.log("In this effect");
    if (props.localStream !== null) {

      console.log("In this effect 2");
      console.log("video aspect: ", videoAspectRef.current)
      console.log("ToF: ", !videoAspectRef.current)
      audioTrackRef.current = props.localStream.getAudioTracks()[0];
      videoTrackRef.current = props.localStream.getVideoTracks()[0];

      // use this to set videoref
      try {
        props.localStream.removeTrack(audioTrackRef.current);
      } catch (error) {
        console.log(error);
      }

      console.log("video track: ", videoTrackRef.current)

      if (videoTrackRef.current && !videoAspectRef.current ) {

        const height = videoTrackRef.current.getSettings().height
        const width = videoTrackRef.current.getSettings().width
        const aspect = width/height

        console.log("Height: ", height)
        console.log("Width: ", width)

        console.log("Set aspect: ", aspect);
        videoAspectRef.current = aspect
      }
          
    }

    videoRef.current.srcObject = props.localStream;
  }, [props.localStream]);


  const meetingNameRef = useRef(props.meetingName); 


  //Meetingname
  let letter = props.meetingName.substr(0, 1);

  const handleMute = () => {
    props.updateLocalMic(!props.localMic);
  };

  const handleCamera = () => {
    props.updateLocalCamera(!props.localCamera);

    if (props.localCamera && videoTrackRef.current) {
      videoTrackRef.current.enabled = false;
    }

    if (props.localCamera && videoTrackRef.current) {
      videoTrackRef.current.enabled = true;
    }
  };
  const handleNameChange = (e) => {
    props.setMeetingName(e.target.value);
    meetingNameRef.current = e.target.value;
    console.log(e.target.value);
  }
  useEffect(() => {
    console.log(`Toggling Mute: ${props.localMic}`);

    console.log(`Toggling Local Camera: ${props.localCamera}`);
  }, [props.localCamera, props.localMic]);

  return (
    <Background>
      {console.log("Video Ref: ", videoRef)}
      {console.log("local Stream: ", props.localStream)}
      <AdjustableSpacer height="100px" />

      <WaitingRoomContainer>
        <VidSession>
          <VideoBG
            autoPlay="1"
            ref={videoRef}
            localCamera={props.localCamera}
            aspect={videoAspectRef.current || 1.777777778}
          />
            <ScreenPreviewContainer localCamera={props.localCamera}>
              <NameCircle>
                <Name>{letter}</Name>
              </NameCircle>
            </ScreenPreviewContainer>
            
          <Disclaimer>
            Preview may appear distorted but will display correctly in session
          </Disclaimer>
          <AdjustableSpacer height="30px" />
          <VidToolsNavbar>
            {/* Mute Button */}
            <ButtonBox>
              <BottomButton toggledOn={props.localMic} onClick={handleMute}>
                <Icon
                  src={
                    props.localMic
                      ? "/images/pages/WaitingRoom/Microphone.svg"
                      : "/images/pages/WaitingRoom/SlashedMicrophone.svg"
                  }
                />
              </BottomButton>
              <TextBox>
                Mute/
                <br /> unmute
              </TextBox>
            </ButtonBox>
            {/* Camera on/off button */}
            <ButtonBox>
              <BottomButton
                toggledOn={props.localCamera}
                onClick={handleCamera}
              >
                <Icon
                  src={
                    props.localCamera
                      ? "/images/pages/WaitingRoom/Camera.svg"
                      : "/images/pages/WaitingRoom/SlashedCamera.svg"
                  }
                />
              </BottomButton>
              <TextBox>
                Stop/ start
                <br /> camera
              </TextBox>
            </ButtonBox>

            {/* Add/ remove virtual background */}
            {/* <ButtonBox>
              <BottomButton toggledOn={true} onClick={() => {}}>
                <Icon src="/images/pages/WaitingRoom/AddVirtualBackground.svg" />
              </BottomButton>
              <TextBox>
                Add/ remove
                <br /> virtual background
              </TextBox>
            </ButtonBox> */}
          </VidToolsNavbar>
       
            <AdjustableSpacer width="94px"  mobileHeight ="20px"/>
        </VidSession>
            <BeginSessionContainer>
              <Input
                inputLabel="Enter your name for the session"
                value={meetingNameRef.current}
                onChange={handleNameChange}
              ></Input>
              <AdjustableSpacer height="34px" />
              <PrimaryIconButtonXL
                labelText="Begin Marvin Session"
                onClick={props.bhandleClick}
              />
            </BeginSessionContainer>
      </WaitingRoomContainer>
    </Background>
  )
}

export default WaitingRoom;
