import styled from "styled-components";

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputField = styled.input`

  height: ${(props) => (props.height ? props.height : "40px")};
  width: 100%;
  /* Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 17px 20px 17px 14px;

  /* Background / Shadow*/
  background: #f6f6f6;
  box-shadow: inset -2.5px -2.5px 6px 1px #ffffff,
  inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
  border-radius: 8px;
  box-sizing: border-box;
  outline: none !important;
  /* border: none; */
  border: ${(props) => (!props.validated ? " 1px solid  #E73144" : "none")} ;

  :focus {
    border: 1px solid ${(props) => (!props.validated ? "#E73144" : "#618CF1")};
  }

  //Font Stuff
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #333333;    /* Gray / 1 */

  mix-blend-mode: normal;

`;

export const InputLabel = styled.div`
  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */
  color: #333333;

  mix-blend-mode: normal;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin-bottom: 10px;
`;

export const ShowHidePwdIcon = styled.div`
  position: absolute;
  right: 12px;
  top: 11px;
  width: 32px;
  height: 18px;
  flex: none;
  flex-grow: 0;
  
  background: url(${(props) => props.iconPath ? props.iconPath : "/images/pages/Settings/showPassword.svg"}) ; 
  background-size: cover;
  background-repeat: no-repeat; 
`;


export const PasswordInputContainer = styled.div`   

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  flex: none;
  flex-grow: 0;

`;