import React from "react";

import {
  ICard,
} from "./styles";

import { useHistory } from "react-router-dom";

import PageWrapper from "../PageWrapper";

import ContactTherapistCard from "../../components/ContactTherapistCard";

import msgArray from "../../data/msgArray";

const MeetCT = () => {
  let history = useHistory();
  
  const meetMTOptionHandler = () => history.push("/meetMT");

  const tab = "meetCT";

  return (
    
        <PageWrapper tab={tab} backToMeetMT={meetMTOptionHandler}>
          <ICard>
            <ContactTherapistCard msgArray={msgArray} />
          </ICard>
        </PageWrapper>
  );
};

export default MeetCT;
