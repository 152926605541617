// export const raceAndEthnicCategoryList = [
//     {value : "American Indian or Alaska Native", label: "American Indian or Alaska Native"},
//     {value: "Asian", label: "Asian"},
//     {value: "Black or African American", label: "Black or African American"},
//     {value: "Hispanic or Latino", label: "Hispanic or Latino"},
//     {value: "Native Hawaiian or Other Pacific Islander", label: "Native Hawaiian or Other Pacific Islander"},
//     {value: "White", label: "White"},
//     ]

export const raceAndEthnicCategoryList = [
  {
    value: "American Indian or Alaska Native",
    label: "American Indian or Alaska Native",
  },
  { value: "Asian", label: "Asian" },
  { value: "Black or African American", label: "Black or African American" },
  { value: "Hispanic or Latino", label: "Hispanic or Latino" },
  {
    value: "Native Hawaiian or Other Pacific Islander",
    label: "Native Hawaiian or Other Pacific Islander",
  },
  { value: "White", label: "White" },
];

export default raceAndEthnicCategoryList;
