import styled from "styled-components";

export const ChatMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding-left: 16px;
  box-sizing: border-box;
`;

export const MsgBox = styled.div`
  /* Rectangle 105 */
  min-width: 50px;
  max-width: 245px;
  min-height: 40px;
  padding: 12px 10px;
  box-sizing: border-box;
  word-break: normal;

  /* Gradients/Light Blue Purple */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  border-radius: 15px 15px 15px 0px;

  /* Hello Jenny! See you */
  /* Mobile/Body/3 */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;
  /* or 16px */

  letter-spacing: 0.3px;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.8;
`;

export const Time = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;

  /* Gray / 3 */

  color: #a2aecb;
`;
export const MsgBoxURL = styled.a`
  /* Rectangle 105 */
  min-width: 50px;
  max-width: 245px;
  min-height: 40px;
  padding: 12px 10px;
  box-sizing: border-box;
  word-wrap: break-word;

  /* Gradients/Light Blue Purple */
  background: linear-gradient(180deg, #598ef5 0%, #79a4fb 100%);
  border-radius: 15px 15px 0px 15px;

  /* Hi Dr. Smith! See yo */

  /* Mobile/Body/3 */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;
  /* identical to box height, or 16px */

  letter-spacing: 0.3px;

  color: #fbfbfb;
`;