import React, { useEffect, useState } from "react";
import moment from "moment";

import SafetyMenu from "../../components/SafetyMenu";
import ProfileMenu from "../../components/ProfileMenu";

import {
  NavWrapper,
  NavContainer,
  Title,
  LeftBar,
  RightBar,
  Span,
  CircleBtn,
  AvatarImg,
  CircleBtnIcon,
  TextContainer,
  SafetyContainer,
  CircleBtnActive,
  BackTitle,
  HambergerBtn,
  HambergerBtnIcon,
  AvatarImgActive,
  LogoBox,
  Waves,
  Logo,
} from "./styles";

const Navbar = (props) => {
  const [dateStr, setDateStr] = useState(moment().format("dddd, MMMM Do"));
  const [timeStr, setTimeStr] = useState(moment().format("LT"));

  useEffect(() => {
    const interval = setInterval(() => {
      setDateStr((dateStr) => moment().format("dddd, MMMM Do"));
      setTimeStr((timeStr) => moment().format("LT"));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const [isSafetyMenuShow, setIsSafetyMenuShow] = useState(false);
  const [isProfileMenuShow, setProfileMenuShow] = useState(false);

  const isSafetyMenuShowHandler = () => {
    setIsSafetyMenuShow((prev) => !prev);
  };

  const isProfileMenuShowHandler = () => {
    setProfileMenuShow((prev) => !prev);
  };

  //Icon Paths
  const backIcon = "/images/components/Navbar/BackIcon.svg";
  const emergencyIcon = "/images/components/Navbar/EmergencyIcon.svg";
  const blankProfilePic = "/images/components/Settings/Blank_Profile_Picture.svg";

  // Profile picture logic
  const avatar = props.susie.profilePicture
    ? props.susie.profilePicture
    : blankProfilePic;

  return (
    <NavWrapper>
      <NavContainer>
        <LeftBar>
          <HambergerBtn onClick={props.showSidebar}>
            <HambergerBtnIcon>
              <svg viewBox="0 0 100 80" width="40" height="40">
                <rect width="100" height="15"></rect>
                <rect y="30" width="100" height="15"></rect>
                <rect y="60" width="100" height="15"></rect>
              </svg>
            </HambergerBtnIcon>
          </HambergerBtn>
          {props.tab === "meet" ? <Title>{props.title}</Title> : null}

          {props.tab === "meetMT" ? (
            <>
              <CircleBtn onClick={() => props.pushTo("/dashboard")}>
                <CircleBtnIcon src={backIcon} />
              </CircleBtn>
              <BackTitle>Back to Meet</BackTitle>
            </>
          ) : null}

          {props.tab === "meetCT" ? (
            <>
              <CircleBtn onClick={() => props.pushTo("/meetmt")}>
                <CircleBtnIcon src={backIcon} />
              </CircleBtn>
              <BackTitle>Back to Susie’s details</BackTitle>
            </>
          ) : null}

          {props.tab === "meetWR" ? (
            <LogoBox>
              <Waves src = {"/images/pages/WaitingRoom/MarvinWaves.svg"}/>
              <Logo src = {"/images/pages/WaitingRoom/BlackLogo.png"}/>
            </LogoBox>
          ) : null}

          {props.tab === "grow" ? <Title>Grow</Title> : null}

          {props.tab === "growVideo" ? (
            <>
              <CircleBtn onClick={() => props.setTab("grow")}>
                <CircleBtnIcon src={backIcon} />
              </CircleBtn>
              <BackTitle>Back to Grow</BackTitle>
            </>
          ) : null}

          {props.tab === "settings" ? <Title>Settings</Title> : null}
        </LeftBar>
        <RightBar>
          <Span>{dateStr}</Span>
          <Span>{timeStr}</Span>
          {isSafetyMenuShow ? (
            <SafetyContainer>
              <CircleBtnActive onClick={isSafetyMenuShowHandler}>
                <CircleBtnIcon src={emergencyIcon} />
              </CircleBtnActive>
              <SafetyMenu closeMenu={isSafetyMenuShowHandler} />
            </SafetyContainer>
          ) : (
            <CircleBtn onClick={isSafetyMenuShowHandler}>
              <CircleBtnIcon src={emergencyIcon} />
            </CircleBtn>
          )}

          {!props.guestSession && (
            <>
              {isProfileMenuShow ? (
                <SafetyContainer>
                  <AvatarImgActive
                    onClick={isProfileMenuShowHandler}
                    src={avatar}
                  ></AvatarImgActive>
                  <ProfileMenu closeMenu={isProfileMenuShowHandler} />
                </SafetyContainer>
              ) : (
                <AvatarImg
                  onClick={isProfileMenuShowHandler}
                  src={avatar}
                ></AvatarImg>
              )}
            </>
          )}
        </RightBar>
      </NavContainer>
      {props.tab === "meet" ? (
        <TextContainer>
          <span>
            {"You have logged in "}
            <strong>
              {props.consWeeksLogged === 1
                ? props.consWeeksLogged + " week"
                : props.consWeeksLogged + " weeks"}
            </strong>
            {" in a row. Keep it up!"}
          </span>
        </TextContainer>
      ) : null}
      {props.tab === "grow" ? (
        <TextContainer>
          Explore our mental health exercises and tools.
        </TextContainer>
      ) : null}
    </NavWrapper>
  );
};

export default Navbar;
