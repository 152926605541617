import React, {useEffect, useState} from 'react'

import { convertToHhmm } from '../../utils/Video/videosession';
import moment from "moment";

import {
    SessionTimerContainer,
    SessionTimerText
} from "./styles";


const SessionTimer = () => {

    const [initialMoment, setInitialMoment] = useState(moment());
    const [timeInSession, setTimeInSession] = useState();

    useEffect(() => {
      const interval = setInterval(() => {
        setTimeInSession(moment().diff(initialMoment));
      }, 1000);
      return () => clearInterval(interval);
    }, []);

  return (
  <SessionTimerContainer>
      <SessionTimerText>
              Time in session: {convertToHhmm(timeInSession)}
      </SessionTimerText>
  </SessionTimerContainer>
  )
}

export default SessionTimer