import { ChatMsgContainer, MsgBox, Time } from "./styles.js";
import React from "react";

import AdjustableSpacer from "../AdjustableSpacer";

const ChatMsgLeftWithShadow = (props) => {
  return (
    <ChatMsgContainer>
      <AdjustableSpacer height="16px" />
      <MsgBox>{props.msg}</MsgBox>
      <AdjustableSpacer height="8px" />
      <Time>
        {props.name} · {props.time}
      </Time>
    </ChatMsgContainer>
  );
};

export default ChatMsgLeftWithShadow;
