import styled from "styled-components";

export const TileViewContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 100vh;
  background-color: #404040;
  column-gap: 15px;
  row-gap: 15px;
`;

export const TileHolder = styled.div`
  margin: 15px;
`;
