import React from "react";
import PasswordInput from "../PasswordInput";

import {
  Title,
  PassInstructions,
  ChangePasswordButton,
  ChangePasswordText,
  PasswordChangeBoxOne,
  PasswordChangeBoxTwo,
  SaveChangesBtn,
  InputContainer,
} from "./styles";

const PassswordChangeForm = (props) => {

  return (
    <>
      {props.pwdStage === 1 ? (
        <PasswordChangeBoxTwo>
          <Title>Password</Title>
          <PassInstructions>
            Your password should have a minimum 8 characters – at least 1 upper
            case, 1 lower case, and 1 numerical character.
          </PassInstructions>
          {/* <InputCol> */}
          <InputContainer>
            <PasswordInput
              // readOnly = {true}
              ref={props.currPwdRef}
              // value = {props.currPwd}
              inputLabel="Current Password"
            ></PasswordInput>
          </InputContainer>
          {/* <AdjustableSpacer height = "25px" mobileHeight = "25px"/> */}
          <InputContainer>
            <PasswordInput
              ref={props.newPwdRef}
              onChange={props.onPasswordChange}
              inputLabel="New Password"
            ></PasswordInput>
          </InputContainer>
          {/* <AdjustableSpacer height = "25px" mobileHeight = "25px"/> */}
          <InputContainer>
            <PasswordInput
              ref={props.confNewPwdRef}
              onChange={props.onPasswordChange}
              inputLabel="Confirm New Password"
            ></PasswordInput>
          </InputContainer>
          {/* </InputCol> */}
          <SaveChangesBtn
            changesMade={props.pwdChangesMade}
            onClick={() => props.saveNewPwd()}
            disabled={!props.pwdChangesMade}
          >
            Save
          </SaveChangesBtn>
        </PasswordChangeBoxTwo>
      ) : (
        <PasswordChangeBoxOne>
          <Title>Password</Title>
          <PassInstructions>
            Your password should have a minimum 8 characters – at least 1 upper
            case, 1 lower case, and 1 numerical character.
          </PassInstructions>
          <ChangePasswordButton onClick={() => props.setPwdStage(1)}>
            <ChangePasswordText> Change password </ChangePasswordText>
          </ChangePasswordButton>
        </PasswordChangeBoxOne>
      )}
    </>
  );
};

export default PassswordChangeForm;
