import React, { useState } from "react";
import PageWrapper from "../PageWrapper";
import { FlexContainer, ICard } from "./styles";

import GrowCard from "../../components/GrowCard";

import exerciseCardArray from "../../data/exerciseCardArray";

const Grow = (props) => {
  const [tab, setTab] = useState("grow");

  const growVideoOptionHandler = () => {
    setTab("growVideo");
  };

  return (
    <PageWrapper tab={tab} setTab={setTab} isGrowPage={true}>
      <FlexContainer>
        <ICard>
          <GrowCard
            tab={tab}
            growVideoOptionHandler={growVideoOptionHandler}
            exerciseCardArray={exerciseCardArray}
          />
        </ICard>
      </FlexContainer>
    </PageWrapper>
  );
};

export default Grow;
