import styled from "styled-components";

export const SessionListBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const MonthStrip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
