import styled from "styled-components";

export const RoundedRec = styled.div`
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Up - Height >111 */
  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  width: 531px;
  height: 333px;
`;

export const MeetHeader = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const MarginBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const RowOne = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Group643 = styled.div`
  background-image: url("images/components/Meet/Group643.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 67px;
  width: 102px;
`;

export const MarvinWaves = styled.div`
  background-image: url("images/components/Meet/MarvinWaves.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  width: 100px;
  &:hover {
    background-image: url("images/components/Meet/MarvinWavesOnHover.svg");
    cursor: pointer;
    /* White Elements/Default */
    /* background-color: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6; */

    /* Waiting room button shadow */
    /* box-shadow: 0px 0px 2px rgba(255, 255, 255, 0.4), -7px -7px 14px #FFFFFF, 5px 5px 25px rgba(202, 208, 223, 0.85); */
  }
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
`;

export const Date = styled.div`
  /* Mobile/Body/2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.2%;

  /* or 17px */
  letter-spacing: 0.3px;

  color: #333333;
`;

export const Time = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;
export const Title = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;
export const WorkingOnItBox = styled.div`
  width: 441px;
  height: 73px;

  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;

export const WorkingOnItContent = styled.div`
  padding: 15px 27px 15px 36px;
`;

export const YoullBeNotified = styled.div`
  /* Desktop / Body / 2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #333333;
`;
