export const yourTherapist = {
  profilePicture: "images/components/YourTherapistCard/ProfilePicture.png",
  // name: "Dr. Susie Quermeir",
  title: "Dr.",
  firstName: "Susie",
  lastName: "Quermeir",
  pronouns: "she/her",
  bio:
    "I've had a very varied client population and have spent a lot of time working with medical professionals." +
    "I work now at a clinic in the Los Angeles area and deeply understand the culture and lifestyle.",
  experience:
    "I've had a very varied client population and have spent a lot of time working with medical professionals." +
    "I work now at a clinic in the Los Angeles area and deeply understand the culture and lifestyle.",
  specialties: "Depression, Anxiety",
  languages: "English, Russian, Spanish",
  therapyTypes: ["CBT", "TF-CBT"],
  location: "Los Angeles, California",
};

export default yourTherapist;
