import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../../utils/Zustand/store";

// Mediasoup configs / socket.io
import { initialUserMedia, initialDisplayParams} from '../../config/mediaSoup.js';

import AdjustableSpacer from "../../components/AdjustableSpacer";
import FilmStripBar from "../../components/FilmstripBar";
import TileView from "../../components/TileView";
import ChatSessionButton from "../../components/ChatSessionButton";
import VidSessionButton from "../../components/VidSessionButton";
import ChatBox from "../../components/ChatBox";
import FilmVidView from "../../components/FilmVidView";
import FullVidView from "../../components/FullVidView";
import DevicePicker from "../../components/DevicePicker";
import SessionTimer from "../../components/SessionTimer";
import FullScreenAudio from "../../components/FullScreenAudio";
import Time from "../../components/Time";

import {
  Background,
  VideoBG,
  MarvinLogo,
  ButtonStripLeft,
  ButtonStripRight,
  ButtonStripCenter,
  RowContainer,
  TileViewIcon,
  ParticipantsBox,
  BottomShadowContainer,
  TopShadowContainer,
  LockRoomHoverBox,
  LockRoomText,
  LockRoomTitle,
  LockMeetingButtonContainer,
  DevicesButtonContainer,
  DevicesHoverBox,
  DevicesText,
  DevicesTitle,
  FullScreenIcon,
  FullScreenText,
  FullScreenBox,
  TileViewBox
} from "./styles";


const VideoComponent = (props) => {


  const [fullScreenMode, setFullScreenMode] = useState(false);

  //Stuff for navigation back to waitingroom specific to userapp
  const history = useHistory();


  const [devicesOpen, setDevicesOpen] = useState(false);

  const [producers, setProducers] = useState({
    audioProducer: null,
    videoProducer: null,
    desktopProducer: null
  });

  const localMic = useStore((state) => state.localMic);
  const [micOn,setMic ] = useState(localMic);
  const localCamera = useStore((state) => state.localCamera);
  const [cameraOn, setCam] = useState(localCamera);
  const meetingName = useStore((state) => state.meetingName);
  
  //Tile view state
  const [Tiled, clickTileView] = useState(false);
  //Screenshare
  const [Sharing, shareScreen] = useState(false);
  //Hide self view
  const [selfHidden, hideSelf] = useState(false);

  // User media
  const [localAudioStream, setLocalAudioStream] = useState(null);
  const [localVideoStream, setLocalVideoStream] = useState(null);
  const [localDesktopStream, setLocalDesktopStream] = useState(null);

  //State for selected device
  const [selectedMic, setSelectedMic] = useState("");
  const [selectedCam, setSelectedCam] = useState("");

  const [camList, setCamList] = useState([]);
  const [micList, setMicList] = useState([]);

  let constraints = useRef(initialUserMedia);
  const targetPeer = useRef(null);



  useEffect(() => {
    console.log(`inside focusedParticipant useeffect`);

    if(!targetPeer.current){
      console.log(`remotePeers`, props.remotePeers);
      console.log(`remotePeers size: `, props.remotePeers.size)
      for(let [peerId, peerState] of props.remotePeers.entries()){
          console.log(`iterating over Peers`)
          console.log(`peerState: `, peerState);
          console.log(`Condition`, peerState.videoStream && !targetPeer.current)
          if(peerState.videoStream && !targetPeer.current){
                targetPeer.current = [peerId, peerState];
                console.log(`targetPeer`, targetPeer.current)   
          }
          if(peerState.desktopStream){
            targetPeer.current = [peerId, peerState];
            break;
          }
      }
      if(targetPeer.current){
        console.log(`targetPeer.current`, targetPeer.current);
        props.setFocusedParticipant({
          uid: targetPeer.current[0],
          ...targetPeer.current[1]
        })
      } else if(localVideoStream){
        console.log(`LocalVideoStream`, localVideoStream);
        props.setFocusedParticipant({
              uid: props.myUserId,
              cameraOn: cameraOn,
              displayName: meetingName,
              videoStream: localVideoStream,
              desktopStream: localDesktopStream,
        })
            
      }
    }
    
  }, [props.changeInRemotePeers, localVideoStream])


  useEffect(() => {
      console.log(`SelectedMic`, selectedMic);
  }, [selectedMic ])
  useEffect(() => {
    console.log(`SelectedCam`, selectedCam);
  },[selectedCam])
  useEffect(() => {
    console.log(`micList`, micList);
  },[micList])
  useEffect(() => {
    console.log(`camList`, camList);
  },[camList])


  console.log("Rerendering.");

  let frameRate;
  useEffect(() => {
     let constraints = navigator.mediaDevices.getSupportedConstraints();
     frameRate = constraints.frameRate;
     console.log(`here are my GUm constraints: `, constraints); 
  }, [])


  useEffect(() => { 

    if (producers.audioProducer) {
      console.log(`closing current audioProducer`)
      props.producerClose(producers.audioProducer.id, () => {

        producers.audioProducer.close();

        setProducers(prevProducers => {
          return {...prevProducers, audioProducer: null}
        });  

        for (let track of localAudioStream.getAudioTracks()){
          console.log("Track stopping: ", track);
          track.stop();
        }
      })
    }
      
  }, [selectedMic])
  

  useEffect(() => {
    const getUserMediaMicrophone = async () => {
      let tempStream = null;
      let audioConstraints = micOn;
      if (selectedMic && micOn) audioConstraints = {deviceId: selectedMic};

      constraints.current = {... constraints.current, audio: audioConstraints, video: false};
      console.log("Calling getUserMediaUE with: ", constraints.current);
    
      try {
        tempStream =  await navigator.mediaDevices.getUserMedia(constraints.current);  
      } catch (error) {
        console.log(`error grabbing user media`, error)
        props.emitMediaError({errorMessage: "Error in getUserMediaMicrophone", error});
        setMic(false);
      }
      setLocalAudioStream(tempStream);
      console.log("Setting localAudioStream to: ", tempStream);
    }

    // If the mic is being turned off, stop all audio tracks.
    if(localAudioStream && !micOn){
      localAudioStream.getAudioTracks().forEach(track => track.stop())
    }

    if (micOn) getUserMediaMicrophone();
    if(!micOn){
        if(producers.audioProducer){
            props.producerClose(producers.audioProducer.id, () => {
                //close the local producer       
                producers.audioProducer.close();
                //set the audio producer in producers to null
                setProducers(prevProducers => {
                  return {...prevProducers, audioProducer: null}
                })
                for (let track of localAudioStream.getAudioTracks()){              
                  console.log("Track stopping: ", track);
                  track.stop();
                }
              });       
          }
        } else console.log("Getting new  Producer")
  }, [micOn, selectedMic])


useEffect(() => {
    let audioTrack;
    if(localAudioStream && props.newProducerTransport){
      if (micOn) {
          try {
            audioTrack = localAudioStream.getAudioTracks()[0];
          } catch (error) {
            setMic(false);
            console.log(`Error in getting audiotracks from localaudiostream`, error)
          }
          console.log("Audio track in localAudioStream: ", audioTrack);
          
          const getProducers = async () => { 
            if (!producers.audioProducer && audioTrack) {
                let { audioProducer } = await props.getProducers({audioTrack});
                setProducers(prevProducers => {
                  return {...prevProducers, audioProducer}
                })      
            }
          }
        getProducers();
      }
    }
}, [localAudioStream, props.newProducerTransport])


useEffect(() => {
  //If we have a video producer go ahead and run cleanup
  if (producers.videoProducer) {
    console.log(`Closing current videoProducer`)
    props.producerClose(producers.videoProducer.id, () => {

      producers.videoProducer.close();

      setProducers(prevProducers => {
        return {...prevProducers, videoProducer: null}
      });  

      for (let track of localVideoStream.getVideoTracks()){
        console.log("Track stopping: ", track);
        track.stop();
      }
    })
  }

}, [selectedCam])



useEffect(() => {
    
    const getUserMediaCamera = async () => {
      let tempStream = null;
      let videoConstraints = {...initialUserMedia.video};

      if (selectedCam) videoConstraints = {...videoConstraints, deviceId: selectedCam};

      constraints.current = {...initialUserMedia, audio: false, video : videoConstraints};   

      try {
        tempStream =  await navigator.mediaDevices.getUserMedia(constraints.current);
        setLocalVideoStream(tempStream);
      } catch (error) {
        console.log(`error grabbing user media`, error);
        props.emitMediaError({errorMessage: "Error in getUserMediaCamera", error});
        setCam(false);
      }
      console.log("Setting localVideoStream to: ", tempStream);
    }

    // If the camera is true (camera being turned on), call the function to collect media
    if (cameraOn) getUserMediaCamera();
    if(!cameraOn){

      if (producers.videoProducer) {

          props.producerClose(producers.videoProducer.id, () => {
            producers.videoProducer.close();

            setProducers(prevProducers => {
              return {...prevProducers, videoProducer: null}
            });
            
            for (let track of localVideoStream.getVideoTracks()){
              console.log("Track stopping: ", track);
              track.stop();
            }
          })

        // if(props.focusedParticipant.id === props.myUserId){
        //     props.setFocusedParticipant({
        //       ...props.focusedParticipant,
        //       cameraOn: false
        //     })
        // }
      }        
    }
  }, [cameraOn, selectedCam])

 
useEffect( () => {
  let videoTrack;
  if (localVideoStream && props.newProducerTransport){
    if (cameraOn) {
      videoTrack = localVideoStream.getVideoTracks()[0]; 
      console.log("Video track in localVideoStream: ", videoTrack);

      const getProducers = async () => {
        if (!producers.videoProducer && videoTrack) {
            let { videoProducer } = await props.getProducers({videoTrack});
            setProducers(prevProducers => {
              return {...prevProducers, videoProducer}
            })  
        }
      }
      getProducers();

     
   
      if(props.focusedParticipant.uid === props.myUserId){
        
        props.setFocusedParticipant({
          uid: props.myUserId,
          cameraOn: cameraOn,
          displayName: meetingName,
          videoStream: localVideoStream,
          desktopStream: localDesktopStream,
        })
      }
    }    
    
  } else { console.log("In useEffect, stream is null.") } // Null can be normal return from useGetMedia.
}, [localVideoStream, props.newProducerTransport])


  // If mic is being turned on, collects user media that is necessary accordingly

  const updateList = async () => {
  if(localVideoStream || localAudioStream){
    let tempCamList = [];
    let tempMicList = [];
    let devices =  await navigator.mediaDevices.enumerateDevices();
    devices.forEach(device => {
        if(device.kind === 'videoinput'){
          tempCamList.push(device);
          if(selectedCam === null) setSelectedCam(device.deviceId);
        } else if(device.kind ==='audioinput'){
          tempMicList.push(device);
          if(selectedMic === null) setSelectedMic(device.deviceId);
        } else {console.log(`not audioinput or videoinput`)}
      })
      setCamList(tempCamList);
      setMicList(tempMicList);
    }
  }
  
  useEffect(() => {
    console.log(`producers changed`, producers)
  }, [producers])
  
  // ************ End Collecting User Media ************** //
  



//*************Handle Mute Button************************
  
const toggleMic = () => {
    setMic(prevState => !prevState);
}

//*******************Handle Camera Button *******************/

const toggleCam = () => {
  setCam(prevState => !prevState);
};

const handleHideSelfView = () => {
  hideSelf(prevState => !prevState);
};


/**
 * grabScreenShare method
 * @returns desktopStream
 */

  const grabScreenShare = async () => {
    let desktopStream = null;
    console.log(`Inside grab screen share`);    
    let getDisplayMediaParams = initialDisplayParams;
    if(frameRate){
      getDisplayMediaParams = {...getDisplayMediaParams, frameRate: {max: 1}}
    }
    try {
      desktopStream = await navigator.mediaDevices.getDisplayMedia(getDisplayMediaParams);
    } catch (error) {
      props.emitMediaError({errorMessage: "Error in getDisplayMedia", error});
      shareScreen(false);
      console.log(`Error: `, error);
      console.log(error.name);
    }
    return desktopStream;
  }


  /**
   * HandleScreenShare
   * if trying to share 
      * then grabs the desktopstream, 
      * sets to false if failed to get desktop stream
      * then it grabs the desktop track 
      * defines an ondended function for the desktopo track to set the set the stream to null and set sharing to false
      * then sets the localdesktopstream and the focused participant with the updated desktopstream
      * then gets a desktop producer
      * then sets the producers state
    * if trying to no longer share, 
      * it grabs the localdesktopstream's tracks and calls stop()
      * then it sets the localdesktopstream to null
      * then it sets the focusedparticpant with that null stream
      * then it checks if there is a desktop producer and calls onclosescreenshare() with a callback to call close() on the local producer
      * onCloseScreenShare() goes ahead and emites 'close-screenshare-prod' to server and executes callback to local producer.close() 
    * Inputs: for function
    *        1. @useState sharing, shareScreen()
    *        2. @function grabScreenShare
    *        3. @useState localDesktopStream, setLocalDesktopStream
    *        4. @useState producers, setProducers
    *        5. @function props.getProducers
    *        6. @function props.setFocusedParticipant
    *        7. @function props.onCloseScreenShare
    */
  const handleScreenShare = async () => {
    shareScreen(prevState => !prevState);

    try {
      if(!Sharing){
          //Grab the desktopStream from getdisplay media
          let desktopStream = await grabScreenShare();         
          console.log(`Here is my desktop stream`, desktopStream);
          let desktopTrack;

          if(!desktopStream){
            shareScreen(false);
            return;
          } else {
            console.log(desktopStream);
            desktopTrack = desktopStream.getVideoTracks()[0];
            // console.log(`DesktopTrack constraints:`, desktopTrack.getSettings())
            console.log(`desktop track`, desktopTrack);
          }

          //Extract the track
          desktopTrack.onended = () => {
                console.log(`onEnded function call`);
                shareScreen(false);

                //Close producers on server, then client
                if(producers.desktopProducer){
                    props.onCloseScreenShare(producers.desktopProducer.id, () => {
                      producers.desktopProducer.close();
                    });
                }    

                setLocalDesktopStream(null);

                props.setFocusedParticipant({
                  ...props.focusedParticipant,
                  desktopStream: null
                })

            }

            // //Set the DesktopStream State
            setLocalDesktopStream(desktopStream);

            // //Set the FullVidView
            props.setFocusedParticipant({
              desktopStream: desktopStream,
              ...props.focusedParticipant
            })

            // //Call produce with desktop track and setproducers state
            const { desktopProducer} = await props.getProducers({
                desktopTrack
              });

            console.log(desktopProducer);

            //Set the producers
            setProducers( {  
              ...producers,
              desktopProducer: desktopProducer
            });
          
          } else {
              //First grab the tracks from the desktopstream and call stop
                let tracks = localDesktopStream.getTracks();
                tracks.forEach(track => track.stop());

              //Set the state to null
                setLocalDesktopStream(null);

              //Reset the Focused Participant
                props.setFocusedParticipant({
                    uid: props.myUserId,
                    videoStream: localVideoStream,
                    desktopStream: null,
                    cameraOn: cameraOn,
                    displayName: meetingName,
                    sharing: Sharing,
                });
            
              //Close the producer or pause the producer
                if(producers.desktopProducer){
                //Emit to server to close producers on server side
                  props.onCloseScreenShare(producers.desktopProducer.id, () => {
                    producers.desktopProducer.close();
                  });
              }         
          }
    } catch (error) {
        console.log(`Error:`, error)
    }
    }
  

  // ************ Signaling new peer state *********** //
  useEffect(() => {

    console.log("Calling newState");
    // Function that emits to other peers that you've changed your mic / camera status
    props.newState({
      displayName: meetingName, 
      micOn, 
      cameraOn,
      screenshareOn: Sharing,
    });

  }, [meetingName, micOn, cameraOn, Sharing]); // new attributes can be added later




  const handleFocusedParticipant =  (uid) => {   
    if(uid === props.myUserId){
      props.setFocusedParticipant({
        uid: props.myUserId,
        videoStream: localVideoStream,
        desktopStream: localDesktopStream,
        cameraOn: cameraOn,
        displayName: meetingName,
        sharing: Sharing,
      });
    } else {
      props.setFocusedParticipant({...props.remotePeers.get(uid), uid: uid});
    }
    clickTileView(false);
  };

  useEffect(() => {
      console.log(`Focused participant changed.`, props.focusedParticipant)
  }, [props.focusedParticipant])
  


useEffect(() => {
    props.setTileViewArray(getTileViewArray())
}, [props.changeInRemotePeers])

  /**
   * @func getTileViewArray
   * @returns remotepeers + localUser 
   */
  const getTileViewArray = () => {
    let tileViewList = Array.from(props.remotePeers.values());
    return tileViewList;
  };
  


  const handleTileView =  () => {
    clickTileView(Tiled === false ? true : false);
      props.setTileViewArray(getTileViewArray())
  };

  

  const handleLockRoom = () => {

    if (!props.locked) {
      console.log(`just locked room`);
      props.handleLockRoom();
    }
    if (props.locked) {
      console.log(`unlocked`);
      props.handleUnlockRoom();
    }
  };


  const handleDevicesButton = () => {
    setDevicesOpen(prev => !prev);
    updateList();
  }


  useEffect(() => {
      console.log(`change in fullScreenMode: ${fullScreenMode}`)
  }, [fullScreenMode])


  const toggleFullScreenMode = () => {
    
    setFullScreenMode(prevState => !prevState);
  }

  useEffect(() => {
      console.log(`Consumers Array:`, props.consumers)

  }, [props.consumers])

  const displayAudioStats =  async () => {
    props.consumers.forEach( async (consumer) => {
      if(consumer.kind ==="audio"){
        console.log(`is of type audio`)
        let report = await consumer.getStats();
       for (const now of report.values()){

         let mediaStats =  report.get(now.id);
         console.log(mediaStats)
       }
      }
})

  }
  return (
    <Background>
          
          <MarvinLogo src = "/images/pages/VideoSession/WhiteLogo.svg" onClick = {displayAudioStats}></MarvinLogo>  
    {!fullScreenMode &&
           <ButtonStripLeft>
            <span>
              <ChatSessionButton
                pic={props.ChatOpen ? "/images/pages/VideoSession/ChatOn.svg" : "/images/pages/VideoSession/VideoMessagingDefault.svg"}
                buttonHandler={props.handleChatBox}
                labelText="Chat"
                chatInbox={props.unreadMessages}
                chatOpen={props.ChatOpen}
              />
              <ChatBox
                showChatBox={props.ChatOpen}
                closeChatBox={props.handleChatBox}
                chatArray={props.chatArray}
                sendMessage={props.sendMessage}
                myUserId={props.myUserId}
                meetingName={meetingName}
              />
            </span>
            <AdjustableSpacer width="10px" />
            <VidSessionButton
              pic={ Sharing ? "/images/pages/VideoSession/ScreenshareClicked.svg" 
              : "/images/pages/VideoSession/ScreenShareDefault.svg"}
              buttonHandler={handleScreenShare}
              labelText="Screen share"
            />  
          </ButtonStripLeft>
      }

      {!fullScreenMode && 

        <ButtonStripRight>
          <LockMeetingButtonContainer>
            <VidSessionButton
              pic={ !props.locked ? "/images/pages/VideoSession/Lock.svg"
                  : "/images/pages/VideoSession/Unlock.svg"
              }
              buttonHandler={handleLockRoom}
              labelText={!props.locked ? "Lock meeting" : "Unlock meeting"}
            />
            <LockRoomHoverBox>
              <LockRoomTitle>{props.locked ? "Unlock meeting" : "Lock meeting"}</LockRoomTitle>
              <LockRoomText>{props.locked ?  "Unlocking this meeting will allow other parties to join with the link.  You can lock the meeting at any time."
              : "Locking this meeting will prevent any other parties from joining with the link.  You can unlock the meeting at any time."}</LockRoomText>
            </LockRoomHoverBox>
          </LockMeetingButtonContainer>
          <DevicesButtonContainer>
          <VidSessionButton
              pic={devicesOpen ? "/images/pages/VideoSession/TroubleshootActive.svg"
                  : "/images/pages/VideoSession/TroubleshootInactive.svg"
              }
              buttonHandler={handleDevicesButton}
              labelText={"Devices"}
            />
            {devicesOpen && 
                <DevicesHoverBox>
                  <DevicesTitle>Devices </DevicesTitle>
                  <AdjustableSpacer height = "20px"/>
                  <DevicesText>Available mic devices:</DevicesText>
                  <DevicePicker   valueArray = {micList}
                              value = {selectedMic}
                              name = "mic-select"
                              onChange = {setSelectedMic}
                              >
                  </DevicePicker>
                  <AdjustableSpacer height = "20px"/>
                  <DevicesText>Available camera devices</DevicesText>
                  <DevicePicker valueArray = {camList}
                              value = {selectedCam}
                              name = "cam-select"
                              onChange = {setSelectedCam}                         
                  >
                </DevicePicker>
                </DevicesHoverBox>
            }
          </DevicesButtonContainer>
        </ButtonStripRight>
      }
      {/* <SessionTimer/> */}
        {!fullScreenMode && 

                  <ButtonStripCenter>
                    <VidSessionButton
                      pic={  micOn ? "/images/pages/VideoSession/MicrophoneOnDefault.svg"
                      : "/images/pages/VideoSession/MuteOn.svg"}
                      buttonHandler={toggleMic}
                      labelText={micOn ? "Mute" : "Unmute"}
                    />  
                    <AdjustableSpacer width="10px" />
                    <VidSessionButton
                      pic={cameraOn ? "/images/pages/VideoSession/CameraDefault.svg"
                        : "/images/pages/VideoSession/CameraOffClicked.svg"}
                      buttonHandler={toggleCam}
                      labelText={cameraOn ?  "Stop camera" : "Start camera"}
                    />
                    <AdjustableSpacer width="10px" />
                    <VidSessionButton
                      pic="/images/pages/VideoSession/EndCall.svg"
                      buttonHandler={props.handleEndSession}
                      labelText="End session"
                    />
                  </ButtonStripCenter>
        }
              
      <TopShadowContainer />

      {Tiled ?  null : <BottomShadowContainer /> }

      {Tiled ? (
        <VideoBG>
          <TileView
              localVideoStream = {localVideoStream}
              localDesktopStream = {localDesktopStream} 
              displayName = {meetingName}
              micOn = {micOn}
              cameraOn = {cameraOn}
              myUserId = {props.myUserId}
              tileViewArray={props.tileViewArray}
              handleFocusedParticipant={handleFocusedParticipant}
              remotePeersKeys={Array.from(props.remotePeers.keys())}
          />
        </VideoBG>
      ) : (
        <VideoBG>
           <FullVidView focusedParticipant = {props.focusedParticipant}
                        />
        </VideoBG>
      )}
      <ParticipantsBox>
        {!fullScreenMode && 
        
          <RowContainer>
            <TileViewBox onClick={handleTileView}>  
              <TileViewIcon
                src={Tiled ?  "/images/pages/VideoSession/SpeakerView.svg": "/images/pages/VideoSession/Tile.svg"}
                alt="Gallery View"/>
              <FullScreenText> {Tiled ? "Speaker view" : "Tile view"}</FullScreenText>
            </TileViewBox>
            <Time/>
          </RowContainer>
        }
     

       {!Tiled &&
            <FullScreenBox onClick={toggleFullScreenMode}>
              <FullScreenIcon src = "/images/pages/VideoSession/Fullscreen.svg"
                              alt="FullScreenMode"/>
              <FullScreenText>{fullScreenMode ? "Exit full screen" : "Full screen"}</FullScreenText>
            </FullScreenBox>          
       }
        <AdjustableSpacer height="6px" />

        {fullScreenMode  && <FullScreenAudio filmStripList = {Array.from(props.remotePeers.values())}/>}

        {Tiled  || fullScreenMode ? null : (
          <>
            <FilmVidView
              supportHiding={true}  //To Determine User Video
              selfHidden={selfHidden}
              handleFocusedParticipant={handleFocusedParticipant}
              sharing={Sharing}
              cameraOn={cameraOn}
              micOn={micOn}
              videoStream={localVideoStream}
              desktopStream={localDesktopStream}
              handleHideSelfView={handleHideSelfView}
              userId={props.myUserId}
              displayName={meetingName}
              remotePeersKeys = {props.remotePeersKeys}
              update={props.update}
            />
            <AdjustableSpacer height="15px" />
            <>
            </>
              <FilmStripBar
                update={props.update}
                filmStripList={Array.from(props.remotePeers.values())}
                remotePeersKeys={Array.from(props.remotePeers.keys())}
                handleFocusedParticipant={handleFocusedParticipant}
              />
          </>
        )} 
      </ParticipantsBox>
    </Background>
  );
};

export default VideoComponent;