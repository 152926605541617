import React, {useEffect, useRef} from 'react'


const PeerAudio = (props) => {


    const localAudioRef = useRef(null);
    
    useEffect(() => {
        console.log(`rendering peerAudio`)
        console.log(props.audioStream && localAudioRef.current != null)
        if (props.audioStream && localAudioRef.current != null ) {
            console.log("Assigning audio stream in peerAudio");
            localAudioRef.current.srcObject = props.audioStream;
          } 
    },[props.audioStream])

    return (
        <div>
            <audio  ref = {localAudioRef}
                autoPlay = {true}
                muted = {!props.micOn}
            />
        </div>
    )
}

export default PeerAudio