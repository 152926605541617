import {
  TileViewContainer,
  // TileHolder
} from "./styles.js";
import React from "react";

import FilmVidView from "../../components/FilmVidView";

const TileView = (props) => {


  return (
    <TileViewContainer>
      <FilmVidView
          tiled = {true}
          displayName = {props.displayName}
          audioStream = {null}
          handleFocusedParticipant={props.handleFocusedParticipant}
          videoStream =  {props.localVideoStream}
          desktopStream =  {props.localDesktopStream}
          micOn= {props.micOn}
          cameraOn = {props.cameraOn}
          userId = {props.myUserId}
          width={
            String(
              (100 - Math.ceil(Math.sqrt(props.tileViewArray.length+1)) * 5) /
                Math.ceil(Math.sqrt(props.tileViewArray.length+1))
            ) + "%"
          }
          height={
            String(
              (100 - Math.ceil(Math.sqrt(props.tileViewArray.length+1)) * 5) /
                Math.ceil(Math.sqrt(props.tileViewArray.length+1))
            ) + "%"
          }
        />
      {props.tileViewArray.map((val, index) => (
        <FilmVidView
          tiled = {true}
          key={index}
          userId = {props.remotePeersKeys[index]}
          handleFocusedParticipant={props.handleFocusedParticipant}
          displayName={val.displayName}
          supportHiding={false}
          selfHidden={false}
          cameraOn={val.cameraOn}
          videoStream={val.videoStream}
          audioStream={val.audioStream}
          desktopStream={val.desktopStream}
          micOn={val.micOn}
          width={
            String(
              (100 - Math.ceil(Math.sqrt(props.tileViewArray.length+1)) * 5) /
                Math.ceil(Math.sqrt(props.tileViewArray.length+1))
            ) + "%"
          }
          height={
            String(
              (100 - Math.ceil(Math.sqrt(props.tileViewArray.length+1)) * 5) /
                Math.ceil(Math.sqrt(props.tileViewArray.length+1))
            ) + "%"
          }
        />
      ))}
    </TileViewContainer>
  );
};

TileView.defaultProps = {
  filmStripList: [],
};

export default TileView;
