import styled from "styled-components";


export const FlexContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;


export const ICard = styled.div`
  width: 50%;
  margin: 30px;
`;

export const BG = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(220, 220, 220, .4);
    
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1000;
`;