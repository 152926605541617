import React from "react";

import {
  DeactivateBox,
  DeactTitle,
  DeactBody,
  DeactBtn,
  DeactBtnTxt,
} from "./styles";

// import AdjustableSpacer from '../AdjustableSpacer';
// import { useHistory } from 'react-router';

const Deactivate = (props) => {
  return (
    <>
      {
        <DeactivateBox>
          {/* <AdjustableSpacer height = "25px"/> */}

          <DeactTitle> Deactivate </DeactTitle>

          {/* <AdjustableSpacer height = "25px"/> */}

          <DeactBody>
            Deactivating your account will cancel all upcoming sessions and
            remove them from your therapist’s schedule. You can reactivate your
            account at any time, but we will have to begin the therapy journey
            by finding you a new therapist.
          </DeactBody>

          {/* <AdjustableSpacer height = "20px"/> */}
          <DeactBody>
          To deactivate your account, please contact user-success@meetmarvin.com
          </DeactBody>
          {/* <AdjustableSpacer height = "25px"/> */}
        </DeactivateBox>
      }
    </>
  );
};

export default Deactivate;
