import { ButtonBox, ButtonControl, ButtonLabel } from "./styles";

import AdjustableSpacer from "../AdjustableSpacer";

const VidSessionButton = (props) => {
  return (
    <ButtonBox onClick={props.buttonHandler}>
      <AdjustableSpacer height="11px" />
      <ButtonControl src={props.pic} />
      <AdjustableSpacer height="6px" />
      <ButtonLabel>{props.labelText}</ButtonLabel>
    </ButtonBox>
  );
};

export default VidSessionButton;
