import styled from 'styled-components';

export const OuterBox = styled.div`
    position: relative;
    width: 41vw;
    min-height: ${props => props.stage < 4 ? '36vh' : '50vh'};
    padding: 6vh 7vw 6vh 7vw;

    box-shadow: -22px -22px 66px #FFFFFF, 22px 22px 66px rgba(202, 208, 223, 0.85), inset 0px 0px 2px rgba(255, 255, 255, 0.4);
    border-radius: 0px 8px 8px 8px;
    height: 1px;
    background: #F6F6F6;  
`;

export const Body = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
`;

export const InputContainer = styled.div`
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
`;

export const InvalidOTPPopup = styled.div`
  position: absolute;
  top: 115%;
  border: solid black 1px;
  border-radius: 3px;

  padding: 17px 20px 17px 14px;
  background-color: #F6F6F6;

  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

export const CircleBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 3vh;
  left: 1.8vw;
  width: 40px;
  height: 40px;

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  border: 1px solid rgba(242, 242, 242, 0.25);
  box-sizing: border-box;

  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);

  border-radius: 50%;

  &:hover {
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    cursor: pointer;
  }
`;

export const TitleText = styled.div`
    font-family: 'Syne';
    font-size: 20px;
    line-height: 24px;
    color: #333333;
`;

export const InstructionsText = styled.div`
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #333333;
    text-align: center;
`;

export const MarvinImage = styled.div`
  background: url("/images/components/PwdReset/Marvin.svg");
  width: 237px;
  height: 222px;
  margin-top: -5vh;
`;