import { InputField, InputBox, InputLabel, InputText } from "./styles";
import React from "react";

export { InputField, InputBox, InputLabel, InputText };

const InputBoxWithLabel = React.forwardRef((props, ref) => {
  let validated = false;

  if (props.validated === undefined) validated = true;
  if (props.validated === true || props.validated === false) {
    validated = props.validated;
  } else {
    validated = validated || false;
  }

  return (
    <InputBox>
      <InputLabel>{props.inputLabel}</InputLabel>
      <InputField
        readOnly={props.readOnly}
        height={props.height}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        validated={validated}
        ref={ref}
        disabled={props.disabled ? props.disabled : false}
      ></InputField>
    </InputBox>
  );
});

export default InputBoxWithLabel;
