import styled from "styled-components";

export const OuterBox = styled.div`
box-sizing: border-box;
position: relative;
  width: 531px;
  min-height: 545px;
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  display: flex;
  @media (min-width: 1599px) {
    width: 640px;
  }
`;

export const Body = styled.div`
  box-sizing: border-box;
  padding-left: 25px;
  padding-top: 25px;
  padding-right: 46px;
  width: 100%;
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const Hovering = styled.img`
  position: absolute;
  width: 505px;
  height: 150px;
  z-index: 1;
  bottom: 0px;
  left: -225px;
  border-radius: 8px;
  /* border: solid red 1px; */
  /* background-color: black; */
  /* box-shadow: 5px 5px 25px rgba(202, 208, 223, 0.85); */
`;
export const Hovering2 = styled(Hovering)`
  left: -210px;
`;
export const NamePronounsContact = styled.div`
  display: flex;
  flex-direction: column;
`;

export const NamePronouns = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  color: #333333;
`;
export const YourTherapist = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const ProfilePicture = styled.div`
  width: 72px;
  height: 72px;
  border-radius: 42px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ContactBtn = styled.button`
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;

  width: 130px;
  height: 30px;

  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;

  border: none;
  color: #fefefe;

  opacity: 0.33; // For disabled appearance

  /* &:hover{
        cursor: pointer;
        background: linear-gradient(342.94deg, rgba(255, 255, 255, 0.4) -182.89%, rgba(0, 0, 0, 0.4) 132.93%), linear-gradient(323.71deg, #6ADCB3 -41.08%, #6ACCDC 96.31%);
        background-blend-mode: soft-light, normal;

        box-shadow: -4px -4px 10px #FFFFFF, 0px 8px 14px rgba(106, 170, 163, 0.35), inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
        border-radius: 75px;
    } */
`;

export const Heading = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;

  margin-bottom: 8px;
`;

export const Paragraph = styled.div`
  /* Desktop / Body / 2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 1 */
  color: #333333;

  margin-bottom: 20px;
`;
export const TypeContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  /* border: solid red 1px; */
  flex-wrap: wrap;
`;
export const Type = styled.div`
  /* Gradients/Blue Purple */
  background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
  color: #333;

  /* Up/BluePurple Card */
  box-shadow: -2px -3px 10px #FFFFFF, 0px 3px 10px rgba(202, 208, 223, 0.85), inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 75px;  

  padding: 8px 24px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  ${props => props.clicked && 
  `
    background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
    box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
    z-index: 100000;
  `}
  &:hover{
    cursor: pointer;
  }
`;

export const PopupBox = styled.div`
  box-sizing: border-box;
  position: absolute;
  // Calc to account for padding, 100% is equal to total container + 40, because it does not adhere to the padding range.
  width: 100%;
  bottom: 115%;
  min-height: 20vh;
  padding: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  background-color: #FEFEFE;
  z-index:10000;
`;

export const PopupX = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: url("images/components/MyTherapist/Close Icon.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10001;
  &:hover{
    cursor: pointer;
  }
`;

export const PopupContainer = styled.div`
  position: relative;

  font-family: Raleway;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
`;

export const TypeTitle = styled.div`
  font-family: Syne;
  font-size: 18px;
  margin-bottom: 2vh
`;
