import AdjustableSpacer from "../AdjustableSpacer";
// import Session from "../Session";
// import SessionList from "../SessionList";
import {
  RoundedRec,
  MeetHeader,
  MarginBox,
  ContentBox,
  Group643,
  MarvinWaves,
  RowOne,
  DateBox,
  // Date,
  // Time,
  Title,
  WorkingOnItBox,
  WorkingOnItContent,
  YoullBeNotified,
} from "./styles";

const MeetZero = (props) => {

  const makeError = () => {
    console.log(`zzzzz`);
    throw Error("Fake Error")
}
  return (
    <RoundedRec>
      <MarginBox>
        <AdjustableSpacer width="45px" />
        <ContentBox>
          <AdjustableSpacer height="25px" />
          <MeetHeader>Welcome to Marvin!</MeetHeader>
          <AdjustableSpacer height="16px" />
          <RowOne>
            <Group643></Group643>
            <MarvinWaves onClick={() => {}}></MarvinWaves>
            <DateBox>
              <Title> No sessions scheduled </Title>
            </DateBox>
          </RowOne>
          <Title>All upcoming sessions (0 scheduled)</Title>
          <AdjustableSpacer height="16px;" />
          <WorkingOnItBox>
            <WorkingOnItContent>
              <Title> We're working on it! </Title>
              <AdjustableSpacer height="8px;" />
              <YoullBeNotified>
                {" "}
                You'll be notified when we've scheduled your first session.{" "}
              </YoullBeNotified>
            </WorkingOnItContent>
          </WorkingOnItBox>
        </ContentBox>
        <AdjustableSpacer width="45px" />
      </MarginBox>
    </RoundedRec>
  );
};
export default MeetZero;
