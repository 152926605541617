import styled from "styled-components";

import { Link } from "react-router-dom";

export const MarvinWrapper = styled(Link)``;
export const SidebarWrapper = styled.div`
  min-width: 190px;
  height: 100vh;

  position: sticky; /* stay in place on scroll */
  z-index: 1; /* Stay as topmost element */
  top: 0;

  overflow-x: hidden; /* no horizontal scrollbar */
  overflow-y: hidden; /* no vertical scrollbar */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 0px 20px 0px 20px;
  background: linear-gradient(
      355.87deg,
      rgba(255, 255, 255, 0.05) 3.28%,
      rgba(235, 235, 235, 0.05) 63.85%,
      rgba(83, 138, 150, 0.025) 108.18%
    ),
    #f6f6f6;

  box-shadow: -7px -7px 14px #ffffff, 5px 5px 25px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);

  @media screen and (max-width: 1200px) {
    display: none;
  }
`;

export const SideTopGrouping = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  width: 100%;
  align-items: center;
`;

export const SideTab = styled.div`
  width: 100%;
  height: auto;
  margin-top: 33px;
`;

export const TabItem = styled(Link)`
  width: 100%;
  height: 40px;
  color: #a2aecb;
  text-decoration: none;
  padding: 0px 20px;
  box-sizing: border-box;

  &:hover {
    cursor: pointer;
    /* White Elements/Default */

    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #f6f6f6;
    /* Desktop White/Up - Height <50 */

    box-shadow: -5px -4px 12px rgba(255, 255, 255, 0.8),
      5px 5px 20px rgba(202, 208, 223, 0.85),
      inset 0px 0px 1px 1px rgba(255, 255, 255, 0.4);
    border-radius: 33px;

    /* Flat/Blue */
    color: #618cf1;
    #changedColor {
      stroke: #618cf1;
    }
  }

  &:active {
    /* White Elements/Default */

    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #f6f6f6;
    /* Desktop White/Down - White Element 35-60 */

    box-shadow: inset -2.5px -2.5px 5px #fafbff,
      inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
    border-radius: 33px;
  }

  &.unavailable {
    color: #cad0df;
  }
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 7px 0px;
`;

export const TabItemSelected = styled(Link)`
  width: 100%;
  height: 40px;
  color: #618cf1;
  text-decoration: none;
  padding: 0px 20px;
  box-sizing: border-box;
  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White/Down - White Element 35-60 */

  box-shadow: inset -2.5px -2.5px 5px #fafbff,
    inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
  border-radius: 33px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 7px 0px;
`;

export const Title = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

export const Icon = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`;
