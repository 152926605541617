import styled from "styled-components";

export const ChatBoxContainer = styled.div`
  /* Rectangle 117 */
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* width: 936px;
    height: 606px; */
  width: 95%;
  height: 90%;
  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White/Up - Height >50 */

  box-shadow: -7px -7px 14px #ffffff, 5px 5px 25px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  overflow-y: hidden;
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Body = styled.div`
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  width: 612px;
  height: 100%;
  overflow-y: scroll;
`;

export const Footer = styled.div``;

export const TypeBoxContainer = styled.div`
  position: relative;
`;

export const CBTitle = styled.span`
  /* Chat */
  /* Mobile / Header / 2 */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 26px;
  text-align: center;

  color: #333333;
`;

export const TypeBox = styled.textarea`
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 612px;
  height: 80px;
  padding: 16px 112px 16px 16px;
  resize: none;

  /* Gray / Background */

  background: #f6f6f6;
  /* Stroke / White Element */

  border: 0.5px solid rgba(202, 208, 223, 0.25);
  box-sizing: border-box;
  box-shadow: inset -2.5px -2.5px 6px 1px #ffffff,
    inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
  border-radius: 12px;

  :focus {
    outline: none;
  }

  /* Mobile/Input Field */
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 110%;

  /* identical to box height, or 14px */
  letter-spacing: 0.25px;

  /* Flat / White */

  color: #333333;
  mix-blend-mode: normal;
  opacity: 0.8;
`;

export const SendBtn = styled.button`
  position: absolute;
  width: 33px;
  height: 33px;
  right: 20px;
  bottom: 24px;

  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    linear-gradient(182.75deg, #618cf1 -21.55%, #618cf1 99.5%);
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(242, 242, 242, 0.1);
  box-sizing: border-box;
  box-shadow: -5px -5px 10px #fafbff,
    1.69px 1.69px 6px rgba(161, 169, 199, 0.85);
  border-radius: 100px;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const SendIcon = styled.img`
  position: absolute;
  left: 25%;
  top: 25%;
`;

export const AttachBtn = styled.button`
  position: absolute;
  width: 33px;
  height: 33px;
  right: 63px;
  bottom: 24px;

  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Stroke / White Button */

  border: 1px solid rgba(242, 242, 242, 0.25);
  box-sizing: border-box;
  /* Up / White Button 25-36 */

  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  border: none;
`;

export const AttachIcon = styled.img`
  position: absolute;
  left: 32.5%;
  top: 25%;
`;

export const ProfilePicture = styled.img`
  background-color: gray;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: none;
  object-fit: stretch;
`;
