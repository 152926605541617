import { useRef, useEffect, useState } from "react";

import AdjustableSpacer from "../../components/AdjustableSpacer";

import {
  OuterVideoBox,
  UserVideo,
  HideSelfViewIcon,
  StopCameraBoxInitial,
  StopCameraBoxNameCircle,
  CameraOffIcon,
  HideSelfText,
  ShowSelfViewIcon,
  ShowSelfText,
  MicrophoneOffIcon,
  MicCamRow,
  MicRow,
  NameLabel,
  FilmVidViewOverLay,
  StopCameraBox
} from "./styles";

const FilmVidView = (props) => {

  const localAudioRef = useRef(null);
  const localVideoRef = useRef(null);

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {

          console.log("noAudioPlayback? ", props.noAudioPlayback)
    
          if(props.videoStream){
            console.log("Assigning video stream.")
            console.log(`VideoStreamFilmVidView`)
            localVideoRef.current.srcObject = props.videoStream;
            setIsDesktop(false);
          } 
          if(props.desktopStream){
            console.log(`assigning desktop stream`)
            console.log(`DesktopFilmdVidView`)
            localVideoRef.current.srcObject = props.desktopStream;
            setIsDesktop(true);
          }

          if (props.audioStream && localAudioRef.current != null ) {
            console.log("Assigning audio stream.")
            localAudioRef.current.srcObject = props.audioStream;
          } 
          
  }, [props.videoStream, props.audioStream, props.desktopStream]);

  useEffect(() => {
    console.log("Mic state in filmVidView: ", props.micOn); 
    console.log("Cam state in filmVidView: ", props.cameraOn);
  }, [props.micOn, props.cameraOn]);
  
  useEffect(() => {
        console.log(`isDesktop Value: `, isDesktop)
  }, [isDesktop])

  const passUserId = () => {
    console.log("props.userId");
    console.log(props.userId);

    props.handleFocusedParticipant(props.userId);
    console.log(props);
  }

  const initial = (props.displayName ? props.displayName.slice(0,1) : "M");

  return (
          
            <OuterVideoBox
              width={props.width}
              height={props.height}
              update={props.update}
            >
              <FilmVidViewOverLay onClick={passUserId}>
                  {(props.cameraOn || isDesktop)?
                    <>
                    <MicRow>{!props.micOn ? <MicrophoneOffIcon src = "/images/pages/VideoSession/SlashedMicrophone.svg"/> : null}</MicRow>
                        {props.supportHiding ? 
                                  <>
                                        {!props.selfHidden ? 
                                              <>
                                                <HideSelfViewIcon src = "/images/pages/VideoSession/HideSelfView.svg"
                                                  onClick={props.handleHideSelfView}
                                                ></HideSelfViewIcon>
                                                <MicRow>{!props.micOn ? <MicrophoneOffIcon src = "/images/pages/VideoSession/SlashedMicrophone.svg" /> : null}</MicRow>
                                                <HideSelfText>Hide Self View</HideSelfText>
                                              </>
                                              : 
                                              <>
                                                <AdjustableSpacer height="5px" />
                                                <StopCameraBoxNameCircle>
                                                  <StopCameraBoxInitial>{initial}</StopCameraBoxInitial>
                                                </StopCameraBoxNameCircle>
                                                <ShowSelfViewIcon src = "/images/pages/VideoSession/ShowSelfView.svg"
                                                  onClick={props.handleHideSelfView}
                                                ></ShowSelfViewIcon>
                                                <ShowSelfText>Show Self View</ShowSelfText>
                                                <AdjustableSpacer height="5px" />
                                                <MicRow>{!props.micOn ? <MicrophoneOffIcon src = "/images/pages/VideoSession/SlashedMicrophone.svg"/> : null}</MicRow>
                                              </>
                                          }
                                  </>
                          : null}
                      </>

                  : null
                  }
              </FilmVidViewOverLay>
                  <UserVideo
                    autoPlay={true}
                    playsInline = {true}
                    muted = {true}
                    onClick={passUserId}
                    displayVid={(props.cameraOn || isDesktop) && (props.selfHidden ? false : true)}
                    ref={localVideoRef}
                    update={props.update}
                    desktop={isDesktop}
                    fullScreenMode = {props.fullScreenMode}
                  />
                  <audio
                      autoPlay={true}
                      muted={!props.micOn}
                      ref={localAudioRef}
                    />
                    { (!props.cameraOn && !isDesktop) ?  
                                <StopCameraBox tiled = {props.tiled ? props.tiled : false}>
                                    <AdjustableSpacer height="19px" />
                                    <StopCameraBoxNameCircle>
                                      <StopCameraBoxInitial>{initial}</StopCameraBoxInitial>
                                    </StopCameraBoxNameCircle>
                                    <MicCamRow>
                                      {!props.micOn ? (
                                        <>
                                          <MicrophoneOffIcon src = "/images/pages/VideoSession/SlashedMicrophone.svg"/>
                                          <AdjustableSpacer width={"15px"} />
                                        </>
                                      ) : null}
                                      <CameraOffIcon src = "/images/pages/VideoSession/StopCameraBoxIcon.svg"/>
                                    </MicCamRow>
                                  </StopCameraBox>        
                  : null }
              </OuterVideoBox>
        
  );
};

export default FilmVidView;
