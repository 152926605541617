import AdjustableSpacer from "../AdjustableSpacer";
import {
  MarginBox,
  Title,
  Content,
  ProfilePic,
  Body,
  Info,
  Name,
  Location,
  Pronoun,
  ButtonStrip,
  ContactButton,
  ContactText,
  DetailsText,
  ViewDetailsButton,
  Working,
} from "./styles";

const YourTherapistCard = (props) => {
  //  Set the zero state
  let zeroState = false;
  const numSessions = props.sessions.length;
  // Id from destructed therapist props ({...therapist})
  if (!props.id) {
    zeroState = true;
  }

  return (
    <MarginBox height={zeroState ? "134px" : null}>
      <Content>
        <Title zeroState = {zeroState}>Your therapist</Title>
        <Working display={zeroState ? "block" : "none"}>
          We're working on finding you a therapist who matches your preferences
          for your first session.{" "}
        </Working>
        <Body display={zeroState ? "none" : "flex"}>
          <ProfilePic
            pic={ props.profilePicture !== "" ? props.profilePicture : "/images/components/Settings/Blank_Profile_Picture.svg"  }
          />
          <Info>
            <Name> {props.firstName + " " + props.lastName} </Name>
            <AdjustableSpacer height="6px" />
            <Pronoun>{"(" + props.pronouns + ")"}</Pronoun>
            <AdjustableSpacer height="4px" />
            <Location>{props.location}</Location>
          </Info>
        </Body>
        <ButtonStrip display={zeroState ? "none" : "flex"}>
          <ViewDetailsButton onClick={() => props.handleViewDetailsButton()}>
            <DetailsText>View Details</DetailsText>
          </ViewDetailsButton>
        </ButtonStrip>
      </Content>
      <AdjustableSpacer width="27px" />
    </MarginBox>
  );
};

export default YourTherapistCard;
