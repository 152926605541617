import { useHistory, useParams } from "react-router-dom";
import React, { useState } from "react";
import { Entropy } from "entropy-string";

import {validateEmail} from "../../utils/MiscHelpers/validators";
import {fetchToMarvinAuth} from '../../utils/Auth/marvinAuth';
import { useStore } from "../../utils/Zustand/store";

import {
  Background,
  TopImageContainer,
  TopImage,
  BottomImageContainer,
  BottomImage,
  LoginBox,
  JoinLabel,
  InputContainer,
  LoginBtn,
  LoginBtnText,
  ResetPwdText,
  PwdResetLink,
  ErrorBox,

} from "./styles";

import AdjustableSpacer from "../../components/AdjustableSpacer";
import InputBoxWithLabel from "../../components/InputBoxWithLabel";
import PasswordInput from "../../components/PasswordInput";
import MobileAppPoster from "../../components/MobileAppPoster";
import BlueNotificationBox from "../../components/BlueNotificationBox";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';


const LoginApp = (props) => {

  const history = useHistory();

  let { doesSessionExist, userId, accessTokenPayload } = useSessionContext();
  const updateGuestSession = useStore((state) => state.updateGuestSession);
  const passwordUpdated = useStore((state) => state.passwordUpdated);
  const setPasswordUpdated = useStore((state) => state.setPasswordUpdated);

  if(doesSessionExist){
      console.log(`session exists`, doesSessionExist);
      console.log(userId);
      console.log(accessTokenPayload);
  } else {
    console.log(`session does not exist`);
  }


  const { room } = useParams();


  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleChange = (event) => {
    const {name, value} = event.target;
    if(name === "password")console.log(value);
    if(name === "email")setEmail(value);
    if(name ==="password")setPassword(value);
}
  const [eValidated, validateE] = useState(true);
  const [pValidated, validateP] = useState(true);

  const [pwdErrorMsg, setPwdErrorMsg] = useState("") ;

  let isValidated = true;

  const [emailNotConfirmed, setEmailNotConfirmed]  = useState(false)

  const updateSessionRoom = useStore((state) => state.updateSessionRoom);

  const setFirstLogin = useStore((state) => state.setFirstLogin);

  const setUserId = useStore((state) => state.setUserId);

  const loginAsUser = async () => {
    setUserId(email);
    setPwdErrorMsg("");
    setFirstLogin(false);
    updateGuestSession(false);
    setInterval(() => {
      history.push('/dashboard');
    }, 2000);

    // //First validate email and password to right type
    //   if(email=== ""  || !validateEmail(email) )validateE(false); else validateE(true);
    //   if(password ==="")validateP(false); else validateP(true);
    //
    // //If email or password is empty, then set isValidated to false
    //   if(email=== ""  || !validateEmail(email)  || password === "") isValidated = false;
    //
    // //Make a post to signin with email and pwd
    //   let params = {
    //     route: "signin",
    //     method: "POST",
    //     body: {
    //       login: email,
    //       passwordString: password,
    //       loginType: "email"
    //     }
    //   }
    //   try {
    //     if (isValidated){
    //       const validUserPwd = await fetchToMarvinAuth(params);
    //       console.log(`Here is the valid user pwd response: ${validUserPwd}`);
    //       if (validUserPwd.validLogin){
    //         setUserId(validUserPwd.userId);
    //         console.log(`Made it through the validation.`);
    //         setPwdErrorMsg("");
    //         setFirstLogin(false);
    //         history.push('/dashboard');
    //         updateGuestSession(false);
    //       } else if (validUserPwd.error.errorNumber === 1) {
    //         console.log(validUserPwd);
    //         setEmailNotConfirmed(true)
    //       } else {
    //         setPwdErrorMsg("The username or password you entered is incorrect.");
    //         validateP(false);
    //         validateE(false)
    //       }
    //     } else {
    //       console.log("Invalid input parameters.")
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
  }

  // const loginAsGuest = async () => {
  //   if (room) {
  //     updateSessionRoom(room);
  //   } else {
  //     const random = new Entropy();
  //     // console.log(`look at all these randoms, ${random}`)
  //     const bits = Entropy.bits(5e6, 1e12);
  //     // console.log(`here are some bits: ${bits}`);
  //     const roomString = random.string(bits);
  //     // console.log(`here is the room string: ${roomString}`)
  //     updateSessionRoom(roomString);
  //   }
  //   updateGuestSession(true); // Designates that the user is a guest, as opposed to logged in.

  //   let  params = {
  //     route: "signup",
  //     method: "POST",
  //     body: {
  //       login: email,
  //       passwordString: password,
  //       loginType  : "email",
  //     }
  //   }

  //   const response = await fetchToMarvinAuth(params);

  //   history.push("/waitingroom");
  // };

  const selectPasswordField = () => {
    setPwdErrorMsg("")
    validateP(true);
    validateE(true);

  }

  return (
    <Background>
      <TopImageContainer>
        <TopImage src="/images/pages/Login/TopLeft SignIn.svg" alt="TopImage" />
      </TopImageContainer>
       <MobileAppPoster/>
       {passwordUpdated &&
        <BlueNotificationBox  onClick = {() => setPasswordUpdated(false)}
                              notificationTitle = "Password updated"
                              notificationText = "Your password has been updated.  Please sign in below."
                              />
       }
       {emailNotConfirmed &&
                <BlueNotificationBox  onClick = {() => setEmailNotConfirmed(false)}
                                      notificationTitle = "Unconfirmed email"
                                      notificationText = "Your email has not been confirmed from signing up, please check your email for a link to confirm."
                  />
       }
      <LoginBox>
        <JoinLabel>Welcome back to Marvin</JoinLabel>
        <InputContainer>
            <InputBoxWithLabel
                value = {email}
                name = "email"
                inputLabel="Email"
                validated = {eValidated}
                onChange = {handleChange}
                onClick = {() => validateE(true)}
              />
        </InputContainer>
        <InputContainer>
          <PasswordInput
            value = {password}
            name = "password"
            inputLabel="Password"
            onChange = {handleChange}
            validated = {pValidated}
            onClick = {() => selectPasswordField()}
          />
           {(pwdErrorMsg === "") ? null
                                    : <ErrorBox>{pwdErrorMsg}</ErrorBox>
            }
        </InputContainer>
        <LoginBtn onClick={loginAsUser}>
          <LoginBtnText>Login</LoginBtnText>
        </LoginBtn>
        <ResetPwdText>
          Forgot your password?{" "}
          <PwdResetLink href="/passwordreset">Reset it here.</PwdResetLink>
        </ResetPwdText>
      </LoginBox>
      <BottomImageContainer>
        <BottomImage
          src="/images/pages/Login/BottomRight SignIn.svg"
          alt="BottomImage"
        />
      </BottomImageContainer>
    </Background>
  );
}

export default LoginApp;
