import { ChatMsgContainer, MsgBox, Time, MsgBoxURL } from "./styles.js";
import React from "react";

import AdjustableSpacer from "../AdjustableSpacer";

const ChatMsgLeft = (props) => {

  const isValidUrl = urlString =>{
    var inputElement = document.createElement('input');
    inputElement.type = 'url';
    inputElement.value = urlString;

    if (!inputElement.checkValidity()) {
      return false;
    } else {
      return true;
    }
  } 
  return (
    <ChatMsgContainer>
      <AdjustableSpacer height="16px" />
      {isValidUrl(props.msg) ? <MsgBoxURL target = "_blank" href = {props.msg}><u>{props.msg}</u></MsgBoxURL> 
      : 
      
      <MsgBox>{props.msg}</MsgBox>
      }
      <AdjustableSpacer height="8px" />
      <Time>
      {props.name} · {props.time}
      </Time>
    </ChatMsgContainer>
  );
};

export default ChatMsgLeft;
