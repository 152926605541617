import styled from "styled-components";

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  width: 100px;
  height: 90px;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.75);

    /* Blur/ Video Session BG */
    backdrop-filter: blur(66px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
  }
`;

export const ButtonControl = styled.img`
  width: 40px;
  height: 40px;

  /* background-image: url(${(props) => props.pic ? props.pic : "images/MuteButton/Default.svg"}); */
  /* background-size: contain;
  background-repeat: no-repeat; */
  
  object-fit: contain;
  &:hover {
    border-radius: 50%;
    background: rgba(250, 250, 250, 0.44);
    border: 50%;
    /* background-image: url(${(props) => props.pic ? props.pic : "images/MuteButton/Default.svg"}); */
  }
`;

export const ButtonLabel = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #ffffff;
`;

export const HoverBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
`;
