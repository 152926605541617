import React, {useState } from 'react';
    
import {
    DashBoardContainer,
    FlexContainer, 
    PageContainer,
} from './styles'

// Components
import Sidebar from '../../components/Sidebar';
import SidebarPopUp from '../../components/SidebarPopUp';
import Navbar from '../../components/Navbar';
import MobileAppPoster from '../../components/MobileAppPoster';
// Utils
import {useStore} from '../../utils/Zustand/store';
import { useHistory } from "react-router-dom";

import PageLevelEB from "../../components/PageLevelEB";

const PageWrapper = ({ children, tab, ...rest }) => {
    const susie = useStore(state => state.profile);
    const updateMsg = useStore(state => state.updateMsg);
    const profileId = useStore(state => state.profileId);
    // Variables for guest sessions
    const guestSession = useStore(state => state.isGuestSession);

    const [isSideBarPopUp, setSideBarPopUp] = useState(false);    
    
    const history = useHistory();

    const pushTo = (uri) => {
        history.push(uri);
    }

    const throwNewErrorAndPush = (uri) => {
       
            // throw new Error("crashed on click");
        console.log(`reached thrownewerror and push`)
        console.log(`here is the requested uri`, uri)
        // pushTo(uri);
    }

    return (
        // <PageLevelEB>

            <DashBoardContainer isGrowPage = {rest.isGrowPage}>
                <MobileAppPoster/>
                { guestSession ? null
                :
                    <>
                        <Sidebar onClick = {throwNewErrorAndPush}
                            tab={tab}
                        />
                        {isSideBarPopUp ? 
                            <SidebarPopUp 
                                tab={tab}
                                hideSidebar={() => setSideBarPopUp(false)}
                            />
                        :
                            null
                        }
                    </>
                }
                <PageContainer >
                    <Navbar 
                        tab={tab}
                        showSidebar={() => setSideBarPopUp(true)}
                        guestSession={guestSession}
                        susie = {susie}
                        pushTo = {pushTo}
                        {...rest}
                    />
                    <FlexContainer> {children} </FlexContainer>
                </PageContainer>
            </DashBoardContainer>
        // </PageLevelEB>
    )
}

export default PageWrapper;

