import { MouseEventHandler } from "react";
import {
  Text,
  TextActive,
  InactiveButton,
  ButtonActive,
} from "./styles";

type buttonProps = {
  text: string,
  active: boolean,
  buttonHandler: MouseEventHandler

}
const MonthButton = ({text, active, buttonHandler}: buttonProps) => {

  return (
    <>
      {" "}
      {
       active ? (
          <ButtonActive>
            <TextActive>{text}</TextActive>
          </ButtonActive>
        ) : (
       
          <InactiveButton onClick={buttonHandler}>
            <Text>{text}</Text>
          </InactiveButton>
        )
      }
    </>
  );
};

export default MonthButton;
