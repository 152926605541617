import React from 'react'

import AdjustableSpacer from '../AdjustableSpacer';
import { useState } from 'react';

import {
    InputRow,
    Content,
    Digit
} from "./styles";


const OTP = (props) => {
    return (
        <Content>
            <InputRow>
                <Digit  name = "first" 
                        onChange ={props.handleChange} 
                        value = {props.digits.first}
                        maxLength = {1}
                        validated={props.digits.first}/>
                <Digit name = "second"
                        onChange ={props.handleChange} 
                        value = {props.digits.second}
                        maxLength = {1}
                        validated={props.digits.second}/>
                <Digit name = "third"
                        onChange ={props.handleChange} 
                        value = {props.digits.third}
                        maxLength = {1}
                        validated={props.digits.third}/>
                <Digit name = "fourth"
                        onChange ={props.handleChange} 
                        value = {props.digits.fourth}
                        maxLength = {1}
                        validated={props.digits.fourth}/>
                <Digit name = "fifth"
                        onChange ={props.handleChange} 
                        value = {props.digits.fifth}
                        maxLength = {1}
                        validated={props.digits.fifth}/>
                <Digit name = "sixth"
                        onChange ={props.handleChange} 
                        value = {props.digits.sixth}
                        maxLength = {1}
                        validated={props.digits.sixth}/>
            </InputRow>
        </Content>
    )
}

export default OTP


