// For toggling between running locally / hosted
const marvURL = "https://userappapi.meetmarvin.com/email";
// const localURL = "http://localhost:4000/email";

const postURL = marvURL; // CHANGE BEFORE DEPLOYING / TESTING

const marvin = "user-success@meetmarvin.com, patrick@meetmarvin.com";
// const tyler = "tyler.torrey@meetmarvin.com";

const receivingEmail = marvin; // CHANGE BEFORE DEPLOYING / TESTING

// sendEmail is used to POST to email, sending to user-success.
export const sendEmail = async (paramObj) => {
  // Subject: String -- The subject line of the email, e.g. "Contact Marvin, topic: 'Technical Support'"
  // userId: uuid4  -- The user's unique ID number e.g. "bb915aef-6c3f-4d52-92e3-15df677c4a5a"
  // fullName: String -- The user's first and last name, separated by a space. e.g. "Steve Rogers"
  // message: String -- The message entered into whatever text field the email is coming from. e.g. "I'd like to reschedule my appointment for 9pm Friday."
  // userEmail: String -- The user's email address
  // userPhone: String -- The user's phone number

  const { subject, userId, fullName, message, userEmail, userPhone } = paramObj; // Extracts all parameters from parameter object

  const emailStatus = await fetch(postURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      to: receivingEmail,
      subject: subject,
      // Template subject to change.
      text: `User with ID: ${
        userId + " (" + fullName + ")"
      } submitted the message: ${message}. User's contact information: ${userEmail}, ${userPhone}`,
      html: `<p> User ${
        userId + " (" + fullName + ")"
      } submitted the message: ${message} </p> <br/> 
                  <p>User's contact information: ${userEmail}, ${userPhone} </p>`,
    }),
  });

  console.log(await emailStatus.json());
  console.log("Sent!");
};

export const sendRescheduleRequest = async (paramObj) => {
  // Subject: String -- The subject line of the email, e.g. "Contact Marvin, topic: 'Technical Support'"
  // userId: uuid4  -- The user's unique ID number e.g. "bb915aef-6c3f-4d52-92e3-15df677c4a5a"
  // fullName: String -- The user's first and last name, separated by a space. e.g. "Steve Rogers"
  // message: String -- The message entered into whatever text field the email is coming from. e.g. "I'd like to reschedule my appointment for 9pm Friday."
  // userEmail: String -- The user's email address
  // userPhone: String -- The user's phone number
  // sessionID: Int -- The ID of the session being rescheduled
  // date: Moment -- The date of the session being rescheduled
  // descr: String -- The text associated with the request, prompted by: "Provide 3 alternative times that work for you"

  const {
    subject,
    userId,
    fullName,
    message,
    userEmail,
    userPhone,
    sessionId,
    date,
  } = paramObj; // Extracts all parameters from parameter object

  const emailStatus = await fetch(postURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      to: receivingEmail,
      subject: subject,
      // Template subject to change.
      text: `User with ID: ${
        userId + " (" + fullName + ")"
      } requested to reschedule the session with ID: ${sessionId} scheduled for ${date}, with the text: ${message}. 
          User's contact information: ${userEmail}, ${userPhone}`,
      html: `<p> User ${
        userId + " (" + fullName + ")"
      } requested to reschedule the session with ID: ${sessionId} scheduled for ${date}, with the request: ${message}. <br/>
          User's contact information: ${userEmail}, ${userPhone} </p>`,
    }),
  });

  console.log(await emailStatus.json());
  console.log("Sent!");
};

export const sendCancellation = async (paramObj) => {
  // Subject: String -- The subject line of the email, e.g. "Contact Marvin, topic: 'Technical Support'"
  // userId: uuid4  -- The user's unique ID number e.g. "bb915aef-6c3f-4d52-92e3-15df677c4a5a"
  // fullName: String -- The user's first and last name, separated by a space. e.g. "Steve Rogers"
  // message: String -- The message entered into whatever text field the email is coming from. e.g. "I'd like to reschedule my appointment for 9pm Friday."
  // userEmail: String -- The user's email address
  // userPhone: String -- The user's phone number
  // sessionID: Int -- The ID of the session being rescheduled
  // date: Moment -- The date of the session being rescheduled

  const { subject, userId, fullName, userEmail, userPhone, sessionId, date } =
    paramObj; // Extracts all parameters from parameter object

  const emailStatus = await fetch(postURL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      to: receivingEmail,
      subject: subject,
      // Template subject to change.
      text: `User with ID: ${
        userId + " (" + fullName + ")"
      } canceled the session with ID: ${sessionId} scheduled for ${date}. 
          User's contact information: ${userEmail}, ${userPhone}`,
      html: `<p> User ${
        userId + " (" + fullName + ")"
      } canceled the session with ID: ${sessionId} scheduled for ${date}. <br/>
          User's contact information: ${userEmail}, ${userPhone} </p>`,
    }),
  });

  console.log(await emailStatus.json());
  console.log("Sent!");
};
