import Select from "react-select";
import makeAnimated from "react-select/animated";
// import { colourOptions } from '../data';

import { ChipInputContainer, LabelText, StyledBox } from "./styles";
import React from "react";

const animatedComponents = makeAnimated();

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    // borderBottom: '1px dotted pink',
    color: state.isFocused ? "#333333" : "#A2AECB",
    padding: 20,
    fontFamily: "Raleway",
    fontStyle: "normal",
    background:
      "linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%),  #F6F6F6",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: "#E2EBFF",
    fontFamily: "Raleway",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: "#E2EBFF",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
  }),
  control: (provided, state) => ({
    ...provided,
    background: "#F6F6F6",
    border: "0.5px solid rgba(202, 208, 223, 0.25)",
    // borderColor: 'rgba(202, 208, 223, 0.25)',
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "normal",
    borderRadius: "8px",
    boxShadow:
      "inset -2.5px -2.5px 6px 1px #FFFFFF, inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5)",

    // font-size: 14px,
    // line-height: 16px,

    // color: '#A2AECB',
  }),
  menuList: (provided, state) => ({
    ...provided,
    height: "150px",
    // background: '#F6F6F6',
    background:
      "linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6",
  }),
  menu: (provided, state) => ({
    ...provided,
    background:
      "linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6",
    // border: 'green',
    border: "1px solid #618CF1",
    borderRadius: "8px",
    fontFamily: "Raleway",
    fontStyle: "normal",
    fontWeight: "normal",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: "#F6F6F6",
    visibility: "hidden",
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    background: "#F6F6F6",
    visibility: "hidden",
  }),
  multiValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const background =
      "radial-gradient(149.37% 229.63% at 114.71% 127.22%, #618CF1 0%, #6569EE 100%)";
    const boxShadow =
      "inset 2.5px 2.5px 12px rgba(41, 46, 218, 0.53), inset -1.67px -1.67px 8px #8AA9F1";
    const borderColor = "0.5px solid rgba(202, 208, 223, 0.25)";
    const borderRadius = "15px";
    return {
      ...provided,
      opacity,
      transition,
      background,
      borderRadius,
      borderColor,
      boxShadow,
    };
  },
};

const ChipInput = React.forwardRef((props, ref) => {
  // const [redBorder, setRedBorder] = useState(true);

  // const validatedOnClick = () => {
  // setRedBorder(false);
  // };

  let validated = false;

  if (props.validated === undefined) validated = true;
  if (props.validated === true || props.validated === false) {
    validated = props.validated;
  } else {
    validated = validated || false;
  }

  return (
    <ChipInputContainer>
      <LabelText>{props.labelText}</LabelText>
      <StyledBox
        onClick={props.onClick}
        // validated={(props.validated && redBorder) ? props.validated : true}
        validated={validated}
      >
        <Select
          closeMenuOnSelect={true}
          components={animatedComponents}
          defaultValue={props.defaultValue}
          styles={customStyles}
          isMulti
          options={props.optionList}
          onChange={props.onChange}
          ref={ref}
          isDisabled={props.isDisabled ? props.isDisabled : false}
        />
      </StyledBox>
    </ChipInputContainer>
  );
});

export default ChipInput;
