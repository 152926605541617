import styled from "styled-components";

export const FlexContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 768px){
    flex-direction: column;
  }
`;



export const ICard = styled.div`
  width: 50%;
  margin: 30px;
`;
