



/*fetch to marvinauth for general purpose to handle all cases for auth
*/

const localHost = "http://localhost:4000/auth/";
const remoteHost = "https://auth.meetmarvin.com/auth/";
let loginStatus;

export const fetchToMarvinAuth = async (params) => {
    console.log(`made it to the fetch`);
    const {route, method, body  } = params
    // console.log(body);
    // console.log(method);
    try {
      const res = await fetch(`${remoteHost}${route}`, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
       })
       const loginStatus =  res.json();

       console.log(`here is the response from marvinauth`);
       console.log(loginStatus);
       return loginStatus;
    } catch (error) {
        console.log(`error in fetch to marvinAuth`);
        console.log(error);
    }
}



export const deactivateAccount = async (uuid) => {
  const res = await fetchToMarvinAuth({
    route: "deactivateAccount",
    method: "POST",
    body: {
      uuid: uuid
    }
  })
  console.log(res);
  console.log(uuid);
}

const signup = async (email, password) => {
    const res = await fetchToMarvinAuth({
      route: "signup",
      method: "POST",
      body: {
        loginType: "email",
        login: email,
        passwordString: password

      }
    })
    console.log(res);
    console.log(password);
  }

  export default signup;

// Makes a post to <marvinauth>/otp/create to have the server create and distribute an OTP.
export const sendOTPRequest = async (email, firstName) => {
  fetchToMarvinAuth({
    route: "otp/create",
    method: "POST",
    body: {
      email,
      firstName
    }
  })
}

export const verifyOTP = async (email, otp) => {
  loginStatus = fetchToMarvinAuth({
    route: "otp/verify",
    method: "POST",
    body: {
      email,
      otp
    }
  })
  return loginStatus;
}

export const resetPasswordWithToken= async (email, token, password) => {
  loginStatus = fetchToMarvinAuth({
    route: "reset/token",
    method: "POST",
    body: {
      email,
      token, 
      newPassword: password
    }
  })
  return loginStatus;
}