import styled from "styled-components";

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputText = styled.input`
  /* Desktop / Body / Input Field */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 3 */
  color: #a2aecb;
  background: #f6f6f6;
  mix-blend-mode: normal;
  border: none;
  &:focus {
    outline: none;
  }
  /* Inside Auto Layout */

  display: flex;
  flex-grow: 1;
  margin: 0px 10px;
`;

export const InputField = styled.input`
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 17px 20px 17px 14px;
  height: ${(props) => (props.height ? props.height : "40px")};

  /* Gray / Background */
  background: #f6f6f6;

  /* Stroke / White Element */
  border: 0.5px solid rgba(202, 208, 223, 0.25);
  box-sizing: border-box;

  /* Desktop White/Down - Input Field */
  box-shadow: inset -2.5px -2.5px 6px 1px #ffffff,
    inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
  border-radius: 8px;

  outline: none !important;
  border: 1px solid ${(props) => (!props.validated ? "#E73144" : "none")};

  :focus {
    outline: none !important;
    border: 1px solid ${(props) => (!props.validated ? "#E73144" : "#618CF1")};
    /* border-radius: 8px; */
  }

  /* Desktop / Body / Input Field */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const InputLabel = styled.div`
  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  /* Gray / 1 */
  color: #333333;

  mix-blend-mode: normal;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin-bottom: 10px;
`;
