import React from 'react'


import {
    DashBoardContainer,
    ErrorTitle,
    ErrorText,
    MarvinIcon
} from "./styles";

class PageLevelEB extends React.Component{

    constructor(props){
            super(props);
            this.state = {hasError: false}
            this.state = {currentError: null}
    }

    static getDerivedStateFromError (error) {
        this.currentError = error;
        return {hasError: true}
    }

    componentDidCatch(error, errorInfo){
        console.log(`Error occured in render`, error)
        console.log(errorInfo)
    }

    render() {
        if(this.state.hasError){
            return( <DashBoardContainer>
                            <ErrorTitle>Whoops! Sorry, we are experiencing an error.</ErrorTitle>
                            <ErrorText> We are currently experiencing technical difficulties in fulfilling your request. Please try again. {this.currentError}</ErrorText>
                            <MarvinIcon src ="../../../public/images/components/PageLevelEB/MarvinIcon.png"/>
                    </DashBoardContainer>
            )
        }
        return this.props.children;
    }
    

}

export default PageLevelEB;