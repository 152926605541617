import React, {useState, useRef, useEffect, } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { io } from "socket.io-client";
import adapter from 'webrtc-adapter';

import useStore from "../../utils/Zustand/store";
import {initialVideoParams, initialAudioParams} from '../../config/mediaSoup.js';
// import '@tensorflow/tfjs-backend-core';
// import '@tensorflow/tfjs-backend-webgl';
// import * as bodySegmentation from '@tensorflow-models/body-segmentation';
// import '@mediapipe/selfie_segmentation';


/**
 * Marvin Components
 */
import VideoComponent from './VideoComponent'


const VideoSession = (props) => {

  // const model = bodySegmentation.SupportedModels.MediaPipeSelfieSegmentation; // or 'BodyPix'
  
  // const segmenterConfig = {
  //   runtime: 'mediapipe', // or 'tfjs'
  //   modelType: 'general' // or 'landscape'
  // };
  
  // const getSegmenter = async () => {

  //   segmenter = await bodySegmentation.createSegmenter(model, segmenterConfig);
  // }

  console.log(`browserDetailsAdapter.browserDetails.browser`, adapter.browserDetails);
  console.log(adapter.browserDetails.version);
  const history = useHistory();
  let socketRef = useRef(null);

  const mediasoupClient = require('mediasoup-client');
  const [newProducerTranport, setNewProducerTransport] = useState(false);
  const producerTransportRef = useRef(null);
  const consumerTransportRef = useRef(null);

  const deviceRef = useRef(null);
  const routerRtpCapabilitiesRef = useRef(null);
  let serverConsumerTransportId;
  
  const sessionRoom = useStore((state) => state.sessionRoom);
  const [myUserId, setUserId] = useState();
  const meetingName = useStore((state) => state.meetingName);

  const [remotePeers, setRemotePeers] = useState(new Map());
  const [changeInRemotePeers, setChangeInRemotePeers] = useState(false);
  const [tileViewArray, setTileViewArray] = useState([]);
  const [toBeUpdated, update] = useState(false);

  const [chatArray, setChatArray] = useState([]);
  const [ChatOpen, openChat] = useState(false);
  const isChatOpenRef = useRef(ChatOpen);
  const [unreadMessages, setUnreadMessages] = useState(0);

  
  const [locked, toggleLocked] = useState(false);


  useEffect(() => {
    isChatOpenRef.current = ChatOpen
  }, [ChatOpen])
  
  useEffect(() => {
    console.log("Remote peers in useEffect: ", remotePeers);
  }, [remotePeers, toBeUpdated])

  const localMic = useStore((state) => state.localMic);

  const localCamera = useStore((state) => state.localCamera);

  const localHost = "http://localhost:4000";

  const remoteHost = "https://mitsitest.marvin.health:4000";


  const [consumers, setConsumers] = useState([]);



  const [focusedParticipant, setFocusedParticipant] = useState({
    uid: myUserId,
    displayName: meetingName,
    videoStream: null,
    cameraOn: false,
    desktopStream: null,
    sharing: false,
  })



  // *********** Connection to socket.io *********** //

    
    if (socketRef.current == null){
      console.log("Creating a socket.");
      socketRef.current = io(remoteHost , {
        path: "/socket.io/",
        query: {
          "room" : sessionRoom,
          "micOn" : localMic,
          "cameraOn": localCamera,
          "displayName": meetingName
        },
      });
    }
    
 
    useEffect(()=>{    
      return ()=>{
        socket.close();
      }
    },[])



  // *********** End connection to socket.io *********** //

  
  const socket = socketRef.current;
  
  console.log("Here's the socket in VideoSession 'constructor': ", socketRef.current);
  console.log("Room name: ", sessionRoom)
  
  

  // ************** Producer Controls ************** //

  // Pause producer, to be passed down to videoComponent. Needs to be at this level to access socket.
  const producerPause = (producerId) => {
    
    socket.emit('producer-pause', producerId, () => {
      console.log("Serverside producer paused.")
    })
  }
  
  const producerResume = (producerId) => {
    socket.emit('producer-resume', producerId, () => {
      console.log("Serverside producer resumed.")
    })
  }

/**
 * This func emits the close-prod event with the producer
 * 
 * @param {} producerId id sent to server to remove server's producer/consumer pair 
 * server then broadcasts close-prod to all peers in room
 * then initiates acknowledgement and 
 */
  const producerClose = (producerId, callback) => {
    socket.emit('close-prod', producerId, () => {
      console.log(`Serverside producer closed`);
      
    })
    callback();
  }


  /** This listener should remove out the recently closed stream 
   *    from the corresponding entry in the remotePeers map from the incoming peerId
   */
  useEffect(() => {
        
      socket.on('producer-closed', ({peerId, isAudio} ) => {
          let tempPeer;
          let tempPeers = remotePeers;
          console.log(`received a close producer server side event`);
      })
      return () => {
        socket.off(`producer-closed`);
      }
    

  })


  const onCloseScreenShare = (producerId, callback) => {
    socket.emit('close-screenshare-prod', producerId, () => {
      console.log(`screen share producer closed on server side`)
      callback();
    })
    
  }

useEffect(() => {
  socket.on('screen-share-closed', (peerId) => {
    let tempPeer;
    let tempPeers = remotePeers;
    console.log(`received a screen-share-closed event from server for peerId ${peerId}`);
    if(remotePeers.has(peerId)){
      tempPeer = remotePeers.get(peerId);
      console.log(`here is the corresponding remote peers entry`, tempPeer);
      console.log(`setting the desktop stream to null`);
      tempPeer.desktopStream = null;


      //setting the focused participant to be temp peer;
      setFocusedParticipant({...tempPeer, uid: peerId});
      
      tempPeers.set(peerId, tempPeer);

      setRemotePeers(tempPeers);
      setChangeInRemotePeers(prevState => !prevState)
    }
    })
    return () => {
      socket.off('screen-share-closed');
    }

},[])

  // ************ End Producer Controls *********** //
  


  // ************ New producer added ************* //

  useEffect( () => {
    
    socket.on('new-producer', ( producerId, peerId, isDesktop ) => {
      if(producersBox.current.has(producerId)){
        console.log(`we already have received a producer with this producerid`);
      } else{
        producersBox.current.add(producerId);
        console.log("New producer with id: ", producerId);
        console.log("Corresponding peer id: ", peerId);
        console.log("isDesktop: ", isDesktop);
        connectRecvTransport(producerId, peerId, isDesktop);
      }
    })
    return () => {
      socket.off('new-producer');
    }
    
  }, [])

  // ************ End new producer added ************* //
  

  
  // ********************* Peer state *********************** // 

  const producersBox = useRef(new Set());
  console.log(`Here is out brand new set`)
  console.log(producersBox.current);


  const newState = ({ micOn, cameraOn, displayName, screenshareOn }) => {
    console.log("In newState, emitting new-peer-state with: ", {micOn, cameraOn, displayName, screenshareOn });
    // Server emits 'update-peer' to all other peers
    socket.emit('new-peer-state', {micOn, cameraOn, displayName, screenshareOn })
  }
  
  useEffect( () => {
    socket.on('update-peer', (peerId, newPeerState) => {
      console.log(`in update peer, received newPeerState`, newPeerState);
      // newPeerState has form: {micOn, cameraOn, displayName, screenshareOn}
      if (remotePeers.get(peerId)){
        let currentPeer = remotePeers.get(peerId);
        console.log("In update-peer, current Peer: ", currentPeer);
        let newMap = remotePeers.set(peerId, {...currentPeer, ...newPeerState})
        let newPeer = {...currentPeer, ...newPeerState}
        setChangeInRemotePeers(prevState => !prevState)
        console.log("In update-peer, new Map: ", newMap);
        
        // Clean up corresponding tracks / producers if their state is false to ensure congruence
        if (newPeerState.micOn === false){
          remotePeers.set(peerId, {...newPeer, audioStream: null})
        }

        if (newPeerState.cameraOn === false){
          remotePeers.set(peerId, {...newPeer, videoStream: null})
          if(peerId === focusedParticipant.uid)setFocusedParticipant({...focusedParticipant, cameraOn: false})
        }
        if (newPeerState.screenshareOn === false){
          remotePeers.set(peerId, {...newPeer, desktopStream: null})
          if(peerId === focusedParticipant.uid)setFocusedParticipant({...focusedParticipant, sharing: false})
        }
      } else {
        console.log("In update-peer, no current peer.");
        let newMap = remotePeers.set(peerId, {...newPeerState});
        setChangeInRemotePeers(prevState => !prevState)
      }
      
      update(prevState => !prevState);
    })
    
    return () => {
      socket.off('update-peer');
    }
  
  }, [])

  useEffect( () => {

    socket.on('peer-disconnected', (peerId, source) => {
      console.log("The peer with ID: ", peerId, " has disconnected. Source: ", source);
      if (remotePeers.has(peerId)){
        console.log("Deleting that peer from the map.");
        let tempPeers = remotePeers;
        let deleted = tempPeers.delete(peerId);
  
        console.log(`Peer was ${deleted ? "" : "not "} deleted from tempPeers.`)
        console.log(`Here's remotePeers after deleting ${peerId}.`)
        setRemotePeers(tempPeers);
        setChangeInRemotePeers(prevState => !prevState)
        update((prev) => !prev)
      }
    });
    
    return () => {
      socket.off('peer-disconnected');
    }
  
  
  }, [])

  const emitMediaError = ({errorMessage, error}) => {
    socket.emit('mediaError', {errorMessage, error});
  }

  // ************** End peer state **************** // 
  
  
  // *************** Room locking/status ****************** // 

  useEffect(() => {
    socket.on('access-denied', () => {
      console.log(`received access-denied event`);
      alert('The room you select to join is currently locked. Returning to waiting room... ');
      setTimeout(() => {
        props.setInLobby(true);
      }, 3000)
    });
    
    return () => {
      socket.off('access-denied');
    }

  }, [])
  
  // Locks the room
  const handleLockRoom = () => {
    socket.emit('lock-room', socket.id);
  }
  
  // Handles when the room is locked
  useEffect(() => {   
      socket.on('room-locked', () => {
        toggleLocked(true);
      })
      return () => {
        socket.off('room-locked');
      }
    
  
  }, [])
  
  // Unlocks the room
  const handleUnlockRoom = () => {
    socket.emit('unlock-room', socket.id);
  }

  // Handles when the room is unlocked
  useEffect(() => {
    socket.on('room-unlocked', () => {
      toggleLocked(false);
    })
    return () => {
      socket.off('room-unlocked');
    }
  

  }, [])

  // *************** End Room locking/status ****************** // 
  


  // ************* Chat ************** // 
  const handleChatBox = () => {
    openChat((prevState) => !prevState); // Toggle the bool to true
    // If not because the state setter above has not fired by this point ^
    if (!ChatOpen) {
      setUnreadMessages(0);
    }
  };

  // Sends a message from chat to the server
  const sendMessage = (msg) => {
    console.log("New msg from chat: ", msg);
    socket.emit('send-message', msg);
  }


  // Handles receipt of a new message from the server
  useEffect( () => {  
      socket.on("new-message", (text, userId) => {
        console.log("Received a message from the server!")
        let msgObj = JSON.parse(text);
        let { msgTxt, meetingName, time } = msgObj;
        console.log("msgObj: ", msgObj);
        let tempMsg = [meetingName, msgTxt,  time, userId];
    
        // Adds the message to the message array and handles whether a number of unread messages should be shown or updated
        if (msgTxt) {
          setChatArray((prevArray) => [...prevArray, tempMsg]);
          if (isChatOpenRef.current) {
            setUnreadMessages(0);
          } else {
            setUnreadMessages(prevNum => prevNum + 1);
          }
        }
      });

      return () => {
        socket.off('new-message');
      }

  }, [])

  // ************* End Chat *************** //

  // *************** Initial Mitsi Connection ***************** //

  useEffect( () => {
    socket.on('connection-success', ({ socketId }) => {
      console.log("Successfully connected! Your id is: ", socketId);
      setUserId(socketId);
      getRtpCapabilities();
    });
  
    return () => {
      socket.off('connection-success');
    }
  }, [])

  // Gets the RTP capabilities from the router and sets it to the local "routerRtpCapabilties"
  const getRtpCapabilities = () => {
    socket.emit('getRtpCapabilities', ( RtpCapabilities ) => {
      console.log("Received RTP Capabilities from server: ", RtpCapabilities)
      routerRtpCapabilitiesRef.current = RtpCapabilities;
      console.log("Here are the router rtp capabilities: ", routerRtpCapabilitiesRef.current);
      createDevice();
    })
  }

  // Creates a device for this client and sends capabilities to the sever.
  const createDevice = async () => {

    console.log("Creating device..")
    deviceRef.current = new mediasoupClient.Device();
    if (deviceRef.current) console.log("Device was successfully created.")

    await deviceRef.current.load({routerRtpCapabilities: routerRtpCapabilitiesRef.current } );
    // console.log("Here's the device: ", device);
    
    // Sends rtp capabilities to the server
    await socket.emit('deviceRtpCapabilities', deviceRef.current.rtpCapabilities, () => {
      // callback from server
      console.log("Server received device rtpcapabilities");
    });

    createRecvTransport(() => {
      console.log("Recieve transport created.")
    });
    createSendTransport(() => {
      console.log("Send transport created.")
    });
  }
  
        // Creates a send transport on the serverside, then uses the parameters from the serverside to create a transport clientside.
  const createSendTransport = (callback) => {
    socket.emit('createWebRtcTransport', { isConsumerTransport: false }, ( {transportParameters} ) => {

      // Checks for an error object within the transportParameters object
      if (transportParameters.error) {
        console.log("Error in transportParameters: ", transportParameters.error);
        return;
      }

      // console.log("Here are the transport parameters: ", transportParameters);

      // https://mediasoup.org/documentation/v3/mediasoup-client/api/#device-createSendTransport
        producerTransportRef.current = deviceRef.current.createSendTransport({
          id: transportParameters.id,
          iceParameters: transportParameters.iceParameters,
          iceCandidates: transportParameters.iceCandidates,
          dtlsParameters: transportParameters.dtlsParameters,
          sctpParameters: transportParameters.sctpParameters,
      });
      if (producerTransportRef.current) console.log("Producer transport created! ", producerTransportRef.current)

      // console.log("Here's the transport object: ", producerTransport);

      // https://mediasoup.org/documentation/v3/communication-between-client-and-server/#producing-media
      // this event is raised when a first call to transport.produce() is made
      // see connectSendTransport() below
      producerTransportRef.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
        try {
          console.log("Received 'connect' emission to producerTransport. ");
          // Signal local DTLS parameters to the server side transport
          // see server's socket.on('transport-connect', ...)
          await socket.emit('transport-prod-connect', {
            dtlsParameters,
          })
  
          // Tell the transport that parameters were transmitted.
          callback();
  
        } catch (error) {
          errback(error);
        }
      })

      producerTransportRef.current.on('produce', async (parameters, callback, errback) => {
        // console.log("Parameters in producerTransport 'produce' listener: ", parameters);
        // console.log("Callback: ", callback)
        try {
          console.log("Received 'produce' emission to producerTransport. ");
        console.log(`parameters from calling .produce`, parameters)
          // tell the server to create a Producer
          // with the following parameters and produce
          // and expect back a server side producer id
          // see server's socket.on('transport-produce', ...)
          await socket.emit('transport-produce', {
            kind: parameters.kind,
            rtpParameters: parameters.rtpParameters,
            appData: parameters.appData,
          }, ({ id }) => {
            // Tell the transport that parameters were transmitted and provide it with the
            // server side producer's id. (callback from 'produce' listener)
            callback({ id });
          })

        } catch (error) {
          errback(error);
        }
      })
      callback();
      setNewProducerTransport(true);
    })
  }

  const createRecvTransport = async (callback) => {
    await socket.emit('createWebRtcTransport', { isConsumerTransport: true }, ({ transportParameters }) => {

      // console.log("transportParameters in createRecvTransport: ", transportParameters);
      // The server sends back transportParameters needed
      // to create Send Transport on the client side
      if (transportParameters.error) {
        console.error("Recv transport parameters error: ", transportParameters.error)
        return;
      }
  
      try {
        consumerTransportRef.current = deviceRef.current.createRecvTransport({
          id: transportParameters.id,
          iceParameters: transportParameters.iceParameters,
          iceCandidates: transportParameters.iceCandidates,
          dtlsParameters: transportParameters.dtlsParameters,
          sctpParameters: transportParameters.sctpParameters,
        }); // Recv = consumer transport

      } catch (error) {
        // exceptions: 
        // {InvalidStateError} if not loaded
        // {TypeError} if wrong arguments.
        console.log("Device creation error: ", error);
        return;
      }
  
      consumerTransportRef.current.on('connect', async ({ dtlsParameters }, callback, errback) => {
        try {
          // Signal local DTLS parameters to the server side transport
          // see server's socket.on('transport-Conn-connect', ...)
          await socket.emit('transport-cons-connect', {
            dtlsParameters,
          })
  
          // Tell the transport that parameters were transmitted.
          callback()
        } catch (error) {
          // Tell the transport that something was wrong
          errback(error)
        }
      })
      
      socket.emit('cons-trans-created', () => {
        console.log("Server signaled")
      })
      callback();
    })
  }

  const connectSendTransport = async ({ audioTrack, videoTrack, desktopTrack }) => {
    // we now call produce() to instruct the producer transport
    // to send media to the Router
    // https://mediasoup.org/documentation/v3/mediasoup-client/api/#transport-produce
    // this action will trigger the 'connect' and 'produce' events above
    // console.log("In connect sendTransport, videoParams.track: ", videoParams.track);
    // console.log("In connect sendTransport, audioParams.track: ", audioParams.track);

    let prod_rets = {}
    console.log("Producer Transport: ", producerTransportRef.current)

    let videoProducer;
    let audioProducer;
    let desktopProducer;

    if (producerTransportRef.current) {

      console.log("getProducers");

      if (videoTrack){
        console.log("Calling produce (video).")
        let videoParams = initialVideoParams;
        videoParams = {
          track: videoTrack,
          ...videoParams
        }
        videoProducer = await producerTransportRef.current.produce(videoParams);

        videoProducer.on('trackended', () => {
          // ccreateonsole.log('track ended');
          // close video track
        });
      
        videoProducer.on('transportclose', () => {
          // console.log('transport ended');
          // close video track
        });
      
      }

      if (audioTrack){
        console.log("Calling produce (audio).")

        let audioParams = initialAudioParams;
        audioParams = {
          track: audioTrack,
          ...audioParams,
        }
        audioProducer = await producerTransportRef.current.produce(audioParams);

        audioProducer.on('trackended', () => {
          // console.log('track ended');
          // close audio track
        });
      
        audioProducer.on('transportclose', () => {
          // console.log('transport ended');
          // close audio track
        });
      }

      if (desktopTrack){
        console.log(`received a desktop track`);

         let desktopParams = initialVideoParams;

         desktopParams = {
            track: desktopTrack,
            appData: {
              isDesktop: true
            },
            ...desktopParams
         }
         console.log(`here are the desktop params"`, desktopParams);
         console.log(`now calling produce with desktop params`);
         desktopProducer = await producerTransportRef.current.produce(desktopParams);
         console.log(`here is our desktop producer: `, desktopProducer);
         desktopProducer.on('trackended', () => {
           // createconsole.log('track ended');
           // close video track
         });
       
         desktopProducer.on('transportclose', () => {
           // console.log('transport ended');
           // close video track
         });
      }
      prod_rets = {videoProducer, audioProducer, desktopProducer};
    } 

    return prod_rets;
  }
  

  const connectRecvTransport = async (remoteProducerId, peerId, isDesktop) => {
    // for consumer, we need to tell the server first
    // to create a consumer based on the rtpCapabilities and consume
    // if the router can consume, it will send back a set of params as below
    console.log("In connectRecvTransport!")
    // console.log("In connectRecvTransport, here is the device.", deviceRef.current)
    try {
      
      await socket.emit('consume', {
        rtpCapabilities: deviceRef.current.rtpCapabilities,
        remoteProducerId,
        serverConsumerTransportId,
      }, async ({ params }) => {
        if (params.error) {
          console.log('Cannot Consume', params);
          return;
        }
    
        // then consume with the local consumer transport
        // which creates a consumer
        const consumer = await consumerTransportRef.current.consume({
          id: params.id,
          producerId: params.producerId,
          kind: params.kind,
          rtpParameters: params.rtpParameters
        })
  
  
        setConsumers(prevConsumers => [...prevConsumers, consumer]);
  
        consumer.on('producerclose', () => {
          //setConsumers(consumers.filter(consumer => consumer.producerId !== remoteProducerId)) or something
          console.log(`producerClose in consumer.on listener`);
  
          consumer.close();
        })
  
        console.log("new consumer: ", consumer)
        
        // console.log('Consumer in connectRecvTransport: ', consumer)
              
        console.log(`calling resume on consumer`);
        
        consumer.resume();
        
        
        const newPeerTemplate = {
          audioStream: null,
          videoStream: null,
          desktopStream: null,
          displayName: "Marvin Friend",
          micOn: false,
          cameraOn: false,
        }
  
        const {track} = consumer;
        
        console.log(`here is our track`, track);
  
        let tempStream = new MediaStream();
        tempStream.addTrack(track);
  
        if (track.kind === 'video'){
          console.log("Track kind is video.")
          if (remotePeers.has(peerId)){
            console.log("Setting existing peer data.");
            let currentPeerObject = remotePeers.get(peerId);
            
            //Line to take newest incoming desktop stream and reset focuseParticipant to that stream
            if (isDesktop){
              setRemotePeers((prevRemotePeers) => prevRemotePeers.set(peerId, {...currentPeerObject, desktopStream: tempStream}));
              setChangeInRemotePeers(prevState => !prevState)
              setFocusedParticipant(
                {
                  displayName: currentPeerObject.displayName,
                  videoStream: currentPeerObject.videoStream,
                  desktopStream: tempStream,
                  cameraOn: currentPeerObject.cameraOn
                }
                );
              } else {
                setRemotePeers((prevRemotePeers) => prevRemotePeers.set(peerId, {...currentPeerObject, videoStream: tempStream}));
                setChangeInRemotePeers(prevState => !prevState)
  
            }
            update((prev) => !prev)
          } else {
            console.log("Setting new peer data.")
            setRemotePeers((prevRemotePeers) => prevRemotePeers.set(peerId, {...newPeerTemplate, videoStream: tempStream}))
            setChangeInRemotePeers(prevState => !prevState)
  
            update((prev) => !prev)
          }
        }
        
        if (track.kind === 'audio'){
          console.log("Track kind is audio.")
          if (remotePeers.has(peerId)){
            console.log("Setting existing peer data.")
            let currentPeerObject = remotePeers.get(peerId);
            setRemotePeers((prevRemotePeers) => prevRemotePeers.set(peerId, {...currentPeerObject, audioStream: tempStream}))
            setChangeInRemotePeers(prevState => !prevState);
            update((prev) => !prev);
          } else {
            console.log("Setting new peer data.")
            setRemotePeers((prevRemotePeers) => prevRemotePeers.set(peerId, {...newPeerTemplate, audioStream: tempStream}))
            setChangeInRemotePeers(prevState => !prevState)
  
            update((prev) => !prev)
          }
        }
  
        // Server defaults to consumers with paused: true, so once received, tell the server to resume.
        socket.emit('consumer-resume', { serverConsumerId: params.serverConsumerId })
      }) 
    } catch (error) {
      console.log(`Error consuming:`, error)
    }
  }
  
  // *************** End initial Mitsi Connection ***************** //

  return (
      <VideoComponent getProducers={connectSendTransport} 
                      newProducerTransport={newProducerTranport} 
                      remotePeers = {remotePeers} 
                      changeInRemotePeers = {changeInRemotePeers}
                      tileViewArray = {tileViewArray}
                      setTileViewArray = {setTileViewArray}
                      update={update} 
                      sendMessage={sendMessage}
                      openChat={openChat}
                      ChatOpen={ChatOpen}
                      handleChatBox = {handleChatBox}
                      chatArray={chatArray}
                      unreadMessages={unreadMessages}
                      myUserId={myUserId}
                      handleLockRoom = {handleLockRoom}
                      handleUnlockRoom = {handleUnlockRoom}
                      producerPause={producerPause}
                      producerResume={producerResume}
                      producerClose = {producerClose}
                      newState={newState}
                      focusedParticipant = {focusedParticipant}
                      setFocusedParticipant = {setFocusedParticipant}
                      onCloseScreenShare = {onCloseScreenShare}
                      locked={locked}
                      consumers = {consumers}
                      handleEndSession = {props.handleEndSession} 
                      emitMediaError={emitMediaError}
        />
  );

}

export default VideoSession;
