import styled from "styled-components";

export const NotificationBox = styled.div`
    /* Notification */
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 17px;
    gap: 16px;
    margin-bottom: 30px;
    /* position: absolute;
    left: 39.38%;
    right: 29.38%;
    top: 8.98%; */
    width: 508px;
    /* Gradients/Blue Purple */

    background: linear-gradient(155.69deg, rgba(0, 0, 0, 0.16) -13.54%, rgba(255, 255, 255, 0) 111.28%), linear-gradient(331.49deg, #618CF1 -18.45%, #6569EE 93.49%);
    /* Color Elements/BluePurple Card */

    box-shadow: -5px -5px 10px #FAFBFF, 6px 6px 14px rgba(201, 207, 227, 0.75);
    border-radius: 8px;

`;

export const NotificationText = styled.div`
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 123.2%;
    /* identical to box height, or 16px */
    letter-spacing: 0.3px;
    background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

`;

export const NotificationTitle = styled.div` 

    /* Desktop/Header/3 */

    font-family: 'Syne';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;

    /* White Elements/Default */

    background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    margin-bottom: 5px;
`;



export const CheckedCircle = styled.img` 
    height: 48px;
    width: 48px;
`;

export const NotificationContent = styled.div`
    display: flex;
    flex-direction: column;

`;




