import React, {useEffect, useState} from 'react';
import MeetWR from '../MeetWR';
import VideoSession from '../VideoSession';
import { useHistory, useParams } from 'react-router-dom';
import useStore from "../../utils/Zustand/store";

const Meetings = (props) => {

    let { room } = useParams();

    console.log("room: ", room);
    
    const updateSessionRoom = useStore((state) => state.updateSessionRoom);
  
    useEffect(() => {
      updateSessionRoom(room);
      console.log(`here's your match`)
      console.log(room)
    }, [])

    const [inLobby, setInLobby] = useState(true);
    const handleEndSession = () => setInLobby(true);

  return (
    <>
       {
      inLobby ? <MeetWR setInLobby = {setInLobby}></MeetWR>
      :
      <VideoSession handleEndSession = {handleEndSession} setInLobby = {setInLobby}></VideoSession>
       }
    </>
  )
}

export default Meetings