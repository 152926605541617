
export const convertToHhmm = (milliseconds) => {
  let hours = Math.floor(milliseconds / 3600000);
  milliseconds %= 3600000;
  let minutes = Math.floor(milliseconds / 60000);
  milliseconds %= 60000;
  // let seconds = Math.floor(milliseconds / 1000);

  let timeString =
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") // +
    // ":" +
    // seconds.toString().padStart(2, "0");

  return timeString;
};
