import { ButtonBox, ButtonLabelText, ButtonNavArrow } from "./styles";

const PrimaryIconButtonXL = (props) => {
  return (
    <ButtonBox onClick={props.onClick}>
      <ButtonLabelText>{props.labelText}</ButtonLabelText>
      <ButtonNavArrow src="/images/pages/WaitingRoom/Arrow.svg" />
    </ButtonBox>
  );
};
export default PrimaryIconButtonXL;
