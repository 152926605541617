import styled from "styled-components";

export const BG = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: conic-gradient(
    from 180.03deg at 50% 50%,
    rgba(194, 226, 245, 0.3) 0deg,
    rgba(194, 210, 245, 0.7) 101.25deg,
    rgba(224, 200, 232, 0.7) 159.38deg,
    rgba(203, 204, 242, 0.7) 271.87deg,
    rgba(196, 221, 237, 0.3) 360deg
  );
  backdrop-filter: blur(5px);
  /* backdrop-filter: blur(18px); */

  // From figma, converted for RGBA
  /* background: conic-gradient(from 180.03deg at 50% 50%, #C2E2F5 0deg, #C2D2F5 101.25deg, #E0C8E8 159.38deg, #CBCCF2 271.87deg, #C4DDED 360deg); */

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 10;
`;

export const FullscreenVideoWrapper = styled.div`
  height: 100vh;
  width: 100vw;
`;
export const VideoWrapper = styled.div`
  // Forces 16:9 aspect ratio
  /* height: 45vw;
    width: 80vw; */

  height: 40vw;
  width: 60vw;

  position: relative;

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Up/ Modal */
  /* box-shadow: -7px -7px 14px rgba(202, 208, 223, 0.4), 7px 7px 14px rgba(202, 208, 223, 0.6), inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4); */
  border-radius: 16px;

  overflow-y: hidden;
`;
