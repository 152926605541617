// For toggling between running locally / hosted
const marvURL = "https://userappapi.meetmarvin.com";
// const localURL = "http://localhost:4000";

const postURL = marvURL; // CHANGE BEFORE DEPLOYING / TESTING

// Updates a profile given the new object and the profileId
export const UpdateDBProfile = async (paramObj, profileId) => {
  console.log("paramObj in profileOperations", paramObj);
  console.log({ ...paramObj });
  try {
        const profileUpdateStatus = await fetch(postURL + "/profile/" + profileId, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(paramObj),
        });
        if (profileUpdateStatus) {
          console.log(await profileUpdateStatus.json());
        }
      } catch (error) {
    console.log(`error in updating db, `,error)
  }
};

// Looks up a user profile from the database given a profileId
export const ReadDBProfile = async (profileId) => {
  try {
    const response = await fetch(postURL + "/profile/" + profileId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let result = await response.json();
    console.log("result in readDBProfile: ", result);
    return result;
  } catch (error) {
    console.log(`error in reading dbprofile`, error)
  }
  
};

// Looks up a list of user sessions from the database given a profileId
export const ReadDBSessions = async (profileId) => {
  console.log("Making a fetch to: " + postURL + "/sessions/" + profileId);
  try {
      const response = await fetch(postURL + "/sessions/" + profileId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let sessions = await response.json();
      console.log("IN PROFILEOPERATIONS, SESSIONS: ", sessions);
      return sessions;
  } catch (error) {
    console.log(`error in readDbSessions`, error);
  }
};

// Updates a single session in the DB given a sessionId
export const UpdateDBSession = async (sessionId, newSession) => {
  console.log("REQUESTING AN UPDATE TO A DB SESSION");
  try {
    const profileUpdateStatus = await fetch(postURL + "/session/" + sessionId, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSession),
    });
    console.log(profileUpdateStatus);
  } catch (error) {
    console.log(`Error  in updateDBSession`, error);
  }
  
};

// Gets all therapists corresponding with a given userId
export const GetUserTherapists = async (userId) => {
  try {
    const response = await fetch(postURL + "/therapists/" + userId, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    let therapists = await response.json();
    console.log("IN PROFILEOPERATIONS, THERAPISTS: ", therapists);
    return therapists;
    
  } catch (error) {
    console.log(`Error in GetUserTherapist`, error)
  }
};

export const ReadDBTherapist = async (therapistId) => {

  try {
      const response = await fetch(postURL + "/therapist/" + therapistId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let result = await response.json();
      return result;
  } catch (error) {
    console.log(`Error in readDBTherapist`, error)
  }
};