import styled from "styled-components";

export const VideoBG = styled.video`
  width: 560px;
  height: ${(props) => (props.aspect ? `${560 / props.aspect}px` : "315px")};

  background-repeat: no-repeat;
  display: ${(props) => (props.localCamera ? "flex" : "none")};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  object-fit: fill;
  transform: rotateY(180deg);
  @media screen and (max-width: 768px) {
    width: 460px;
    height: ${(props) => (props.aspect ? `${460 / props.aspect}px` : "315px")};
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const NameCircle = styled.div`
  background: linear-gradient(
      155.69deg,
      rgba(0, 0, 0, 0.16) -13.54%,
      rgba(255, 255, 255, 0) 111.28%
    ),
    linear-gradient(332.15deg, #618cf1 4.89%, #6569ee 93.6%);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-position: center;
`;

export const Name = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
`;

export const BottomButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  background: ${(props) =>
    !props.toggledOn
      ? "linear-gradient(309.77deg, #FA9B94 7.54%, #E15160 76.84%);"
      : "linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;"};

  /* box-shadow: "-1.66667px -1.66667px 5px #FFFFFF, 2.5px 2.5px 10px rgba(202, 208, 223, 0.9), inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);"; */
  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);

  &:hover {
    cursor: pointer;
    background: ${(props) =>
      !props.toggledOn
        ? "linear-gradient(343.84deg, #FA9B94 -20.1%, #E25563 47.76%, #E15160 81.25%);"
        : "linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #E3E8F0;"};
    /* 
        border: ${(props) =>
      props.muted ? "1px solid rgba(210, 117, 117, 0.25);" : "none;"}; */

    box-shadow: ${(props) =>
      !props.toggledOn
        ? "-1.66667px -1.66667px 5px #FFFFFF, 2.5px 2.5px 10px rgba(202, 208, 223, 0.9), inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);"
        : "-2px -8px 10px #FFFFFF, 0px 6px 14px rgba(202, 208, 223, 0.85), inset 0px 0px 2px rgba(255, 255, 255, 0.5);"};
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
`;

export const WaitingRoomHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WaitingRoomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const VidSession = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items:center ; */
  z-index: 1;
  margin-right:7vw ;
`;

export const ScreenPreviewContainer = styled.div`
  background: black;
  width: 560px;
  height: 315px;
  background-repeat: no-repeat;
  display: ${(props) => (!props.localCamera ? "flex" : "none")};
  border-radius: 8px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const VidToolsNavbar = styled.div`
  display: flex;
  width: 573px;
  /* margin-right: 50px;
  margin-left: 50px; */
  /* column-gap: 82px; */
  justify-content:space-evenly ;
  /* border: 1px solid red; */
`;

export const BeginSessionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Icon = styled.img`
  width: 17px;
  height: 23px;
`;

export const TextBox = styled.span`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

export const ButtonBox = styled.div`
  height: 40px;
  //  width: 118px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;


export const Disclaimer = styled.div`
  /* Desktop/Body/Label */

  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Gray / 1 */

  color: #333333;
  margin-top: 10px ;
  align-self:center ;
`;