import React, {useEffect, useState } from 'react';

// Page higher-order-component
import PageWrapper from '../PageWrapper';

// config
import { initialUserMedia } from '../../config/mediaSoup';

// components
import WaitingRoom from "../../components/WaitingRoom";

// utils
import {useStore} from '../../utils/Zustand/store';

import {useHistory} from 'react-router-dom';
import Session from 'supertokens-auth-react/recipe/session';



const MeetWR = (props) => {

    const updateGuestSession = useStore((state) => state.updateGuestSession);

    const checkForValidLogin = async () => {

        let sessionExists = await Session.doesSessionExist();
    
        console.log(`Does session exist at login page?${sessionExists}`);
    
        if (!sessionExists)updateGuestSession(true);
    
      }
      checkForValidLogin();
    // variables for guest sessions
    const meetingName = useStore(state => state.meetingName);

    const changeMeetingName = useStore(state => state.changeMeetingName);

    //area of code for zustand mic and camera states
    const localMic = useStore(state => state.localMic);

    const updateLocalMic = useStore(state=> state.updateLocalMic);

    const localCamera = useStore(state => state.localCamera);
    
    const updateLocalCamera = useStore(state => state.updateLocalCamera);

    //local state variables for state
    
    const history = useHistory();

    const [localStream, setLocalStream] = useState(null);

    useEffect(() => {
        // Uses initialUserMedia video constraints if camera is on, otherwise does not collect video.
        // Collects audio with default constraints if localMic is on.
        const getUserMediaWR = async () => {
            let tempStream;
            if(navigator)tempStream = await navigator.mediaDevices.getUserMedia({video: localCamera && initialUserMedia.video, audio: localMic});
            if(tempStream)setLocalStream(tempStream);
        }

        // If either camera or mic are on, collect the stream.
        if (localCamera || localMic) getUserMediaWR();

        // If camera is off but local video exists, stop the video tracks (stop using camera)
        if (!localCamera && localStream && localStream.getVideoTracks()){
            for (let track of localStream.getVideoTracks()){
                track.stop();
            }
        }
        // If microphone is off but local audio exists, stop the audio tracks (stop using microphone)
        if (!localMic && localStream && localStream.getAudioTracks()){
            for (let track of localStream.getAudioTracks()){
                track.stop();
            }
        }

    }, [localMic, localCamera])
    
    //Zustand Mic and Camera
    const bhandleClick =  () => {
        
        changeMeetingName(meetingName);

        console.log(`Here is \n the zustand camera: ${localCamera} \n and zustand mic: ${localMic}`);

        props.setInLobby(false);
    }
    
   

    //Tab label
    const tab = "meetWR";
    
    return (
        
            <PageWrapper tab={tab}>
                {/* {console.log(`Here is zustand state, \n ${localCamera}, ${localMic}`)} */}
                    <WaitingRoom    meetingName={meetingName} 
                                    setMeetingName={changeMeetingName}
                                    virtualBG={false} 
                                    localStream={localStream}
                                    updateLocalCamera = {updateLocalCamera}
                                    updateLocalMic = {updateLocalMic}  
                                    localMic = {localMic}
                                    localCamera = {localCamera}
                                    bhandleClick = {bhandleClick}/>
            </PageWrapper>
        
    )
}

export default MeetWR;