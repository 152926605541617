//Xtracted function to submitContactMarvin
import { sendEmail } from './emailSenders';

const submitContactMarvin = ( newMsg, susie, profileId, updateMsg ) => {

    // Topic is from the safetymenu / contactMarvin select input, things like "Technical Support, Product Feedback, General questions, etc."
    // Message is the user's text in the input field.
    console.log("newMsg: " + newMsg)
    console.log("topic: " + newMsg.topic)
    console.log("Message: " + newMsg.msg)

    updateMsg(newMsg);

    const emailContent = {
        subject: `Contact Marvin, Topic: ${newMsg.topic}`,
        profileId,
        fullName: susie.firstName + " " + susie.lastName,
        topic: newMsg.topic,
        message: newMsg.msg,
        userEmail: susie.email,
        userPhone: susie.phoneNumber,
    }
    sendEmail(emailContent);
}

export default submitContactMarvin;
