import React, {useState, useEffect, useRef} from 'react';
import InputBoxWithLabel from '../InputBoxWithLabel/index.js';
import PasswordInput from '../PasswordInput/index.js';
import OTP from '../OTP/index.js';
import {useHistory} from "react-router-dom";
import {
    OuterBox,
    Body,
    CircleBtn,
    TitleText,
    InstructionsText,
    InputContainer,
    MarvinImage,
    InvalidOTPPopup
} from './styles.js';

import AdjustableSpacer from '../AdjustableSpacer/index.js';
import PrimaryButton from '../PrimaryButton/index.js';
import useStore from '../../utils/Zustand/store.js';
import { validateEmail } from '../../utils/MiscHelpers/validators.js';
import { sendOTPRequest, verifyOTP, resetPasswordWithToken } from '../../utils/Auth/marvinAuth.js';
const PwdResetCard = () => {
    
    // Stage agnostic
    const backIcon  = "/images/components/Navbar/BackIcon.svg";
    const [stage, setStage] = useState(1)
    const [emailAddress, setEmailAddress] = useState("");
    
    const history = useHistory();
    let titles = ["Password Reset", "Instructions Sent!", "Enter Verification Code", "Password Reset"]
    let instructions = [
        "Enter the email address you used when signing up and we'll send you instructions to reset your password.", 
        "If you do not receive instructions at the email address you entered within 5 minutes, please click here to re-enter your email address or click here to contact our support team.", 
        "Please enter the 6-digit code we've sent to " + emailAddress, 
        "Your password should have a minimum 8 characters - at least 1 upper case, 1 lower case, and 1 numerical character."
    ]
    let buttonTexts = ["Send reset instructions", "", "Submit Verification Code", "Save password"]
    // *********************************** Stage 1 ***********************************
        // Active / Unactive state for stage 1 primary button
        const [primaryActive1 , setPrimaryActive1]= useState(false);

        // Primary Button Stage 1
        const stage1Primary = () => {
            console.log(`Sending an email to ${emailAddress}.`)
            sendOTPRequest(emailAddress);
            setStage(2);
        }

        useEffect(() => {
            if (emailAddress && validateEmail(emailAddress)){
                setPrimaryActive1(true);
            } else {
                setPrimaryActive1(false);
            }
        }, [emailAddress])

    // *********************************** Stage 2 ***********************************
        // timer ID to cancel timeout if back button is clicked on stage 2
        const redirectTimerID = useRef()
        // Sets a timer upon reaching stage 2 to redirect to stage 3, otherwise clears the timer
        useEffect(() => {
            if (stage === 2){
                // In 10 seconds, redirect to stage 3.
                redirectTimerID.current = setTimeout(() => {
                    if (stage === 2) setStage(3);
                }, 10000)
            }
            // If no longer on stage 2 but the timer is still running (should only be present on stage 2)
            else if (redirectTimerID){
                clearTimeout(redirectTimerID.current);
                redirectTimerID.current = null;
            }
        }, [stage])
        
    // *********************************** Stage 3 ***********************************
        // Active / Unactive state for stage 3 primary button
        const [primaryActive3, setPrimaryActive3] = useState(false);

        const [validOTP, setValidOTP] = useState(true);
        //local state value for digits entered
        const [digits, setDigits] = useState({
            first: "",
            second: "",
            third: "",
            fourth: "",
            fifth: "",
            sixth: "",
        });

        const resetDigits = () => {
            setDigits({first: "", second: "", third: "", fourth: "", fifth: "", sixth: ""})
            setValidOTP(false)
        }

        const  {first,second,third,fourth,fifth,sixth} = digits;

        //handle change for digits
        const handleDigitsChange = (event) => {
            const { name, value } = event.target;
            setDigits((prevState) => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        };

        useEffect(() => {
            if (digits.first && digits.second && digits.third && digits.fourth && digits.fifth && digits.sixth){
                setValidOTP(true);
                setPrimaryActive3(true);
            }
        }, [digits])

        const [token, setToken] = useState("");

        const validateOTP = async ( OTP, callback ) => {
            // Needs logic added to fetch to server with OTP so the server can check if they match. Server should then return to let 
            // the user know it's set to change the password.
            let response = await verifyOTP(emailAddress, OTP);
            console.log("Response: ", response);
            if (response.validOTP) {
                setToken(response.token);
                callback(true) 
            }
            else callback(false);
        }

        
        const stage3Primary = () => {
            let inputOTP = first+second+third+fourth+fifth+sixth;
            validateOTP(inputOTP, (validOTP) => validOTP ? setStage(4) : resetDigits())
        }

    // *********************************** Stage 4 ***********************************
        // Active / Unactive state for stage 4 primary button
        const [primaryActive4, setPrimaryActive4] = useState(false);

        const [newPassword, setNewPassword] = useState("");
        const [newPassword2, setNewPassword2] = useState("");

        const setPasswordUpdated = useStore(state => state.setPasswordUpdated);

        // Should validate, then post new password to auth
        const stage4Primary = async () => {
            const response =  await  resetPasswordWithToken(emailAddress, token, newPassword);
            console.log("Vroom vroom", response);
            setPasswordUpdated(true);
            history.push('/');
        }
        useEffect(() => {
            if(newPassword === newPassword2){
                setPrimaryActive4(true);
            } else setPrimaryActive4(false);
        },[newPassword, newPassword2])
    // Stage bodies
    const Stage1Content = 
        <>
            <InstructionsText> {instructions[0]} </InstructionsText>
            <InputContainer>
                <InputBoxWithLabel 
                    inputLabel="Email address"
                    value={emailAddress}
                    onChange={(evt) => setEmailAddress(evt.target.value)}/>
            </InputContainer>
            <PrimaryButton onClick={stage1Primary} active={primaryActive1}> 
             {buttonTexts[0]} 
            </PrimaryButton>
        </>

    const Stage2Content = 
        <>
            <MarvinImage/>
            <InstructionsText> {instructions[1]}</InstructionsText>
        </>
    const Stage3Content = 
        <>
            <InstructionsText> {instructions[2]} </InstructionsText>
            <InputContainer>
            {!validOTP && <InvalidOTPPopup> Incorrect verification code. Please retype or click here to resend code. </InvalidOTPPopup>}
            <OTP    handleChange = {(evt) => handleDigitsChange(evt)}
                        digits = {digits}></OTP>
            </InputContainer>
            <PrimaryButton onClick={stage3Primary} active={primaryActive3}> 
                {buttonTexts[2]}
            </PrimaryButton>
        </>
    const Stage4Content = 
        <>
            <InstructionsText> {instructions[3]} </InstructionsText>
            <InputContainer>
                <PasswordInput
                    inputLabel="New password"
                    value={newPassword}
                    onChange={(evt) => setNewPassword(evt.target.value)}
                    validated = {true}
                    onClick = {() => {}}
                    />
                    <AdjustableSpacer height={'2vh'}/>
                <PasswordInput
                    inputLabel="Confirm password"
                    value={newPassword2}
                    onChange={(evt) => setNewPassword2(evt.target.value)}
                    validated = {true}
                    onClick = {() => {}}/>
            </InputContainer>
            <PrimaryButton onClick={stage4Primary} active={primaryActive4}>
                {buttonTexts[3]}
            </PrimaryButton>
        </>
                    
    return(
        <OuterBox stage={stage}>
            <CircleBtn onClick={() => {stage === 1 ? history.push('/') : setStage(1)}}> <img src={backIcon}/> </CircleBtn>
            <Body>
            <TitleText> {titles[stage - 1]} </TitleText>
            {stage === 1 && Stage1Content}
            {stage === 2 && Stage2Content}
            {stage === 3 && Stage3Content}
            {stage === 4 && Stage4Content}
            </Body>
        </OuterBox>
    )
}

export default PwdResetCard;