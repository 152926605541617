import styled from "styled-components";

export const OuterBox = styled.div`
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  width: 693px;
  height: 614px;

  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  z-index: 1000;
`;

export const Content = styled.div`
  padding: 25px;
`;

export const Body = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

export const ProfileText = styled.div`
  /* Desktop/Header/3 */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;
`;

export const ProfileHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const Photo = styled.div`
  border-radius: 50%;
  object-fit: cover;
  width: 88.68px;
  height: 90px;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;

  /* White Elements/Default */

  /* background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6; */
  /* Stroke / White Element */

  border: 1px solid rgba(202, 208, 223, 0.25);
  /* Buttons/Secondary Large /Default */

  box-shadow: -4px -4px 16px #ffffff, 6px 6px 12px rgba(202, 208, 223, 0.85);
`;

export const ChangePhotoInput = styled.input`
  display: none;
`;

export const InputContainer = styled.div`
  width: 45%;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const RaceContainer = styled.div`
  width: 100%;
`;

export const RemovePhotoBtn = styled.button`
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  white-space: nowrap;

  // Font
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  // position: absolute;
  width: 126px;
  height: 32px;

  color: white;
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;
  /* Buttons/Primary Small /Default */

  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;

  &:hover {
    /* White Elements/Desktop White Hover <50 */
    cursor: pointer;
    background: linear-gradient(
        342.94deg,
        rgba(255, 255, 255, 0.4) -182.89%,
        rgba(0, 0, 0, 0.4) 132.93%
      ),
      linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
    background-blend-mode: soft-light, normal;
    /* Buttons/Primary Small /Hover */

    box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
      inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
  }

  border: none;
`;

export const ChangePhotoBtn = styled.button`
  /* Secondary Button Small /Default */

  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  white-space: nowrap;

  // position: absolute;
  width: 128px;
  height: 32px;
  // left: 356px;
  // top: 394px;

  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Buttons/Secondary& Icon Small/Default */

  box-shadow: -2px -3px 10px #ffffff, 0px 3px 10px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 75px;
  border: none;

  &:hover {
    /* White Elements/Desktop White Hover <50 */
    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #e3e8f0;

    /* Buttons/Secondary& Icon Small/Hover */
    box-shadow: -2px -8px 10px #ffffff, 0px 6px 14px rgba(202, 208, 223, 0.85),
      inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    border-radius: 75px;
    cursor: pointer;
  }

  /* Desktop / Button / Secondary */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
`;

export const SaveChangesBtn = styled.button`
  width: 144px;
  height: 39px;
  /* Buttons/Primary Disabled */
  background: ${(props) =>
    props.changesMade
      ? "linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, #6ADCB3 -14.42%, #6ACCDC 99.59%);"
      : "linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, rgba(106, 220, 179, 0.6) -14.42%, rgba(106, 204, 220, 0.6) 99.59%);"};
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Disabled */
  box-shadow: ${(props) =>
    props.changesMade
      ? "0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 223, 219, 0.7);"
      : "box-shadow: inset 2px 2px 4px rgba(115, 208, 208, 0.3), inset -2px -2px 4px rgba(213, 249, 241, 0.4);"};
  border-radius: 75px;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  border: none;

  /* Font  */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #fefefe;
  mix-blend-mode: normal;

  /* Alignment */
  align-self: center;

  &:hover {
    &:enabled {
      cursor: pointer;

      /* Buttons/Primary Hover */
      background: linear-gradient(
          342.94deg,
          rgba(255, 255, 255, 0.4) -182.89%,
          rgba(0, 0, 0, 0.4) 132.93%
        ),
        linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
      background-blend-mode: soft-light, normal;

      /* Buttons/Primary Small /Hover */
      box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
        inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
    }
  }
`;
