import AdjustableSpacer from "../AdjustableSpacer";
import { useState } from "react";
import Session from "../Session";
import MonthButton from "../Buttons/Meet/MonthButton/index.tsx";
import CheckInPoint from "../CheckInPoint";
import RescheduleBox from "../RescheduleBox";
import CancelBox from "../CancelBox";
import { SessionListBox, Title, MonthStrip } from "./styles";

import moment from "moment";

import { useRef } from "react";

const SessionList = (props) => {
  //Global variables for manipulating sessions.
  let monthTextList = [];

  const firstMonthList = [];
  const secondMonthList = [];
  const thirdMonthList = [];

  const monthList = [];
  monthList.push(firstMonthList);
  monthList.push(secondMonthList);
  monthList.push(thirdMonthList);

  let prevMonth = moment(props.sortedSessions[0].sessionDate).get("month");

  const currDate = moment();

  let monthCounter = 0;
  // let sessionCounter = 0;
  monthTextList[monthCounter] = moment(
    props.sortedSessions[0].sessionDate
  ).format("MMMM");
  for (let i = 0; i < props.sortedSessions.length; i++) {
    // sessionCounter++;
    if (i % 8 === 0 && i !== 0) {
      // const checkIn = Object.assign(checkInPoint, )
      // console.log(object)
      const checkInPoint = {
        sessionId: "Checkin",
        date: moment(props.sortedSessions[i - 1].sessionDate),
        canceled: false,
        rescheduled: false,
      };
      monthList[monthCounter].push(checkInPoint);
      // console.log()
    }

    if (monthCounter > 2) break;

    let currMonth = moment(props.sortedSessions[i].sessionDate).get("month");
    if (currMonth === prevMonth) {
      monthList[monthCounter].push(props.sortedSessions[i]);
    } else if (currMonth !== prevMonth) {
      prevMonth = currMonth;
      monthCounter++;
      if (monthCounter < 3) {
        monthList[monthCounter].push(props.sortedSessions[i]);
        monthTextList[monthCounter] = moment(
          props.sortedSessions[i].sessionDate
        ).format("MMMM");
      }
    }
  }

  // console.log(firstMonthList);
  // console.log(secondMonthList);
  // console.log(thirdMonthList);
  // const Sessions = props.sortedSessions;

  const [currentMonth, toggleCurrentMonth] = useState(0);

  const [mOneActive, toggleMOne] = useState(true);
  const [mTwoActive, toggleMTwo] = useState(false);
  const [mThreeActive, toggleMThree] = useState(false);

  const handleChangeMonth = (numMonth) => {
    switch (numMonth) {
      case 0:
        toggleMOne(true);
        toggleMTwo(false);
        toggleMThree(false);
        toggleCurrentMonth(0);
        break;
      case 1:
        toggleMOne(false);
        toggleMTwo(true);
        toggleMThree(false);
        toggleCurrentMonth(1);
        break;
      case 2:
        toggleMOne(false);
        toggleMTwo(false);
        toggleMThree(true);
        toggleCurrentMonth(2);
        break;
      default:
        break;
    }
  };

  //Cancel and Reschedule functionality
  const [showCancel, setShowCancel] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);

  //toggle cancelBox component rendering
  const toggleCancelBox = () => {
    setShowCancel((showCancel) => !showCancel);
  };

  let currSession = {
    id: "",
    date: "",
  };

  const [currentSession, setCurrentSession] = useState(currSession);
  //Function to open cancel box and pass sessionObj  to cancelbox
  const openCancelBox = (sessionObj) => {
    toggleCancelBox();
    setCurrentSession(sessionObj);
  };

  const submitCancelRequest = (sessionObj) => {
    props.cancelSession(sessionObj);

    toggleCancelBox();
  };

  //RescheduleBox Functions
  const toggleRescheduleBox = () => {
    setShowReschedule((showReschedule) => !showReschedule);
  };
  //function to open reschedule box and pass sessionObj to reschedulebox
  const openRescheduleBox = (sessionObj) => {
    toggleRescheduleBox();
    setCurrentSession(sessionObj);
  };

  //Function to submit a request to reschedule
  const rescheduleRef = useRef();

  // let newReschedule = {
  //     id: "",
  //     descr: "",
  // }

  const submitReschedule = (sessionObj) => {
    toggleRescheduleBox();

    // Parameters are: sessionID (int), date (moment), and descr (String).
    props.addRescheduleRequest({
      id: sessionObj.id,
      date: sessionObj.date,
      descr: rescheduleRef.current.value,
    });
    // props.removeSession(sessionObj.sessionId);
  };

  //Functions to pass to checkinPoint to process request additional sessions

  const [showCheckin, setShowCheckin] = useState(false);

  const toggleCheckin = () => {
    setShowCheckin((showCheckin) => !showCheckin);
  };
  const openCheckInBigBox = (sessionObj) => {
    toggleCheckin();
    setCurrentSession(sessionObj);
  };

  const submitSessionsRequest = (requestedValue) => {
    // toggleCheckin();
    props.addSessionsRequest({
      dateRequested: moment(),
      request: requestedValue,
    });
  };
  const renderMonth = (currentMonth) => {
    return (
      <>
        {currentMonth.map((session, key) => (
          <>
            {" "}
            {session.sessionId !== "Checkin" ? (
              <>
                <AdjustableSpacer height="16px" />
                <Session
                  beforeTwentyFourHours={true}
                  sessionObj={session}
                  openRescheduleBox={() => openRescheduleBox(session)}
                  openCancelBox={() => openCancelBox(session)}
                />
              </>
            ) : (
              <>
                <AdjustableSpacer height="16px" />
                <CheckInPoint
                  sessionObj={session}
                  stage={props.sessionsRequested ? 3 : 0}
                  openCheckInBigBox={() => openCheckInBigBox(session)}
                ></CheckInPoint>
              </>
            )}
          </>
        ))}
      </>
    );
  };

  return (
    <SessionListBox>
      <Title>
        Upcoming sessions
        {/* ({props.numSessions} scheduled) */}
      </Title>
      <AdjustableSpacer height="16px" />
      <MonthStrip>
        <MonthButton
          text={monthTextList[0]}
          active={mOneActive}
          buttonHandler={() => {
            handleChangeMonth(0);
            setShowReschedule(false);
            setShowCancel(false);
            setShowCheckin(false);
          }}
        />
        {monthTextList[1] !== "" && (
          <>
            <AdjustableSpacer width="3px" />
            <MonthButton
              text={monthTextList[1]}
              active={mTwoActive}
              buttonHandler={() => {
                handleChangeMonth(1);
                setShowReschedule(false);
                setShowCancel(false);
                setShowCheckin(false);
              }}
            />
          </>
        )}
        {monthTextList[2] !== "" && (
          <>
            <AdjustableSpacer width="3px" />
            <MonthButton
              text={monthTextList[2]}
              active={mThreeActive}
              buttonHandler={() => {
                handleChangeMonth(2);
                setShowReschedule(false);
                setShowCancel(false);
                setShowCheckin(false);
              }}
            />
          </>
        )}
        {/* {console.log(Sessions)} */}
      </MonthStrip>

      <>
        {" "}
        {showCancel ? (
          <>
            <AdjustableSpacer height="16px" />
            <CancelBox
              sessionObj={currentSession}
              handleBack={toggleCancelBox}
              submitCancelRequest={submitCancelRequest}
              currDate={currDate}
            />
          </>
        ) : showReschedule ? (
          <>
            <AdjustableSpacer height="16px" />
            <RescheduleBox
              sessionObj={currentSession}
              handleBack={toggleRescheduleBox}
              submitReschedule={() => submitReschedule(currentSession)}
              rescheduleRef={rescheduleRef}
              currDate={currDate}
            />
          </>
        ) : showCheckin ? (
          <>
            <AdjustableSpacer height="16px" />
            <CheckInPoint
              sessionObj={currentSession}
              toggleCheckin={toggleCheckin}
              submitSessionsRequest={(val) => submitSessionsRequest(val)}
              stage={props.sessionsAlreadyRequested ? 3 : 1}
            ></CheckInPoint>
          </>
        ) : currentMonth === 0 ? (
          renderMonth(firstMonthList)
        ) : currentMonth === 1 ? (
          renderMonth(secondMonthList)
        ) : (
          renderMonth(thirdMonthList)
        )}
      </>
    </SessionListBox>
  );
};

export default SessionList;
