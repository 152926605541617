import styled from "styled-components";

export const ChipInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledBox = styled.div`
  /* Gray / Background */
  background: #f6f6f6;

  /* Stroke / White Element */
  border: 0.5px solid rgba(202, 208, 223, 0.25);
  box-sizing: border-box;

  /* Desktop / Down - Input Field */
  box-shadow: inset -2.5px -2.5px 6px 1px #ffffff,
    inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
  border-radius: 5px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  // margin: 10px 0px;

  outline: none !important;
  border: 1px solid ${(props) => (!props.validated ? "#E73144" : "none")};
`;

export const LabelText = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */
  color: #333333;
  mix-blend-mode: normal;
  margin-bottom: 10px;
`;
