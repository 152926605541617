import React, { useState, useEffect } from "react";
import { fetchToMarvinAuth } from "../../utils/Auth/marvinAuth";
import {
  Background,
  TopImageContainer,
  TopImage,
  BottomImageContainer,
  BottomImage,
  LoginBox,
  JoinLabel,
  InputContainer,
  LoginBtn,
  LoginBtnText,
  DescriptionText,
} from "./styles";

import { useHistory } from "react-router-dom";

import AdjustableSpacer from "../../components/AdjustableSpacer";
import PasswordInput from "../../components/PasswordInput";
import InputBoxWithLabel from "../../components/InputBoxWithLabel";
import { useStore } from "../../utils/Zustand/store";
import PwdResetCard from "../../components/PwdResetCard";

const PwdResetTest = (props) => {

  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const history = useHistory();
 
  const savePwd =  async () => {
  //   let body = {
  //     login: email,
  //     currentPassword: currentPassword,
  //     newPassword: newPassword
  //   }
  //   let params = {
  //     method: "POST",
  //     body: body,
  //     route: "reset"
  //   }
  //  try {
  //   let status =  await fetchToMarvinAuth(params);
  //    console.log(status);
  //  } catch (error) {
  //    console.log(error);
  //  }

  };


  const [pwdChangesMade, setPwdChangesMade] = useState(false);

  var validPwd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/;

  const [emailValidated, validateE] = useState(true);
  const [newPasswordValidated, validateNP] = useState(true);
  const [passwordConfValidated, validatePC] = useState(true);


  const onPasswordChange = () => {

  };

  

  const handleChange = () => {

  }


  return (
    <Background>
      <TopImageContainer>
        <TopImage src="/images/pages/Login/TopLeft SignIn.svg" alt="TopImage" />
      </TopImageContainer>
      <PwdResetCard/>
      {/* <LoginBox>
        <AdjustableSpacer height="60px" />
        <JoinLabel>Password Reset</JoinLabel>
        <AdjustableSpacer height="10px" />
        <DescriptionText>
          {" "}
          Your password should have a minimum 8 characters – at least 1 upper
          case, 1 lower case, and 1 numerical character.
        </DescriptionText>
        <AdjustableSpacer height="10px" />
        <InputContainer>
          <InputBoxWithLabel 
                inputLabel = "Email address"
                name = "email"
                value = {email}
                onChange = {setEmail}
                validated = {emailValidated}
          />
        </InputContainer>

        <AdjustableSpacer height="10px" />
        <InputContainer>
          <PasswordInput
            inputLabel="New Password"
            name = "newPassword"
            value = {newPassword}
            onChange={onPasswordChange}
            validated = {newPasswordValidated}
          />
        </InputContainer>

        <AdjustableSpacer height="10px" />
        <InputContainer>
          <PasswordInput
            value = {passwordConfirmation}
            name = "passwordConfirmation"
            onChange={onPasswordChange}
            inputLabel="Confirm Password"
            validated = {passwordConfValidated}
          />
        </InputContainer>
        <AdjustableSpacer height="10px" />
        <LoginBtn
          onClick={savePwd}
          disabled={!pwdChangesMade}
          changesMade={pwdChangesMade}
        >
          <LoginBtnText>Save password</LoginBtnText>
        </LoginBtn>
        <AdjustableSpacer height="30px" />
      </LoginBox> */}
      <BottomImageContainer>
        <BottomImage
          src="/images/pages/Login/BottomRight SignIn.svg"
          alt="BottomImage"
        />
      </BottomImageContainer>
    </Background>
  );
};

export default PwdResetTest;
