import styled from "styled-components";

export const OuterVideoBox = styled.div`
  width: 100%;
  height: 100%;
  /* Gray / 1 */
  background: #333333;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

//************************User Video**************************
export const UserVideo = styled.video`
  display: ${(props) => (props.displayVid ? "initial" : "none")};
  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 6px;
  object-fit: cover;
  transform: ${(props) =>
    props.desktop ? "rotateY(0deg)" : "rotateY(180deg)" };
`;


export const BG = styled.div`
  background-color: #404040; // color not product approved (#404040)
  height: 100%;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const InitialCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Syne;
  color: #ffffff;

  font-size: 150px;
  background: linear-gradient(to top left, #52b1fc, #4e73fe);
  border-radius: 50%;
  width: 250px;
  height: 250px;
`;
