import styled from "styled-components";

export const Background = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Gray / Background */
  background: #f6f6f6;
  @media screen and (max-width: 769px){
      width: 100%;
      height: 100vh;
      background-image: url("/images/components/Dashboard/DashboardBG.svg");
      background-size: cover;
     
      background-repeat: no-repeat;

    }
`;

export const TopImageContainer = styled.div`
  width: 40%;
  height: 100%;
  @media screen and (max-width: 769px){
    display: none;
  }

`;

export const TopImage = styled.img`
  position: absolute;
  left: 0%;
  top: 0%;
  max-width: 50%;

`;
export const BottomImageContainer = styled.div`
  width: 40%;
  height: 100%;
  @media screen and (max-width: 769px){
    display: none;
  }
`;

export const BottomImage = styled.img`
  position: absolute;
  right: 0%;
  bottom: 0%;
  max-width: 50%;
  
`;

export const LoginBox = styled.div`
  /* BG */
  width: 540px;
  height: 474px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White/Up - Height >111 */

  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  @media screen and (max-width: 769px){
    display: none;
  }
`;

export const JoinLabel = styled.div`
  /* Join your Marvin Session */

  /* Desktop/Header/3 */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  text-align: center;

  /* Gray / 1 */

  color: #333333;
`;

export const InputContainer = styled.div`
  width: 70%;
  position: relative;
`;

export const LoginBtnText = styled.span`
  /* Button Text */

  position: static;
  width: 34px;
  height: 17px;
  left: calc(50% - 34px / 2);
  top: calc(50% - 17px / 2);

  /* Desktop / Button / Secondary */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  text-transform: capitalize;

  color: #fefefe;

  mix-blend-mode: normal;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 10px 0px;
`;

export const LoginBtn = styled.button`
  /* Primary Button Small /Default */

  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  width: 212px;
  height: 39px;

  /* Buttons/Primary */

  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;
  /* Buttons/Primary Small /Default */

  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;

  border: none;

  &:hover {
    cursor: pointer;
  }
`;

export const OrLabel = styled.div`
  /* or */
  width: 12px;

  /* Desktop/Body/Label */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;
`;

export const GuestJoinBtnText = styled.span`
  /* Button Text */
  position: static;
  // width: 142px;
  height: 17px;
  left: calc(50% - 142px / 2);
  top: calc(50% - 17px / 2);

  /* Mobile/Button/2 */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.01em;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
`;

export const GuestJoinBtn = styled.button`
  /* Secondary Button /Default */
  /* Auto Layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 30px;

  width: 212px;
  height: 39px;

  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Buttons/Secondary Large /Default */

  box-shadow: -4px -4px 16px #ffffff, 6px 6px 12px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 75px;

  border: none;
  &:hover {
    cursor: pointer;
  }
`;

export const ResetPwdText = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #000000;
`;
export const PwdResetLink = styled.a`
  /* Mobile/Input Field */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* or 14px */
  /* letter-spacing: 0.25px; */

  /* color: #000000; */
  color: inherit;
`;
export const ErrorBox = styled.div`   
   background: #F6F6F6;
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* Gray / 1 */
    border: 1px solid #333333;
    box-sizing: border-box;
    box-shadow: -7px -7px 14px #FFFFFF, 5px 5px 25px rgba(202, 208, 223, 0.85);
    border-radius: 8px;
    width: 123px;
    height: 40px;

    //text Inside
    font-family: Syne;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    text-align: center;

    /* Gray / 1 */
     color: #333333;
    position: absolute;
    top: 25px;
    right: -150px;
`;

export const PwdErrorText = styled.div`
    /* Desktop / Body / Input Field */
    font-family: Raleway;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;

    /* Gray / 3 */
    color:#E73144;
    /* border:1px solid red;
    width: 100px;
    height: 10px; */
`;

export const MobileBox = styled.div`

  display: none;
  @media screen and (max-width: 769px){
      display: flex;
      flex-direction: column;
      padding: 5%;
      /* border: 1px solid red; */
      width: 375px;
      height: 674px;

    }
  
`;


export const MobileTitle = styled.div`
  /* Mobile/Header/1 */
  font-family: 'Syne';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 5%;
  margin-top: 3%;
  /* Gray/1 */

  color: #333333;
`;

export const MobileMarvin = styled.img`
  width: 315px;
  height: 315px;
  border-radius: 16px;
  margin-bottom: 5%;
  @media screen and (max-width: 360px){
    width: 280px;
    height: 280px;
  }
`;

export const MobileDescription = styled.div`
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;

  /* Gray/1 */

  color: #333333;
  margin-bottom: 5%;
`;

export const MobileButton = styled.div` 
  width: 327px;
  height: 49px;

  background: linear-gradient(19.39deg, #6ACCDC 5.07%, #6ADCB3 117.43%);
  /* Up / Primary Button */

  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 208, 223, 0.7);
  border-radius: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
  } 

`;

export const MobileButtonText = styled.div`
  /* Mobile/Button/1 */

  font-family: 'Syne';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #FEFEFE;

  mix-blend-mode: normal
`;

