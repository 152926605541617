import React, { useState } from "react";
import TextAreaWithLabel from "../TextAreaWithLabel";
import AdjustableSpacer from "../AdjustableSpacer";
import {
  OuterBox,
  Body,
  Looking,
  WouldYouLike,
  RequestBtn,
  RecievedRequest,
  Stage2Body,
} from "./styles";

import { useRef } from "react";

const RequestNewTherapist = (props) => {
  const requestNewTherapistRef = useRef();
  const [stage, setStage] = useState(props.newTherapistRequested ? 3 : 1);

  // New therapist already requested, show that the request was received.
  // if(props.newTherapistRequested === true){
  //     setStage(3);
  // }

  const sendRequest = () => {
    props.sendRequestMessage(requestNewTherapistRef.current.value);
    setStage(3);
    console.log("REQUEST VALUE: requestNewTherapistRef.current.value)");
  };
  return (
    <OuterBox stage={stage}>
      <Body>
        <Looking> Looking for a different kind of support? </Looking>
        <AdjustableSpacer height="8px" />
        {/* <WouldYouLike> Would you like to request to work with a different Marvin therapist? </WouldYouLike>
                <AdjustableSpacer height={ stage === 1 ? "15px;" : "10px;"} /> */}
        {stage === 1 ? (
          <>
            <WouldYouLike>
              Would you like to request to work with a different Marvin
              therapist?
            </WouldYouLike>
            <AdjustableSpacer height={stage === 1 ? "15px;" : "10px;"} />
            <RequestBtn onClick={() => setStage(2)}>
              Request to change therapist
            </RequestBtn>
          </>
        ) : stage === 2 ? (
          <Stage2Body>
            <WouldYouLike>
              Would you like to request to work with a different Marvin
              therapist?
            </WouldYouLike>
            <AdjustableSpacer height={stage === 1 ? "15px;" : "10px;"} />
            <TextAreaWithLabel
              inputLabel="Tell us what you want in a new therapist."
              height="100px;"
              ref={requestNewTherapistRef}
            />
            <AdjustableSpacer height="28px;" />
            <RequestBtn onClick={sendRequest}>
              Submit Request
            </RequestBtn>
          </Stage2Body>
        ) : (
          <RecievedRequest>
            We've received your request and are working on finding you a
            different therapist.
          </RecievedRequest>
        )}
      </Body>
    </OuterBox>
  );
};

export default RequestNewTherapist;
