import React, { useState, useRef } from "react";
import {fetchToMarvinAuth} from '../../utils/Auth/marvinAuth';
import {
  FlexContainer,
  FlexContainerTwo,
  ICard,
  TabContainer,
  Text,
  TextActive,
  ProfileInactiveButton,
  ProfileButtonActive,
  AccountInactiveButton,
  AccountButtonActive,
} from "./styles";

import SettingsCard from "../../components/SettingsCard";
import PassswordChangeForm from "../../components/PasswordChangeForm";
import Deactivate from "../../components/Deactivate";
import PageWrapper from "../PageWrapper";
import { useStore } from "../../utils/Zustand/store";
import AdjustableSpacer from "../../components/AdjustableSpacer";
import ContactSettings from "../../components/ContactSettings";

import { UpdateDBProfile } from "../../utils/UserAppApi/profileOperations";

const Settings = () => {
  const tab = "settings";
  const susie = useStore((state) => state.profile);
  const updateProfile = useStore((state) => state.updateProfile);

  const [isProfileActive, SetProfileActive] = useState(true);
  const [isAccountActive, SetAccountActive] = useState(false);

  //refs and state for password change form
  const currPwdRef = useRef(susie.pwd);
  const newPwdRef = useRef();
  const confNewPwdRef = useRef();

  const [pwdStage, setPwdStage] = useState(0);
  const [pwdChangesMade, setPwdChangesMade] = useState(false);
  const profileId = useStore((state) => state.profileId);

  //regex for validating a correct password
  // to https://pages.nist.gov/800-63-3/sp800-63b.html#memsecret

  var validPwd = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$/;

  const onPasswordChange = () => {
    if (
      newPwdRef.current.value === confNewPwdRef.current.value &&
      validPwd.test(newPwdRef.current.value)
    ) {
      setPwdChangesMade(true);
    } else {
      setPwdChangesMade(false);
    }
  };

  //Lets go Ahead 
  const saveNewPwd = () => {
    let pwd = newPwdRef.current.value;
    let newProfile = { ...susie, pwd };
    updateProfile(newProfile);
    setPwdStage(0);
    setPwdChangesMade(false);
    
    
    try {
      let params = {
        route: "reset",
        method: "POST",
        body: {
          login: susie.email,
          currentPassword: currPwdRef.current.value,
          newPassword: newPwdRef.current.value
        }
      }
      
      const resetStatus = fetchToMarvinAuth(params);

      console.log(resetStatus);
    } catch (error) {
      console.log(error)
    }
    
    currPwdRef.current.value = newPwdRef.current.value;
  };

  console.log(`susie email and phone number`, susie.email, susie.phoneNumber);


  //state variables
  const [contactChanged, setContactChanged] = useState(false);

  const [emailValidated, validateEmail] = useState(true);
  const [pnValidated, validatePN] = useState(true);
  const [isValidated, setIsValidated] = useState(true);

  //validate email and phone  handlers
  const validatePhoneNumberHandler = (phoneNumber) => {
    let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(phoneNumber);
  };

  const validateEmailHandler = (email) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const [contactInfo, setContactInfo] = useState({
    email: susie.email,
    phoneNumber: susie.phoneNumber,
  });

  const handleChange = (event) => {
    setContactChanged(true);
    const { name, value } = event.target;
    setContactInfo((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onContactSave = () => {
    if (!validateEmailHandler(contactInfo.email)) validateEmail(false);
    else validateEmail(true);
    if (!validatePhoneNumberHandler(contactInfo.phoneNumber)) validatePN(false);
    else validatePN(true);

    if (
      !validateEmailHandler(contactInfo.email) ||
      !validatePhoneNumberHandler(contactInfo.phoneNumber)
    ) {
      setIsValidated(false);
      setContactChanged(false);
    }

    console.log("Here's what's being sent to updateProfile: ", {
      ...susie,
      ...contactInfo,
    });
    UpdateDBProfile({ ...susie, ...contactInfo }, profileId);
    updateProfile({ ...susie, ...contactInfo });
    console.log(susie);
  };

  // When settingscard is changed and presses save
  const handleProfileSave = async (profile) => {
    console.log(profile);
    updateProfile(profile);
    const stringifiedRace = JSON.stringify(profile.race);
    console.log(
      "Being pushed to UpdateDB: ",
      { ...profile, race: stringifiedRace },
      profileId
    );
    UpdateDBProfile({ ...profile, race: stringifiedRace }, profileId);
  };

  return (
 
         <PageWrapper tab = {tab}>
           <FlexContainerTwo>

            <TabContainer>
              {isProfileActive ? (
                <ProfileButtonActive>
                  <TextActive>Profile</TextActive>
                </ProfileButtonActive>
              ) : (
                <ProfileInactiveButton
                  onClick={() => {
                    SetProfileActive(true);
                    SetAccountActive(false);
                  }}
                >
                  <Text>Profile</Text>
                </ProfileInactiveButton>
              )}
              <AdjustableSpacer width="10px" />
              {isAccountActive ? (
                <AccountButtonActive>
                  <TextActive>Account</TextActive>
                </AccountButtonActive>
              ) : (
                <AccountInactiveButton
                  onClick={() => {
                    SetProfileActive(false);
                    SetAccountActive(true);
                  }}
                >
                  <Text>Account</Text>
                </AccountInactiveButton>
              )}
            </TabContainer>
          <FlexContainer>
            {isProfileActive ? (
              <ICard>
                <SettingsCard
                  handleChangesMade={(profile) => {
                    handleProfileSave(profile);
                  }}
                  {...susie}
                />
              </ICard>
            ) : (
              <>
                <ICard>
                  <ContactSettings
                    stage={0}
                    contactChanged={contactChanged}
                    handleChange={(e) => handleChange(e)}
                    onContactSave={onContactSave}
                    isValidated={isValidated}
                    emailValidated={emailValidated}
                    pnValidated={pnValidated}
                    validateEmail={validateEmail}
                    validatePN={validatePN}
                    phoneNumber={contactInfo.phoneNumber}
                    email={contactInfo.email}
                  ></ContactSettings>
                  <AdjustableSpacer height="40px" />
                  <Deactivate stage={0}></Deactivate>
                </ICard>
                <ICard>
                  <PassswordChangeForm
                    setPwdStage={setPwdStage}
                    currPwdRef={currPwdRef}
                    newPwdRef={newPwdRef}
                    confNewPwdRef={confNewPwdRef}
                    onPasswordChange={onPasswordChange}
                    saveNewPwd={saveNewPwd}
                    pwdStage={pwdStage}
                    pwdChangesMade={pwdChangesMade}
                    // isPwdValid = {isPwdValid}
                    currPwd={susie.pwd}
                  />
                </ICard>
              </>
            )}
          </FlexContainer>
           </FlexContainerTwo>
        </PageWrapper>
  );
};


export default Settings;
