import React from 'react'


import PeerAudio from "../PeerAudio";


const FullScreenAudio = (props) => {
  console.log(`fullScreenAudio`, props.filmStripList);
  return (
      <>
     
        {props.filmStripList.map((val, index) => {
          {console.log(`peerAudio val, ${val}`)}
          {console.log(val)}
          return (

            <PeerAudio  audioStream = {val.audioStream}
                        micOn = {val.micOn}
                        key = {index}/>
          )
        })}
    </>
  )
}

export default FullScreenAudio;