import {
  InputField,
  InputBox,
  InputLabel,
  ShowHidePwdIcon,
  PasswordInputContainer
} from "./styles";
import React, { useState } from "react";

const PasswordInput = React.forwardRef((props, ref) => {

  let validated = false;

  if (props.validated === undefined) validated = true;
  if (props.validated === true || props.validated === false) {
    validated = props.validated;
  } else {
    validated = validated || false;
  }

  //new stuff for toggle showpassword icon

  const [pwdShown, setPwdShown] = useState(false);

  const [iconPath, setIconPath] = useState(
    "/images/pages/Settings/hidePassword.svg"
  );

  const handleShowPassword = (e) => {


    if (pwdShown) {
      setIconPath("/images/pages/Settings/hidePassword.svg");
    } else setIconPath("/images/pages/Settings/showPassword.svg");
    //flip the icon

    //flip the type of input
    setPwdShown(!pwdShown);
  };
  // onMouseDown = {(e) => e.preventDefault()} 
  return (
    <InputBox>
      <InputLabel>{props.inputLabel}</InputLabel>
      <PasswordInputContainer>
        <InputField
          readOnly={props.readOnly}
          height={props.height}
          type={pwdShown ? "text" : "password"}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          onClick={props.onClick}  
          validated={validated}
          ref={ref}
          disabled={props.disabled ? props.disabled : false}
        >     
        </InputField>
        <ShowHidePwdIcon iconPath = {iconPath}    onClick={handleShowPassword}/>
        </PasswordInputContainer>
    </InputBox>
  );
});
export default PasswordInput;
