import React, { useState } from "react";
import AdjustableSpacer from "../AdjustableSpacer";
import PlayVideo from "../PlayVideo";
import {
  OuterBox,
  Body,
  Videos,
  VideoCard,
  Heading,
  ColorfulCircle,
  CircleContainer,
  CardText,
} from "./styles";

const IntroductoryVideos = (props) => {
  const [vid, setVid] = useState();
  const [modalShowing, setModalShowing] = useState(false);

  const [circle1, setCircle1] = useState(
    "/images/components/IntroductoryVideos/colorfulCircle1.png"
  );
  const [circle2, setCircle2] = useState(
    "/images/components/IntroductoryVideos/colorfulCircle2.png"
  );
  const [circle3, setCircle3] = useState(
    "/images/components/IntroductoryVideos/colorfulCircle3.png"
  );

  const handleMouseEnter = (vidNum) => {
    switch (vidNum) {
      case 1:
        setCircle1("/images/components/IntroductoryVideos/colorfulCircle4.png");
        break;
      case 2:
        setCircle2("/images/components/IntroductoryVideos/colorfulCircle5.png");
        break;
      case 3:
        setCircle3("/images/components/IntroductoryVideos/colorfulCircle6.png");
        break;
      default: 
        break;
    }
  };

  const handleMouseLeave = (vidNum) => {
    switch (vidNum) {
      case 1:
        setCircle1("/images/components/IntroductoryVideos/colorfulCircle1.png");
        break;
      case 2:
        setCircle2("/images/components/IntroductoryVideos/colorfulCircle2.png");
        break;
      case 3:
        setCircle3("/images/components/IntroductoryVideos/colorfulCircle3.png");
        break;
      default: 
        break;
    }
  };

  const handlePlay = (vidNum) => {
    setModalShowing(true);
    switch (vidNum) {
      case 1:
        setVid(
          props.aboutMeURL ? props.aboutMeURL : props.introVideoArray[0][1]
        );
        break;
      case 2:
        setVid(
          props.myInterestsURL
            ? props.myInterestsURL
            : props.introVideoArray[1][1]
        );
        break;
      case 3:
        setVid(
          props.myFunFactURL ? props.myFunFactURL : props.introVideoArray[2][1]
        );
        break;
      default: 
        break;
    }
  };
  const handleStopVideo = () => {
    setModalShowing(false);
  };
  return (
    <OuterBox>
      <PlayVideo
        showVideo={modalShowing}
        hideVideo={handleStopVideo}
        url={vid}
        controls={true}
      />
      <Body>
        <Heading> Introductory videos </Heading>
        <AdjustableSpacer height="16px;" />

        <Videos>
          <VideoCard
            onClick={() => {
              handlePlay(1);
            }}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={() => handleMouseLeave(1)}
          >
            <AdjustableSpacer height="16px" />
            <CircleContainer>
              <ColorfulCircle src={circle1} />
            </CircleContainer>
            <AdjustableSpacer height="12px" />
            <CardText> About me </CardText>
          </VideoCard>

          <VideoCard
            onClick={() => {
              handlePlay(2);
            }}
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={() => handleMouseLeave(2)}
          >
            <AdjustableSpacer height="16px" />
            <CircleContainer>
              <ColorfulCircle src={circle2} />
            </CircleContainer>
            <AdjustableSpacer height="12px" />
            <CardText> My interests </CardText>
          </VideoCard>

          <VideoCard
            onClick={() => {
              handlePlay(3);
            }}
            onMouseEnter={() => handleMouseEnter(3)}
            onMouseLeave={() => handleMouseLeave(3)}
          >
            <AdjustableSpacer height="16px" />
            <CircleContainer>
              <ColorfulCircle src={circle3} />
            </CircleContainer>
            <AdjustableSpacer height="12px" />
            <CardText> My fun fact </CardText>
          </VideoCard>
        </Videos>

        {/* Old thumbnail style */}
        {/* <Videos> 
                    <Thumbnail 
                        thumbnail={props.introVideoArray[0][0] ? props.introVideoArray[0][0] : "/images/components/IntroductoryVideos/Intro1.png"}>
                            <PlayButton
                            onClick={() => {handlePlay(1)}} /></Thumbnail>

                    <Thumbnail 
                        thumbnail={props.introVideoArray[1][0] ? props.introVideoArray[1][0] : "/images/components/IntroductoryVideos/Intro2.png"}> 
                            <PlayButton
                            onClick={() => {handlePlay(2)}} /></Thumbnail>

                    <Thumbnail 
                        thumbnail={props.introVideoArray[2][0] ? props.introVideoArray[2][0] : "/images/components/IntroductoryVideos/Intro3.png"}> 
                            <PlayButton
                            onClick={() => {handlePlay(3)}} /></Thumbnail>
                </Videos> */}
      </Body>
    </OuterBox>
  );
};

export default IntroductoryVideos;
