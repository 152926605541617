import styled from "styled-components";

export const FirstOuterBox = styled.div`
  position: absolute;
  right: 0px;
  top: 50px;
  width: 198px;
  height: 92px;
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-shadow: -7px -7px 14px rgba(255, 255, 255, 0.1),
    5px 5px 25px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FirstContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const EmergencyOrContactText = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 18px;

  color: #a2aecb;
  opacity: 0.9;

  &:hover {
    color: #333333;
    cursor: pointer;
  }
`;

export const ContactMarvinOuterBox = styled.div`
  position: absolute;
  right: 0px;
  top: 50px;
  width: 376px;
  height: 475px;
  background: #f6f6f6;
  box-shadow: -7px -7px 14px rgba(202, 208, 223, 0.4),
    7px 7px 14px rgba(202, 208, 223, 0.6),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;

  display: flex;
  justify-content: center;
`;

export const ContactMarvinContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 87.23%;
`;
export const ContactMarvinText = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #161616;
`;

export const SendMessageBtn = styled.button`
  width: 160px;
  height: 39px;
  background-blend-mode: soft-light, normal;
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  border: none;

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #fefefe;

  &:hover {
    cursor: pointer;
  }
`;

export const EmergencySupportOuterBox = styled.div`
  position: absolute;
  right: 0px;
  top: 50px;
  width: 376px;
  height: 262px;
  background: #f6f6f6;
  box-shadow: -7px -7px 14px rgba(202, 208, 223, 0.4),
    7px 7px 14px rgba(202, 208, 223, 0.6),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;

  display: flex;
  justify-content: center;
`;

export const EmergencySupportContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 87.23%;
`;

export const EmergencySupportText = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  color: #161616;
`;

export const EmergencyQuestion = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  color: #333333;

  align-self: flex-start;
`;

export const EmergencyButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 276px;
  height: 40px;

  background: linear-gradient(218.09deg, #fa9b94 -20.54%, #e15160 113.32%);
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(239, 125, 125, 0.7);
  border-radius: 75px;
  border: none;

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #fefefe;

  &:hover {
    cursor: pointer;
    background: linear-gradient(
      191.37deg,
      #fa9b94 -31.78%,
      #e25563 47.49%,
      #e15160 101.05%
    );
    box-shadow: 0px 3px 10px rgba(213, 80, 94, 0.33), -5px -5px 10px #ffffff,
      -3px -3px 12px #f5f5f5;
  }
`;

export const EmergencyBottomText = styled.div`
  /* Body/ Link */

  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.51px;
  text-decoration-line: underline;

  color: rgba(0, 0, 0, 0.8);
  opacity: 0.9;

  width: 297px;

  &:hover {
    cursor: pointer;
  }
`;

export const PhoneIcon = styled.div`
  background-image: url("/images/components/SafetyMenu/Phone.svg");
  height: 18px;
  width: 19px;
`;

export const MessageIcon = styled.div`
  background-image: url("/images/components/SafetyMenu/Message.svg");
  height: 18px;
  width: 19px;
`;

export const IconContent = styled.div`
  border: solid black 1px;
`;
