import yourTherapist from "./people/yourTherapist";
export const msgArray = [
  ["Me", "Lorem ipsum dolor", "12:14 PM"],
  [
    yourTherapist.firstName,
    "Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. ",
    "2:58 PM",
  ],
  ["Me", "", "3:30 PM"],
  [yourTherapist.firstName, "", "4:00 PM"],
  [
    "Me",
    "Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor Lorem ipsum dolor",
    "12:14 AM",
  ],
  [
    yourTherapist.firstName,
    "Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. ",
    "2:58 AM",
  ],
];

export default msgArray;
