import styled from "styled-components";

export const DashBoardContainer = styled.div`
  position: relative;
  height: ${props => props.isGrowPage ? "100%" : "100vh"};
  display: flex;
  background: url("/images/components/Dashboard/DashboardBG.svg") no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media screen and (max-width: 769px){
    flex-direction: column ;
    align-items: center;

  }
`;

export const FlexContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  @media screen and (max-width: 769px){
    display: none;
  }
`;

export const ICard = styled.div`
  width: 50%;
  margin: 30px;
`;
