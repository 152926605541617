import {
  LabelText,
  LabelAndInputContainer,
  SelectBox,
  Option,
} from "./styles.js";
import React from "react";
// import
//     { useState } from `react`;

const SelectBoxWithLabel = React.forwardRef((props, ref) => {
  // const [redBorder, setRedBorder] = useState(true);

  // const validatedOnClick = () => {
  //     setRedBorder(false);
  // };

  let validated = false;

  if (props.validated === undefined) validated = true;
  if (props.validated === true || props.validated === false) {
    validated = props.validated;
  } else {
    validated = validated || false;
  }

  return (
    <LabelAndInputContainer>
      <LabelText>{props.inputLabel}</LabelText>
      <SelectBox
        value={props.value}
        name={props.name}
        onClick={props.onClick}
        onChange={props.onChange}
        // validated={(props.validated && redBorder) ? props.validated : true}
        validated={validated}
        ref={ref}
        disabled={props.disabled ? props.disabled : false}
      >
        <Option value="" selected disabled hidden></Option>
        {props.valueArray.map((val, index) => (
          <Option key={index} value={val}>
            {val}
          </Option>
        ))}
      </SelectBox>
    </LabelAndInputContainer>
  );
});

SelectBoxWithLabel.defaultProps = {
  valueArray: [],
};

export default SelectBoxWithLabel;
