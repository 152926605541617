import AdjustableSpacer from "../AdjustableSpacer";
import TextAreaWithLabel from "../TextAreaWithLabel";
import {
  MarginBox,
  Content,
  DetailsRow,
  DayCircle,
  Day,
  Details,
  Date,
  Time,
  X,
  BackButton,
  Warning,
  SubmitButton,
  SubmitLabel,
  DateTimeBox,
  WidthContainer,
} from "./styles";

import moment from "moment";

//Text Area With Label
/*  props:  
            inputLabel
            height
            type
            placeholder
*/

const RescheduleBox = (props) => {
  const day = moment(props.sessionObj.sessionDate).format("D");
  const month = moment(props.sessionObj.sessionDate).format("MMMM");
  const hour = moment(props.sessionObj.sessionDate).format("h");
  const minute = moment(props.sessionObj.sessionDate).format("mm");
  const dayCycle = moment(props.sessionObj.sessionDate).format("A");
  const dayOfWeek = moment(props.sessionObj.sessionDate).format("dddd");

  let timeDiff = moment(props.sessionObj.sessionDate).diff(
    props.currDate,
    "hours"
  );
  console.log("this is the timediff of this session from currdate");
  console.log(timeDiff);

  let beforeTwentyFourHrs = false;
  if (timeDiff < 24) beforeTwentyFourHrs = true;
  else beforeTwentyFourHrs = false;
  return (
    <MarginBox>
      <AdjustableSpacer width="20px" />
      <Content>
        <AdjustableSpacer height="15px" />
        <DetailsRow>
          <DateTimeBox>
            <DayCircle>
              <Day>{day}</Day>
            </DayCircle>
            <AdjustableSpacer width="23px" />
            <Details>
              <Date>
                Reschedule {dayOfWeek}, {month} {day}
              </Date>
              <Time>
                {hour}:{minute} {dayCycle}
              </Time>
            </Details>
          </DateTimeBox>
          {/* <AdjustableSpacer width = "20px"/> */}
          <BackButton>
            <X onClick={props.handleBack} />
          </BackButton>
        </DetailsRow>
        <AdjustableSpacer height="20px" />
        {beforeTwentyFourHrs ? (
          <Warning>
            Please fill out the form below to reschedule your session. Since
            this appointment is less than 24 hours away, a $40 fee will be
            charged to your account to compensate your therapist for their time.
          </Warning>
        ) : (
          <Warning>
            Thanks for letting us know! Please fill out the form below to
            reschedule your session.
          </Warning>
        )}
        <AdjustableSpacer height="10px" />
        <WidthContainer>
          <TextAreaWithLabel
            inputLabel="When would you like to reschedule your session for?"
            placeholder="Please provide 3 different dates/times that work for you."
            ref={props.rescheduleRef}
            height="100px"
          />
        </WidthContainer>
        <AdjustableSpacer height="15px" />
        <SubmitButton
          onClick={() => {
            props.submitReschedule(props.rescheduleRef.current.value);
          }}
        >
          <SubmitLabel> Submit</SubmitLabel>
        </SubmitButton>
        <AdjustableSpacer height="20px" />
      </Content>
      <AdjustableSpacer width="20px" />
    </MarginBox>
  );
};

export default RescheduleBox;
