import {
  ChatBoxContainer,
  CBTitle,
  TypeBox,
  SendBtn,
  Header,
  Body,
  TypeBoxContainer,
  Footer,
  SendIcon,
  AttachBtn,
  AttachIcon,
  ProfilePicture,
} from "./styles.js";
import React from "react";

import AdjustableSpacer from "../AdjustableSpacer";
import ChatMsgRightWithShadow from "../ChatMsgRightWithShadow";
import ChatMsgLeftWithShadow from "../ChatMsgLeftWithShadow";

const ContactTherapistCard = (props) => {
  return (
    <>
      {/* {props.showChatBox ? ( */}
      <ChatBoxContainer>
        <AdjustableSpacer height="15px" />
        <Header>
          <AdjustableSpacer width="20px" />
          <ProfilePicture
            src={
              props.profilePicture
                ? props.profilePicture
                : "images/components/YourTherapistCard/ProfilePicture.svg"
            }
          />
          <AdjustableSpacer width="12px" />
          <CBTitle>
            {props.name ? "Dr. " + props.name : "Dr. Susie Quermeir"}
          </CBTitle>
        </Header>
        <AdjustableSpacer height="15px" />
        <Body>
          {props.msgArray.map((val, index) =>
            val[0] === "Me" ? (
              <ChatMsgRightWithShadow
                msg={val[1]}
                time={val[2]}
                name={val[0]}
              />
            ) : (
              <ChatMsgLeftWithShadow msg={val[1]} time={val[2]} name={val[0]} />
            )
          )}
        </Body>
        <AdjustableSpacer height="20px" />
        <Footer>
          <TypeBoxContainer>
            <TypeBox placeholder="Type a message..."></TypeBox>
            <SendBtn>
              <SendIcon src="images/components/ContactTherapistCard/SendIcon.svg"></SendIcon>
            </SendBtn>
            <AttachBtn>
              <AttachIcon src="images/components/ContactTherapistCard/AttachIcon.svg"></AttachIcon>
            </AttachBtn>
          </TypeBoxContainer>
        </Footer>
        <AdjustableSpacer height="20px" />
      </ChatBoxContainer>
      {/* ) : null} */}
    </>
  );
};

export default ContactTherapistCard;
