
import 'dotenv/config'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useContext, createContext, useState } from "react";

import useStore from "./utils/Zustand/store";

//***********Auth********* */
// import {remoteAppInfo, localAppInfo } from "../src/data/superTokensSettings"
// import useSessionExists from "./utils/Auth/useSessionExists";

import SuperTokens from 'supertokens-auth-react'
import Session from "supertokens-auth-react/recipe/session";
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

// import Passwordless from "supertokens-auth-react/recipe/passwordless";
// import SuperTokens from 'supertokens-website';


//Marvin Components
import LoginApp from "./pages/Login";
import Grow from "./pages/Grow";
import Dashboard from "./pages/Dashboard";
import MeetMT from "./pages/MeetMT";
import MeetCT from "./pages/MeetCT";
import Settings from "./pages/Settings";
import PwdResetTest from "./pages/PwdResetTest";
import Meetings from "./pages/Meetings"


SuperTokens.init({
    appInfo: {
        // learn more about this on https://supertokens.com/docs/session/appinfo
        appName: "meetingapp",
        apiDomain: "https://auth.meetmarvin.com",
        websiteDomain: "https://meetingapp.meetmarvin.com",
        apiBasePath: "/auth",
        websiteBasePath: "/auth"
    },
    recipeList: [
        Session.init()
    ]
});


//Local Version
SuperTokens.init({
  appInfo: {
      // learn more about this on https://supertokens.com/docs/session/appinfo
      appName: "meetingapp",
      apiDomain: "https://auth.meetmarvin.com",
      websiteDomain: "http://localhost:3000",
      apiBasePath: "/auth",
      websiteBasePath: "/"
  },
  recipeList: [
    Session.init()
  ]
});

function App() {

  return (
    <Router>
        <Route path = "/passwordreset">
          <PwdResetTest/>
        </Route>
        {/*<SessionAuth>*/}
          <Route path="/login">
            <LoginApp />
          </Route>
        {/*</SessionAuth>*/}
        {/*<SessionAuth>*/}
          <Route exact path="/">
            <LoginApp />
          </Route>
        {/*</SessionAuth>*/}
        {/*<SessionAuth>*/}
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/meetMT">
              <MeetMT />
            </Route>
            <Route path="/meetCT">
              <MeetCT />
            </Route>
            <Route path="/grow">
              <Grow />
            </Route>
            <Route path="/settings">
              <Settings/>
            </Route>
        {/*</SessionAuth>*/}
        <Route  path="/marvin/:room"   >
          <Meetings></Meetings>
        </Route>
    </Router>
  );



   function PrivateRoute({ children, ...rest }) {


     let { doesSessionExist } = useSessionContext();
    console.log(`ST does Session exist?`, doesSessionExist)
    return (
      <Route
        {...rest}
        render={({ location }) =>
          doesSessionExist ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }
}




export default App;
