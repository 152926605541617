import create from "zustand";
import { persist } from "zustand/middleware";
import moment from "moment";

const defaultProfile = {
  profilePicture:
    "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODkiIGhlaWdodD0iOTAiIHZpZXdCb3g9IjAgMCA4OSA5MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxyZWN0IHdpZHRoPSI4OC42NiIgaGVpZ2h0PSI5MCIgcng9IjQ0LjMzIiBmaWxsPSJ1cmwoI3BhaW50MF9yYWRpYWwpIi8+DQo8cGF0aCBkPSJNMzEuNDY3MyA0MC4yMzg2QzMxLjQ2NzMgNDMuNzAxNSAzMi44MjI0IDQ3LjAyMjYgMzUuMjM0NiA0OS40NzEyQzM3LjY0NjggNTEuOTE5OSA0MC45MTg1IDUzLjI5NTUgNDQuMzI5OCA1My4yOTU1QzQ3Ljc0MTIgNTMuMjk1NSA1MS4wMTI4IDUxLjkxOTkgNTMuNDI1IDQ5LjQ3MTJDNTUuODM3MiA0Ny4wMjI2IDU3LjE5MjMgNDMuNzAxNSA1Ny4xOTIzIDQwLjIzODZDNTcuMTkyMyAzNi43NzU3IDU1LjgzNzIgMzMuNDU0NiA1My40MjUgMzEuMDA1OUM1MS4wMTI4IDI4LjU1NzMgNDcuNzQxMiAyNy4xODE2IDQ0LjMyOTggMjcuMTgxNkM0MC45MTg1IDI3LjE4MTYgMzcuNjQ2OCAyOC41NTczIDM1LjIzNDYgMzEuMDA1OUMzMi44MjI0IDMzLjQ1NDYgMzEuNDY3MyAzNi43NzU3IDMxLjQ2NzMgNDAuMjM4NlY0MC4yMzg2WiIgc3Ryb2tlPSIjRjZGNkY2IiBzdHJva2Utd2lkdGg9IjMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPHBhdGggZD0iTTcwLjgxMTUgNzQuNDU0OUM2Ny4zNDE3IDcwLjA3MzYgNjMuMjE1NyA2Ni41OTY3IDU4LjY3MDkgNjQuMjI0QzU0LjEyNjEgNjEuODUxNCA0OS4yNTIzIDYwLjYyOTkgNDQuMzI5OCA2MC42Mjk5QzM5LjQwNzQgNjAuNjI5OSAzNC41MzM1IDYxLjg1MTQgMjkuOTg4NyA2NC4yMjRDMjUuNDQzOSA2Ni41OTY3IDIxLjMxNzkgNzAuMDczNiAxNy44NDgxIDc0LjQ1NDkiIHN0cm9rZT0iI0Y2RjZGNiIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjxkZWZzPg0KPHJhZGlhbEdyYWRpZW50IGlkPSJwYWludDBfcmFkaWFsIiBjeD0iMCIgY3k9IjAiIHI9IjEiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIiBncmFkaWVudFRyYW5zZm9ybT0idHJhbnNsYXRlKDQ0LjMzIDQ1LjAwMDEpIHJvdGF0ZSgtMTMwLjA4Mykgc2NhbGUoNTEuOTk4MiAzOC43NzA0KSI+DQo8c3RvcCBzdG9wLWNvbG9yPSIjNjE4Q0YxIi8+DQo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM2NTY5RUUiLz4NCjwvcmFkaWFsR3JhZGllbnQ+DQo8L2RlZnM+DQo8L3N2Zz4NCg==",
  firstName: "New",
  lastName: "User",
  genderIdentity: "Cisgender",
  pronouns: "She/Her",
  race: "",
  jobTitle: "No job",
  pwd: "",
  email: "",
  phoneNumber: "",
};

const defaultTherapist = {
  id: "",
  profilePicture: "",
  firstName: "",
  lastName: "",
  pronouns: "",
  bio: "",
  healthcareWorkerExperience: "",
  specialities: "",
  therapyTypes: "",
  languages: "",
  aboutMeURL: "",
  myInterestsURL: "",
  myFunFactURL: "",
  licenseType: "",
  marvinEmail: "",
};

export const useStore = create(
  persist(
    (set) => ({
      // export const useStore = create(set => ({

      // Represents the current's user ID -- Should never be changed after the initial set, but should be used for referencing
      profileId: "153443a1-4909-400c-bcc3-42fd07940dbf",
      // profileId: "",
      setUserId: (Id) => set({ profileId: Id }),

      // meeting firstname (Waiting Room)
      meetingName: "Marvin Friend",
      changeMeetingName: (name) => set({ meetingName: name }),

      //localTracks zustand state
      localMic: true, // Microphone defaults to off

      updateLocalMic: (num) => set({ localMic: num }),

      localCamera: true, // Camera defaults to on

      updateLocalCamera: (num) => set({ localCamera: num }),

      // user profile
      profile: defaultProfile,
      updateProfile: (newProfile) => set({ profile: newProfile }),

      // user therapist
      therapist: defaultTherapist,
      updateTherapist: (newTherapist) => set({ therapist: newTherapist }),

      //Reschedule Store
      rescheduleRequests: [],

      // addReschedule
      addRescheduleRequest: (newReschedule) =>
        set((state) => ({
          rescheduleRequests: [
            { sessionId: newReschedule.sessionId, descr: newReschedule.descr },
            ...state.rescheduleRequests,
          ],
        })),

      //updateReschedules
      updateRescheduleRequests: (newRescheduleRequests) =>
        set({ rescheduleRequests: newRescheduleRequests }),

      //Additional Sessions
      requestsForAdditionalSessions: [],

      // sessionsRequested bool to determine if user requested additional sessions.
      sessionsRequested: false,
      setSessionsRequested: (bool) => set({ sessionsRequested: bool }),

      //addAdditionalSessions
      addSessionsRequest: (newSessionRequest) =>
        set((state) => ({
          requestsForAdditionalSessions: [
            { dateRequested: moment(), request: newSessionRequest },
            ...state.requestsForAdditionalSessions,
          ],
        })),

      //updateAdditionalSessions
      updateRequestsForAdditionalSessions: (newRequests) =>
        set({ requestsForAdditionalSessions: newRequests }),

      //New Therapist Request
      newTherapistRequest: "",

      //addTherapistRequest
      requestNewTherapist: (requestForNewTherapist) =>
        set({ newTherapistRequest: requestForNewTherapist }),

      //therapistRequested bool to determine if user already requested a new therapist
      newTherapistRequested: false,

      //setNewTherapistRequested
      setNewTherapistRequested: (bool) => set({ newTherapistRequested: bool }),

      //Boolean value to determine wheter there is a guest session or not
      isGuestSession: true,
      updateGuestSession: (bool) => set({ isGuestSession: bool }),

      // String value for the conference room
      sessionRoom: "B2NSxYqp9wBEFguyUWKNxUeA",
      updateSessionRoom: (confRoom) => set({ sessionRoom: confRoom }),

      //placeholder list for testing purposes
      sessions: [],

      //replace the sessions with a new list of sessions
      updateSessions: (newSessions) => set({ sessions: newSessions }),

      //add a new session to the state
      addSession: (newSession) =>
        set((state) => ({
          sessions: [
            { sessionId: newSession.SessionId, date: newSession.date },
            ...state.sessions,
          ],
        })),

      //remove a session to the state
      removeSession: (sessionId) =>
        set((state) => ({
          sessions: state.sessions.filter(
            (session) => session.sessionId !== sessionId
          ),
        })),

      // Message to Marvin
      msgToMarvin: "",
      updateMsg: (newMsg) => set({ msgToMarvin: newMsg }),

      firstConsecutiveLogin: 0, // First login of consecutive week streak, used as benchmark for calculating consecutive weeks
      setFirstConsecutiveLogin: (newFirstConsecutiveLogin) =>
        set({ firstConsecutiveLogin: newFirstConsecutiveLogin }),

      consecutiveWeeks: 1, // How many weeks the user has consecutively logged in
      setConsecutiveWeeks: (newConsecutiveWeeks) =>
        set({ consecutiveWeeks: newConsecutiveWeeks }),

      firstLogin: true, // Used to determine if the user just logged in or was logged out for auth
      setFirstLogin: (newFirstLogin) => set({ firstLogin: newFirstLogin }),

      passwordUpdated: false,
      setPasswordUpdated: (newBool) => set({ passwordUpdated: newBool }),

      isBannerOpen: true,
      setIsBannerOpen: (value) => set({ isBannerOpen: value }),
    }),

    {
      name: "UserappStorage",
    }
  )
);

export default useStore;
