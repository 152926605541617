import styled from "styled-components";


export const TimeSpan = styled.span`
  /* 10:36 AM */

  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  color: #ffffff;
  @media screen and (max-width: 768px){
    display: none;
  }
`;