import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const InputRow = styled.div` 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

export const Digit = styled.input`
    box-sizing: border-box;
    width: 55px;
    height: 60px;
    
    /* Gray / Background */
    background: #F6F6F6;
    text-align: center;
    /* Stroke / White Element */
    border: 1px solid ${props => props.validated  ? "#618CF1" : "#E73144"};
    /* White Elements/Input Field */
    box-shadow: inset -2.5px -2.5px 6px 1px #FFFFFF, inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
    border-radius: 8px;
    //pseudoclasses and events
    :focus {
        outline: none;
        border: 1px solid "#618CF1";
        border-radius: 8px; 
    }

    //Digits font
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 31px;
`;