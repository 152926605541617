import styled, { css } from "styled-components";

const ButtonBGMixin = css`
  background: rgba(0, 0, 0, 0.75);
  /* Blur/ Video Session BG */
  backdrop-filter: blur(66px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
`;

export const ButtonBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  height: 90px;
  ${(props) => (props.chatOpen ? ButtonBGMixin : null)};

  &:hover {
    cursor: pointer;
    ${ButtonBGMixin}
  }
`;

export const UnreadMessagesBox = styled.div`
  position: absolute;
  top: 22px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ChatNotification = styled.img`
  position: absolute;

  width: 16px;
  height: 16px;
`;

export const ChatSessTxt = styled.span`
  position: absolute;

  color: #ffffff;
  z-index: 3;
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
`;

export const ButtonControl = styled.img`
  width: 40px;
  height: 40px;

  /* background-image: url(${(props) =>
    props.pic ? props.pic : "images/MuteButton/Default.svg"}); */
  background-size: contain;
  background-repeat: no-repeat;
  &:hover {
    border-radius: 50%;
    background: rgba(250, 250, 250, 0.44);
    border: 50%;
    /* background-image: url(${(props) =>
      props.pic ? props.pic : "images/MuteButton/Default.svg"}); */
  }
`;

export const ButtonLabel = styled.span`
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;

  color: #ffffff;
`;

export const HoverBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  visibility: hidden;
`;
