import React, { useState } from "react";

import AdjustableSpacer from "../AdjustableSpacer";
import {
  OuterBox,
  Body,
  ProfileHeader,
  NamePronounsContact,
  NamePronouns,
  YourTherapist,
  ProfilePicture,
  ContactBtn,
  Heading,
  Paragraph,
  Type,
  Hovering,
  Hovering2,
  TypeContainer,
  PopupBox,
  TypeTitle,
  PopupX,
  PopupContainer
} from "./styles";
import lookupDescription from "../../utils/MiscHelpers/lookupDescription";

const MyTherapist = (props) => {
  let exampleTypes = ["CBT", "CBT-I", "TF-CBT"];
  let types = props.therapyTypes.split(", ");
  return (
    <OuterBox>
      <Body>
        <YourTherapist> Your Therapist </YourTherapist>
        <AdjustableSpacer height="16px;" />
        <ProfileHeader>
          <ProfilePicture
            src={
              props.profilePicture
                ? props.profilePicture
                : "/images/components/Settings/Blank_Profile_Picture.svg"
            }
          />
          <AdjustableSpacer width="29px;" />
          <NamePronounsContact>
            <NamePronouns>
              {props.firstName + " " + props.lastName}
            </NamePronouns>
            <AdjustableSpacer height="7px;" />
            <NamePronouns> {props.pronouns} </NamePronouns>
            <AdjustableSpacer height="8px;" />
            <ContactBtn
            // onClick={props.handleContactButton}
            >
              Contact
            </ContactBtn>
          </NamePronounsContact>
        </ProfileHeader>
        <AdjustableSpacer height="21px;" />
        <Heading> Bio </Heading>
        <Paragraph> {props.bio} </Paragraph>
        <Heading> Experience with healthcare workers</Heading>
        <Paragraph> {props.healthcareWorkerExperience} </Paragraph>
        <Heading> Specialties </Heading>
        <Paragraph> {props.specialties} </Paragraph>
        <Heading> Languages </Heading>
        <Paragraph> {props.languages} </Paragraph>
        <Heading> Therapy types (click to learn more) </Heading>
        <TypeContainer>
          
        {props.modalShown && 
                <>
                <PopupBox> 
                  <PopupContainer>
                  <PopupX onClick={props.closeModal}/>
                  <TypeTitle>
                    {props.currentType}
                  </TypeTitle>
                  <span>
                    {lookupDescription(props.currentType)}
                  </span> 
                  </PopupContainer>
                </PopupBox>
                </>}
          {types.map((type, index) => {
            console.log(type);
            return (
            <Type key={index} onClick={() => props.openModal(type)} clicked={type === props.currentType}> {type} </Type>
            )
        }) 
        }
        </TypeContainer>
      </Body>
    </OuterBox>
  );
};

export default MyTherapist;
