import { TextAreaField, TextAreaBox, TextAreaLabel } from "./styles";
import React from "react";

const TextAreaWithLabel = React.forwardRef((props, ref) => {
  // const [redBorder, setRedBorder] = useState(true);

  // const validatedOnClick = () => {
  //   setRedBorder(false);
  // };

  let validated = false;

  if (props.validated === undefined) validated = true;
  if (props.validated === true || props.validated === false) {
    validated = props.validated;
  } else {
    validated = validated || false;
  }

  return (
    <TextAreaBox>
      <TextAreaLabel>{props.inputLabel}</TextAreaLabel>
      <TextAreaField
        ref={ref}
        height={props.height}
        type={props.type}
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        validated={validated}
        disabled={props.disabled ? props.disabled : false}
      />
    </TextAreaBox>
  );
});
export default TextAreaWithLabel;
