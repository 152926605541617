import {
  ContactBox,
  Title,
  StdBoxContainer,
  SaveChangesBtn,
  NeedHelpText,
  // SaveBtn,
  // SaveBtnTxt
} from "./styles";

import InputBoxWithLabel from "../InputBoxWithLabel";
import AdjustableSpacer from "../AdjustableSpacer";

const ContactSettings = (props) => {
  return (
    <>
      {
        <ContactBox>
          <AdjustableSpacer height="25px" />

          <Title> Contact </Title>

          <AdjustableSpacer height="16px" />

          <StdBoxContainer>
            <InputBoxWithLabel
              disabled={true} // disabled to account for 09/29 consideration that email and password should not be editable by the user
              type="email"
              inputLabel="Email"
              name="email"
              validated={props.emailValidated}
              value={props.email}
              onChange={(e) => props.handleChange(e)}
              onClick={() => props.validateEmail(true)}
            />
          </StdBoxContainer>
          <AdjustableSpacer height="25px" />
          <StdBoxContainer>
            <InputBoxWithLabel
              disabled={true} // disabled to account for 09/29 consideration that email and password should not be editable by the user
              type="tel"
              inputLabel="Phone number"
              name="phoneNumber"
              validated={props.pnValidated}
              value={props.phoneNumber}
              onChange={(e) => props.handleChange(e)}
              onClick={() => props.validatePN(true)}
            />
          </StdBoxContainer>

          <AdjustableSpacer height="20px" />
          <StdBoxContainer>
            <NeedHelpText> If you would like to change any of the above, please contact our team at user-success@meetmarvin.com </NeedHelpText>
          </StdBoxContainer>
          {/* removed to account for 09/29 consideration that email and password should not be editable by the user */}
          {/* <SaveChangesBtn
            contactChanged={props.contactChanged && props.isValidated}
            onClick={() => props.onContactSave()}
            disabled={!props.contactChanged}
          >
            Save
          </SaveChangesBtn> */}
          <AdjustableSpacer height="25px" />
        </ContactBox>
      }
    </>
  );
};

export default ContactSettings;
