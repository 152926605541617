import AdjustableSpacer from "../AdjustableSpacer";
import { useState, useRef } from "react";
import TextAreaWithLabel from "../TextAreaWithLabel";
import {
  CheckinBox,
  CheckinIcon,
  CheckinIconContainer,
  CheckinLabel,
  CheckinButton,
  CheckinButtonText,
  CheckinDate,
  CheckinPointContainer,
  X,
  BackButton,
  CheckInStg1,
  CheckInStg2,
  CheckInStg3,
  CheckinDescr,
  CheckInStg1Button,
  CheckinStgsCheckinBox,
  CheckinStgsButtonText,
  TextAreaContainer,
  CheckInStg2Button,
  ReceivedBox,
  ReceivedText,
  CheckInDateWrapper,
} from "./styles";

const CheckInPoint = (props) => {
  const requestRef = useRef();
  const [stage, setStage] = useState(props.stage);
  const stg1to2 = () => setStage(2);
  const stg2to3 = () => {
    setStage(3);
    props.submitSessionsRequest(requestRef.current.value);
  };
  return (
    <>
      {stage === 0 ? (
        <>
          <CheckinBox>
            <CheckInDateWrapper>
              <AdjustableSpacer width="20px" />
              <CheckinIconContainer>
                <CheckinIcon></CheckinIcon>
              </CheckinIconContainer>
              <AdjustableSpacer width="23px" />
              <CheckinPointContainer>
                <CheckinLabel>Check-in point</CheckinLabel>
                <CheckinDate>
                  {props.sessionObj.date.format("dddd")},{" "}
                  {props.sessionObj.date.format("MMMM")}{" "}
                  {props.sessionObj.date.format("D")}
                </CheckinDate>
              </CheckinPointContainer>
              <AdjustableSpacer width="70px" />
            </CheckInDateWrapper>
            <CheckinButton onClick={props.openCheckInBigBox}>
              {/* <CheckinButton > */}
              <CheckinButtonText>What's This?</CheckinButtonText>
            </CheckinButton>
          </CheckinBox>
        </>
      ) : stage === 1 ? (
        <>
          <CheckInStg1>
            <AdjustableSpacer height="15px" />
            <CheckinStgsCheckinBox>
              <AdjustableSpacer width="20px" />
              <CheckinIconContainer>
                <CheckinIcon></CheckinIcon>
              </CheckinIconContainer>
              <AdjustableSpacer width="23px" />
              <CheckinPointContainer>
                <CheckinLabel>Check-in point</CheckinLabel>
                <CheckinDate>
                  {props.sessionObj.date.format("dddd")},{" "}
                  {props.sessionObj.date.format("MMMM")}{" "}
                  {props.sessionObj.date.format("D")}
                </CheckinDate>
              </CheckinPointContainer>
              <AdjustableSpacer width="140px" />
              <BackButton>
                <X onClick={props.toggleCheckin}></X>
              </BackButton>
            </CheckinStgsCheckinBox>
            <AdjustableSpacer height="20px" />
            <CheckinDescr>
              At Marvin we think therapy should be a collaborative effort
              between you and your therapist. At your check-in session you will
              chat about how things are going, review your progress and let us
              know if you would like to continue therapy and schedule 8 more
              sessions.
            </CheckinDescr>
            <AdjustableSpacer height="15px" />
            <>
              <CheckInStg1Button onClick={stg1to2}>
                <CheckinStgsButtonText>
                  Schedule additional sessions
                </CheckinStgsButtonText>
              </CheckInStg1Button>
            </>
          </CheckInStg1>
        </>
      ) : stage === 2 ? (
        <CheckInStg2>
          <AdjustableSpacer height="15px" />
          <CheckinStgsCheckinBox>
            <AdjustableSpacer width="20px" />
            <CheckinIconContainer>
              <CheckinIcon></CheckinIcon>
            </CheckinIconContainer>
            <AdjustableSpacer width="23px" />
            <CheckinPointContainer>
              <CheckinLabel>Check-in point</CheckinLabel>
              <CheckinDate>
                {props.sessionObj.date.format("dddd")},{" "}
                {props.sessionObj.date.format("MMMM")}{" "}
                {props.sessionObj.date.format("D")}
              </CheckinDate>
            </CheckinPointContainer>
            <AdjustableSpacer width="140px" />
            <BackButton>
              <X onClick={props.toggleCheckin}></X>
            </BackButton>
          </CheckinStgsCheckinBox>
          <AdjustableSpacer height="20px" />
          <CheckinDescr>
            At Marvin we think therapy should be a collaborative effort between
            you and your therapist. At your check-in session you will chat about
            how things are going, review your progress and let us know if you
            would like to continue therapy and schedule 8 more sessions.
          </CheckinDescr>
          <AdjustableSpacer height="10px" />
          <TextAreaContainer>
            <AdjustableSpacer width="20px" />
            <TextAreaWithLabel
              inputLabel="When would you like to schedule your additional sessions for?"
              height="100px"
              ref={requestRef}
            ></TextAreaWithLabel>
            <AdjustableSpacer width="20px" />
          </TextAreaContainer>
          <AdjustableSpacer height="15px" />
          <CheckInStg2Button onClick={stg2to3}>
            {" "}
            <CheckinStgsButtonText> Submit </CheckinStgsButtonText>{" "}
          </CheckInStg2Button>
        </CheckInStg2>
      ) : stage === 3 ? (
        <CheckInStg3>
          <AdjustableSpacer height="15px" />
          <CheckinStgsCheckinBox>
            <AdjustableSpacer width="20px" />
            <CheckinIconContainer>
              <CheckinIcon></CheckinIcon>
            </CheckinIconContainer>
            <AdjustableSpacer width="23px" />
            <CheckinPointContainer>
              <CheckinLabel>Check-in point</CheckinLabel>
              <CheckinDate>
                {props.sessionObj.date.format("dddd")},{" "}
                {props.sessionObj.date.format("MMMM")}{" "}
                {props.sessionObj.date.format("D")}
              </CheckinDate>
            </CheckinPointContainer>
            <AdjustableSpacer width="140px" />
            <BackButton>
              <X onClick={props.toggleCheckin}></X>
            </BackButton>
          </CheckinStgsCheckinBox>
          <AdjustableSpacer height="20px" />
          <CheckinDescr>
            At Marvin we think therapy should be a collaborative effort between
            you and your therapist. At your check-in session you will chat about
            how things are going, review your progress and let us know if you
            would like to continue therapy and schedule 8 more sessions.
          </CheckinDescr>
          <AdjustableSpacer height="10px" />
          <ReceivedBox>
            <ReceivedText>
              We've received your request and are scheduling your additional
              sessions.
            </ReceivedText>
          </ReceivedBox>
        </CheckInStg3>
      ) : null}
    </>
  );
};

export default CheckInPoint;
