import styled from "styled-components";

export const NavWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;

export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
`;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-left: 39px;
  box-sizing: border-box;

  /* Desktop / Body / 2 */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 1 */

  color: #333333;
`;

export const Title = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;

  color: #333333;
`;

export const BackTitle = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;
`;

export const LeftBar = styled.div`
  display: flex;
  align-items: center;
  margin-left: 39px;
`;

export const RightBar = styled.div`
  display: flex;
  align-items: center;
  margin-right: 30px;
`;

export const SafetyContainer = styled.div`
  position: relative;
`;

export const Span = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #333333;
  margin-right: 16px;
`;

export const CircleBtn = styled.button`
  position: relative;
  margin: 0px 16px 0px 0px;
  width: 40px;
  height: 40px;
  /* background: no-repeat url("/images/components/Navbar/Question.svg"); */
  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Stroke / White Button */

  border: 1px solid rgba(242, 242, 242, 0.25);
  box-sizing: border-box;
  /* Up / White Button 25-36 */

  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);

  border-radius: 50%;
  border: none;
  &:hover {
    /* Buttons/Secondary& Icon Small/Default */
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    cursor: pointer;
  }
  &:active {
    box-shadow: inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
  }
`;

export const CircleBtnActive = styled.button`
  position: relative;
  margin: 0px 16px 0px 0px;
  width: 40px;
  height: 40px;
  /* background: no-repeat url("/images/components/Navbar/Question.svg"); */
  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Stroke / White Button */

  border: 1px solid rgba(242, 242, 242, 0.25);
  box-sizing: border-box;
  /* Up / White Button 25-36 */

  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  box-shadow: inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  filter: drop-shadow(-2px -3px 10px #ffffff)
    drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));

  border-radius: 50%;
  border: none;
`;

export const AvatarImg = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;

  /* Buttons/White Button 25 - 36 */

  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  /* filter: drop-shadow(-1.66667px -1.66667px 5px #FFFFFF) drop-shadow(2.5px 2.5px 10px rgba(202, 208, 223, 0.9)); */
  &:hover {
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    cursor: pointer;
  }
`;

export const AvatarImgActive = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #618cf1;
  box-sizing: border-box;

  /* Buttons/White Button 25 - 36 */

  box-shadow: inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  filter: drop-shadow(-1.66667px -1.66667px 5px #ffffff)
    drop-shadow(2.5px 2.5px 10px rgba(202, 208, 223, 0.9));
  &:hover {
    cursor: pointer;
  }
`;

export const CircleBtnIcon = styled.img`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  margin: auto;
`;

export const HambergerBtn = styled.button`
  display: none;
  @media screen and (max-width: 1200px) {
    display: flex;
    position: relative;
    margin: 0px 16px 0px 0px;
    width: 40px;
    height: 40px;
    /* background: no-repeat url("/images/components/Navbar/Question.svg"); */
    /* White Elements/Default */

    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #f6f6f6;
    /* Stroke / White Button */

    border: 1px solid rgba(242, 242, 242, 0.25);
    box-sizing: border-box;
    /* Up / White Button 25-36 */

    box-shadow: -1.66667px -1.66667px 5px #ffffff,
      2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
      inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);

    border-radius: 50%;
    border: none;
    &:hover {
      /* Buttons/Secondary& Icon Small/Default */
      filter: drop-shadow(-2px -3px 10px #ffffff)
        drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
      cursor: pointer;
    }
    &:active {
      box-shadow: inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
      filter: drop-shadow(-2px -3px 10px #ffffff)
        drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    }
  }
`;


export const HambergerBtnIcon = styled.div`
  padding-left: 4px;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Waves = styled.img`

  object-fit: cover;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;

`;

export const Logo = styled.img` 

  width: 157px;
  height: 65px;
  object-fit: contain;
`;