import {
  ButtonBox,
  ButtonControl,
  ButtonLabel,
  ChatNotification,
  ChatSessTxt,
  UnreadMessagesBox,
} from "./styles";

import AdjustableSpacer from "../AdjustableSpacer";

const ChatSessionButton = (props) => {
  return (
    <>
      {props.chatInbox ? (
        <ButtonBox onClick={props.buttonHandler} chatOpen={props.chatOpen}>
          <AdjustableSpacer height="11px" />
          <ButtonControl src={props.pic} />
          <AdjustableSpacer height="6px" />
          <ButtonLabel>{props.labelText}</ButtonLabel>
          <UnreadMessagesBox>
            <ChatNotification src="/images/components/ChatSessionButton/UnreadMessageIcon.png" />
            <ChatSessTxt>{props.chatInbox}</ChatSessTxt>
          </UnreadMessagesBox>
        </ButtonBox>
      ) : (
        <ButtonBox onClick={props.buttonHandler} chatOpen={props.chatOpen}>
          <AdjustableSpacer height="11px" />
          <ButtonControl src={props.pic} />
          <AdjustableSpacer height="6px" />
          <ButtonLabel>{props.labelText}</ButtonLabel>
        </ButtonBox>
      )}
    </>
  );
};

export default ChatSessionButton;
