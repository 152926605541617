import React, {useEffect, useState} from 'react'


import moment from 'moment';

import {
    TimeSpan
} from "./styles"


const Time = () => {

    const [timeStr, setTimeStr] = useState(moment().format("LT"));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeStr(moment().format("LT"));
        }, 60000);
        return () => clearInterval(interval);
      }, []);


  return (
            <TimeSpan>{timeStr}</TimeSpan>
    )
}

export default Time