import styled from "styled-components";

export const Background = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VideoBG = styled.div`
  position: fixed;
  z-index: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  object-fit: fill;
`;


export const MainVideo = styled.video`
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  &:hover{
        background: rgba(0, 0, 0, 0.75);
        border-radius: 6px;
        /* z-index:5; */
    }
`;

export const MarvinLogo = styled.img`
  position: absolute;
  top: 2.7%;
  left: 48px;
  z-index: 2;
  /* background-image: url("images/pages/VideoSession/WhiteLogo.svg"); */
  height: 25px;
  width: 110px;

  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const ButtonStripLeft = styled.div`
  position: absolute;
  left: 14px;
  bottom: 15px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const ButtonStripRight = styled.div`
  position: absolute;
  right: 14px;
  bottom: 15px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  @media screen and (max-width: 768px){
    display: none;

  }
`;

export const ButtonStripBig = styled.div`
  position: absolute;
  bottom: 3px;
  width: 84%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
`;

export const ButtonStripCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: absolute;
  bottom: 15px;
  z-index: 2;
`;

export const BottomShadowContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100vw;
  height: 40vh;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.7)
  );
  z-index: 1;
`;

export const TopShadowContainer = styled.div`
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 15vh;
  background: linear-gradient(
    to top,
    rgba(255, 255, 255, 0),
    rgba(0, 0, 0, 0.7)
  );
  z-index: 1;
`;

export const VGBGContainer = styled.div`
  position: absolute;
  right: 25px;
  bottom: 15px;
  z-index: 2;
  flex-grow: 0;
`;


export const TileViewIcon = styled.img`
  /* Gallery View */
  width: 25px;
  height: 50px;
  object-fit: contain;
  padding-left: 10px;

`;

export const TileViewBox = styled.div`   
  /* width: 177px; */
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  &:hover {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    cursor: pointer;
  }
`;
export const FullScreenIcon = styled.img`
  /* Gallery View */
  width: 25px;
  height: 50px;
  object-fit: contain;
  padding-left: 10px;
 
`;

export const FullScreenBox = styled.div`   
  /* width: 258px; */
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    cursor: pointer;
  }

`;

export const FullScreenText = styled.div`   
    padding-left: 15px;
    width: 152px;
  
    /* Desktop/Body/Link */
    font-family: 'Syne';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 17px;

    color: #FFFFFF;


`;




export const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 258px; */

`;

export const ParticipantsBox = styled.div`
  position: absolute;
  right: 48px;
  top: 2.7%;
  
  // display: flex;
  // flex-direction: column;
  /* height: 30vh; */
  z-index: 1;
  //  overflow-y: scroll;
`;

//****************************Stop Camera Button Box******************
export const RowOne = styled.div`
  flex-grow: 1;
`;
export const RowTwo = styled.div`
  flex-grow: 1;
`;

export const RowThree = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
`;

export const CameraOffBox = styled.div`
  width: 258px;
  height: 145px;
  /* Gray / 1 */
  background: #333333;
  border-radius: 6px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

export const CameraOffIcon = styled.div`
  width: 22px;
  height: 19px;
  background-image: url("images/pages/VideoSession/StopCameraBoxIcon.svg");
  align-self: flex-end;
  margin-right: auto;
`;

export const StopCameraBoxNameCircle = styled.div`
  /* Gradients/Blue Purple */
  background: linear-gradient(
      155.69deg,
      rgba(0, 0, 0, 0.16) -13.54%,
      rgba(255, 255, 255, 0) 111.28%
    ),
    linear-gradient(332.15deg, #618cf1 4.89%, #6569ee 93.6%);
  height: 62px;
  width: 62px;
  background-image: url("images/pages/VideoSession/StopCameraBoxNameCircle.svg");
  background-size: contain;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const StopCameraBoxInitial = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;

  color: #ffffff;
`;

//************************User Video**************************
export const UserVideo = styled.video`
  // width: 258px;
  // height: 145px;

  width: 100%;
  height: 100%;
  z-index: 1;
  border-radius: 6px;
  object-fit: cover;
`;

export const UserVideoBox = styled.div`
  width: 258px;
  height: 145px;
  border-radius: 6px;
  display: flex;
  position: relative;
`;


export const LockMeetingButtonContainer = styled.div`  
    /* border: solid red 1px; */
    position: relative;
`;


export const LockRoomHoverBox = styled.div`
    position: absolute;
    bottom: 100px;
    left: -158px;
    width: 258px;
    height: 131px;

    background: rgba(0, 0, 0, 0.75);

    /* Blur/ Video Session BG */
    backdrop-filter: blur(66px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
    display: none ;
    ${LockMeetingButtonContainer}:hover & {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
`;
export const LockRoomTitle = styled.div`   
    /* Desktop/Header/2 */
    font-family: 'Syne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* White Elements/Default */
    background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */

    display: none;

    ${LockMeetingButtonContainer}:hover & {
      display: initial;
    }
`;

export const LockRoomText  = styled.div`  
    /* Mobile/Input Field */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;

    /* or 14px */
    letter-spacing: 0.25px;

    /* Flat / White */
    color: #FAFAFA;
    width: 222px;
    height: 59px;
    mix-blend-mode: normal;
    opacity: 0.8;
    display: none;
    ${LockMeetingButtonContainer}:hover & {
      display: initial;
    }

`;
export const DevicesButtonContainer = styled.div`  
    /* border: solid red 1px; */
    position: relative;
`;
export const DevicesHoverBox = styled.div`
    position: absolute;
    bottom: 100px;
    left: -158px;
    width: 250px;
    height: 400px;

    background: rgba(0, 0, 0, 0.75);

    /* Blur/ Video Session BG */
    backdrop-filter: blur(66px);

    /* Note: backdrop-filter has minimal browser support */
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
`;
export const DevicesTitle = styled.div`   
    /* Desktop/Header/2 */
    font-family: 'Syne';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* White Elements/Default */
    background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    /* text-fill-color: transparent; */


  
`;


export const DevicesText  = styled.div`  
    /* Mobile/Input Field */
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 110%;

    /* or 14px */
    letter-spacing: 0.25px;

    /* Flat / White */
    color: #FAFAFA;
    width: 222px;
    height: 15px;
    mix-blend-mode: normal;
    opacity: 0.8;
  

`;



