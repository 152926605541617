import styled from "styled-components";

export const FilmstripBarContainer = styled.div`

    /* Virtual BG */
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 292px;
    overflow-y: scroll;
    height: 100%;
    max-height: 65vh;
    row-gap: 15px;
    height: 100%;
    
    
`;
