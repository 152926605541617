import styled from "styled-components";

export const Box = styled.div`
  position: relative;
`;

export const MarginBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 441px;
  height: 68px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 401px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  width: 167px;
`;

export const SessionBox = styled.div`
  display: flex;
  flex-direction: row;
  /* flex-grow: initial; */
  align-items: center;
  justify-content: space-between;
`;

export const DateCircle = styled.div`
  width: 38px;
  height: 38px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Down - White Element 35-60 */
  box-shadow: inset -2.5px -2.5px 5px #fafbff,
    inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
  border-radius: 52px;
  transform: rotate(-0.2deg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Day = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  /* display: flex;
    align-items: center; */

  color: #333333;
`;

export const DateTime = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* width: 167px; */
`;

export const Date = styled.div`
  /* Mobile/Button/1 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* display: flex;
    align-items: center; */
  color: #333333;
  white-space: nowrap;
`;

export const Time = styled.div`
  /* Mobile/Body/3 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;

  /* identical to box height, or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  color: #333333;
`;

export const DateTimeBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ThreeDots = styled.div`
  z-index: 1;
  height: 4px;
  width: 17.5px;
  background-image: url("images/components/Session/Settings.svg");
  /* background-repeat: no-repeat; */
  /* background-size: auto; */
  /* position: relative; */
`;

export const SessionSettings = styled.div`
  width: 38px;
  height: 38px;
  /* background: url("images/components/Session/Settings.svg"); */
  /* background-image: url("images/components/Session/Settings.svg"); */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-sizing: border-box;
  box-shadow: -1.66667px -1.66667px 5px #ffffff,
    2.5px 2.5px 10px rgba(202, 208, 223, 0.9),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(242, 242, 242, 0.25);
  border-radius: 100%;
  z-index: 1;
  /* background-repeat: no-repeat;
    background-size: auto; */
  &:hover {
    z-index: 1;
    cursor: pointer;
    box-shadow: inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
    filter: drop-shadow(-1.66667px -1.66667px 5px #ffffff)
      drop-shadow(2.5px 2.5px 10px rgba(202, 208, 223, 0.9));
  }
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SettingsBox = styled.div`
  z-index: 4;
  display: flex;
  flex-direction: row;
  width: 117px;
  height: 92px;

  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-shadow: -7px -7px 14px rgba(255, 255, 255, 0.1),
    5px 5px 25px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  position: absolute;
  top: 35px;
  right: 19px;

  &:hover {
    cursor: auto;
  }
`;

export const SettingsBoxMargins = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  z-index: 3;
`;
export const Reschedule = styled.div`
  /* Desktop / Button / Primary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 3 */
  color: #a2aecb;
  z-index: 3;
  &:hover {
    /* Gray / 1 */
    color: #333333;
    cursor: pointer;
    opacity: 0.9;
  }
`;

export const Cancel = styled.div`
  /* Desktop / Button / Primary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  z-index: 3;
  /* Gray / 3 */
  color: #a2aecb;
  &:hover {
    cursor: pointer;
    /* Gray / 1 */
    color: #333333;
  }
`;
