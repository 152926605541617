import React from "react";
import RenderSidebarIcon from "../RenderSidebarIcon"
import {
  SidebarWrapper,
  SideTopGrouping,
  SideTab,
  TabItem,
  Title,
  Icon,
  IconImg,
  TabItemSelected,
  MarvinWrapper,
} from "./styles";

const Sidebar = (props) => {
  return (
    <SidebarWrapper>
      <SideTopGrouping>
        <MarvinWrapper to="/dashboard">
          <img src="/images/components/Sidebar/SideLogo.svg" alte="sideLogo" />{" "}
        </MarvinWrapper>
        {/* <TabItem to='/dashboard' ><img src="/images/components/Sidebar/SideLogo.svg" /> </TabItem> */}
        <SideTab>
          {props.tab === "meet" ||
          props.tab === "meetMT" ||
          props.tab === "meetCT" ||
          props.tab === "meetWR" ? (
            <TabItemSelected to="/dashboard" onClick={props.meetOptionHandler}>
              <Title>Meet</Title>
              <Icon>
                <IconImg src="/images/components/Sidebar/MeetSelected.svg" />
              </Icon>
            </TabItemSelected>
          ) : (
            <TabItem to="/dashboard" onClick={props.meetOptionHandler}>
              <Title>Meet</Title>
              <RenderSidebarIcon icon = "meet"/>
            </TabItem>
          )}
          {props.tab === "grow" || props.tab === "growVideo" ? (
            <TabItemSelected to="/grow" onClick={props.growOptionHandler}>
              <Title>Grow</Title>
              <Icon>
                <IconImg src="/images/components/Sidebar/GrowSelected.svg" />
              </Icon>
            </TabItemSelected>
          ) : (
            <TabItem to="/grow" onClick={props.growOptionHandler}>
              <Title>Grow</Title>
             <RenderSidebarIcon icon = "grow"/>
            </TabItem>
          )}
          {props.tab === "settings" ? (
            <TabItemSelected
              to="/settings"
              onClick={props.settingsOptionHandler}
            >
              <Title>Settings</Title>
              <Icon>
                <IconImg src="/images/components/Sidebar/SettingsSelected.svg" />
              </Icon>
            </TabItemSelected>
          ) : (
            <TabItem to="/settings" onClick={props.settingsOptionHandler}>
              <Title>Settings</Title>
                <RenderSidebarIcon icon = "settings"/>
            </TabItem>
          )}
        </SideTab>
      </SideTopGrouping>
    </SidebarWrapper>
  );
};
export default Sidebar;
