import styled from "styled-components";

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 282px;
  height: 55px;
  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Large /Default */
  box-shadow: 8px 8px 16px rgba(106, 204, 220, 0.3), -4px -4px 16px #ffffff,
    4px 4px 12px rgba(202, 223, 219, 0.7);
  border-radius: 100px;

  &:hover {
    cursor: pointer;
    background: linear-gradient(
        342.94deg,
        rgba(255, 255, 255, 0.4) -182.89%,
        rgba(0, 0, 0, 0.4) 132.93%
      ),
      linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
    background-blend-mode: soft-light, normal;
    /* Buttons/Primary Large /Default */
    box-shadow: 8px 8px 16px rgba(106, 204, 220, 0.3), -4px -4px 16px #ffffff,
      4px 4px 12px rgba(202, 223, 219, 0.7);
  }
`;

export const ButtonLabelText = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
  color: #fefefe;
  mix-blend-mode: normal;
  margin-right: 15px;
`;

export const ButtonNavArrow = styled.img`
  width: auto;
  height: 15px;
`;
