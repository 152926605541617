import styled from "styled-components";

export const WidthContainer = styled.div`
  width: 398px;
`;

export const MarginBox = styled.div`
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  width: 441px;
  height: 326px;

  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DateTimeBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DayCircle = styled.div`
  width: 38px;
  height: 38px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Down - White Element 35-60 */
  box-shadow: inset -2.5px -2.5px 5px #fafbff,
    inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
  border-radius: 52px;
  transform: rotate(-0.2deg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Day = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  display: flex;
  align-items: flex-end;
  color: #333333;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Date = styled.div`
  /* Mobile/Button/1 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  white-space: nowrap;
  /* align-items: center; */
  /* word-wrap: none; */
  /* Gray / 1 */
  color: #333333;
`;

export const Time = styled.div`
  /* Mobile/Body/3 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;

  /* identical to box height, or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  /* Gray / 1 */
  color: #333333;
`;

export const X = styled.div`
  width: 26px;
  height: 26px;
  background-image: url("images/components/Meet/X.svg");
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  &:hover {
    cursor: pointer;
    /* Buttons/Secondary& Icon Small/Default */
    box-shadow: inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    cursor: pointer;
    border-radius: 100%;
  }
`;

export const Warning = styled.div`
  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 398px;
  /* Gray / 1 */
  color: #333333;
`;

export const SubmitButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 130px;
  height: 30px;
  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  &:hover {
    cursor: pointer;

    /* Buttons/Primary Hover */
    background: linear-gradient(
        342.94deg,
        rgba(255, 255, 255, 0.4) -182.89%,
        rgba(0, 0, 0, 0.4) 132.93%
      ),
      linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
    background-blend-mode: soft-light, normal;

    /* Buttons/Primary Small /Hover */
    box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
      inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
    border-radius: 75px;
  }
`;

export const SubmitLabel = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  color: #fefefe;
`;
