import styled from "styled-components";
// import dropdownArrow from '/images/selectBoxWithLabel/Dropdown Arrow.png'

export const LabelText = styled.div`
  /* Desktop/Body/Label */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;

  margin-bottom: 10px;
`;

export const LabelAndInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const SelectBox = styled.select`
  /* Rectangle 42 */
  height: 40px;
  width: 100%;
  // width: ${(props) => props.width || "204px"};

  padding-left: 14px;
  width: 100%;

  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White/Up - Height >50 */

  box-shadow: -7px -7px 14px #ffffff, 5px 5px 25px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;

  /* Desktop / Body / Input Field */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  /* Gray / 1 */

  color: #333333;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  background: url("/images/components/SelectBoxWithLabel/Dropdown Arrow.png")
    no-repeat scroll right 16px center/18px transparent;

  border: none;
  outline: none !important;
  border: 1px solid ${(props) => (!props.validated ? "#E73144" : "none")};
`;

export const Option = styled.option`
  // /* Rectangle 42 */

  // position: absolute;
  // width: 348px;
  // height: 224px;
  // left: 0px;
  // top: 0px;

  // /* White Elements/Default */

  // background: linear-gradient(329.58deg, rgba(255, 255, 255, 0.05) 9.56%, rgba(92, 112, 162, 0.05) 87.95%), #F6F6F6;
  // /* Flat/Blue */

  // border: 1px solid #618CF1;
  // /* Desktop White/Up - Height >50 */

  // box-shadow: -7px -7px 14px #FFFFFF, 5px 5px 25px rgba(202, 208, 223, 0.85), inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  // border-radius: 8px;

  // &:hover {
  //     background-color: #dadada;
  //   }
`;
