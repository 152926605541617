import React from 'react'

import {
  LabelText,
  LabelAndInputContainer,
  SelectBox,
  Option,
} from "./styles.js";

const DevicePicker = (props)=> {
    
  let defaultValueArray = [1,1];
  console.log(`Inside device picker`, props.valueArray);

  const handleChange = (e) => {
      const value = e.target.value;
      console.log(`here is e.target.value`, value);
 
      props.onChange(value);
  }

  if(props.valueArray)defaultValueArray = props.valueArray;
    return (
      <LabelAndInputContainer>
        <SelectBox
          defaultValue={props.value}
          name={props.name}
          onChange={handleChange}
          disabled={props.disabled ? props.disabled : false}
        >
          {/* <Option value="" selected disabled hidden></Option> */}
          {defaultValueArray.map((val, index) => (
            <Option key={index} value={val.deviceId}>
              {val.label}
            </Option>
          ))}
        </SelectBox>
      </LabelAndInputContainer>
    );
  }
  

  



export default DevicePicker;