import styled from "styled-components";


export const FlexContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;


export const ICard = styled.div`
  width: 100%;
  margin: 30px;
  position: relative;
`;
