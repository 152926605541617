import styled from 'styled-components';

export const ButtonContainer = styled.button`
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 28px;

    background: 
    ${props => 
        props.disabled ? `linear-gradient(296.83deg, rgba(255, 255, 255, 0.4) -74.33%, rgba(0, 0, 0, 0.4) 6.69%), linear-gradient(331.17deg, #A9EBDB 23.63%, #98E4EB 140.35%);`
                       : `linear-gradient(303.64deg, rgba(255, 255, 255, 0.4) 16.13%, rgba(0, 0, 0, 0.4) 132.9%), linear-gradient(326.75deg, #6ADCB3 -14.42%, #6ACCDC 99.59%);`}
    background-blend-mode: soft-light, normal;

    box-shadow: ${props =>
        props.disabled ? `inset 2px 2px 4px rgba(115, 208, 208, 0.3), inset -2px -2px 4px rgba(213, 249, 241, 0.4);`
                       : `box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #FFFFFF, 3px 3px 12px rgba(202, 223, 219, 0.7);`}
    border-radius: 75px;

    &:hover{
      cursor: ${props => props.disabled ? "initial" : "pointer"};
    }
`;

export const ButtonText = styled.div`
    font-family: 'Syne';
    font-size: 14px;
    line-height: 17px;
    color: #FEFEFE;
    width: 100%;
`;
