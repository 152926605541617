import styled from "styled-components";

export const CheckInDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
export const CheckinBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  width: 441px;
  height: 68px;

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;

export const CheckinIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Down - White Element 35-60 */
  box-shadow: inset -2.5px -2.5px 5px #fafbff,
    inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
  border-radius: 52px;
  transform: rotate(-0.2deg);
`;

export const CheckinIcon = styled.div`
  width: 18px;
  height: 19px;
  background-image: url("images/components/Checkin/CheckinIcon.svg");
  background-size: contain;
`;

export const CheckinLabel = styled.div`
  /* Mobile/Button/1 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #333333;
`;

export const CheckinButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  justify-self: end;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Buttons/Secondary& Icon Small/Default */
  box-shadow: -2px -3px 10px #ffffff, 0px 3px 10px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 75px;
  width: 130px;
  height: 30px;
  &:hover {
    cursor: pointer;

    /* White Elements/Desktop White Hover <50 */
    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #e3e8f0;

    /* Buttons/Secondary& Icon Small/Hover */
    box-shadow: -2px -8px 10px #ffffff, 0px 6px 14px rgba(202, 208, 223, 0.85),
      inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    border-radius: 75px;
  }
`;

export const CheckinPointContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IconAndDetailsBox = styled.div``;

export const CheckinButtonText = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  /* Gray / 1 */
  color: #333333;

  mix-blend-mode: normal;
`;
export const CheckinDate = styled.div`
  /* Mobile/Body/3 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;

  /* identical to box height, or 16px */
  display: flex;
  align-items: center;
  letter-spacing: 0.3px;

  color: #333333;
`;

export const X = styled.div`
  width: 26px;
  height: 26px;
  background-image: url("images/components/Meet/X.svg");
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  height: 38px;
  width: 38px;
  &:hover {
    /* Buttons/Secondary& Icon Small/Default */
    box-shadow: inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    cursor: pointer;
    border-radius: 16px;
  }
`;

export const CheckinDescr = styled.div`
  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  margin-left: 20px;
  margin-right: 20px;

  /* Gray / 1 */
  color: #333333;
`;

export const CheckinStgsCheckinBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckinStgsButtonText = styled.span`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  color: #fefefe;

  mix-blend-mode: normal;
`;
export const CheckInStg1 = styled.div`
  display: flex;
  flex-direction: column;

  /* Rectangle */

  width: 441px;
  height: 194px;

  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;

export const CheckInStg1Button = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  align-self: center;
  width: 222px;
  height: 30px;
  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  &:hover {
    cursor: pointer;
    /* Buttons/Primary Hover */
    background: linear-gradient(
        342.94deg,
        rgba(255, 255, 255, 0.4) -182.89%,
        rgba(0, 0, 0, 0.4) 132.93%
      ),
      linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
    box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
      inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
  }
`;

export const CheckInStg2 = styled.div`
  display: flex;
  flex-direction: column;

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White Card Stroke */

  border: 1px solid #f1f1f1;
  box-sizing: border-box;
  /* Cards/Cool White Card 45-85 */

  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;

  width: 441px;
  height: 328px;
`;

export const CheckInStg2Button = styled.div`
  /* Auto Layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  /* padding: 8px 24px; */
  width: 130px;
  height: 30px;
  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;
  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  &:hover {
    /* Buttons/Primary Hover */
    cursor: pointer;
    background: linear-gradient(
        342.94deg,
        rgba(255, 255, 255, 0.4) -182.89%,
        rgba(0, 0, 0, 0.4) 132.93%
      ),
      linear-gradient(323.71deg, #6adcb3 -41.08%, #6accdc 96.31%);
    box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
      inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
    box-shadow: -4px -4px 10px #ffffff, 0px 8px 14px rgba(106, 170, 163, 0.35),
      inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
  }
`;

export const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const ReceivedBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 401px;
  height: 54px;

  /* Gradients/Nav Selected */
  background: linear-gradient(
      321.07deg,
      rgba(0, 0, 0, 0.6) 1.82%,
      rgba(255, 255, 255, 0) 98.83%
    ),
    #618cf1;
  background-blend-mode: soft-light, normal;

  /* Desktop / Down - Blue Purple >111 */
  box-shadow: inset 6px 6px 17px #3d6ad3, inset -4px -8px 11px #658ff2;
  border-radius: 8px;
`;

export const ReceivedText = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  padding-left: 20px;
  /* Flat / White */

  color: #fafafa;

  mix-blend-mode: normal;
`;
export const CheckInStg3 = styled.div`
  display: flex;
  flex-direction: column;

  width: 441px;
  height: 213px;

  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;
