import React from 'react'

import {
    MobileBox,
    MobileTitle,
    MobileMarvin,
    MobileDescription,
    MobileButton,
    MobileButtonText,
    TabletButton,
    TabletDescription
} from "./styles"

const MobileAppPoster = (props) => {
    const hiMarvin = "/images/components/MobileMarvin/helloMarvin.gif";

  return (
    <MobileBox>
        <MobileTitle>Welcome to Marvin!</MobileTitle>
        <MobileMarvin src = {hiMarvin}></MobileMarvin>
        <MobileDescription>
          To join your session on your smartphone, please click the button below to download our mobile app        
          </MobileDescription>
          <TabletDescription>
          Are you on an iPad or Android tablet? If so, please click the "Download tablet app" button below to get our app and join your session.
          </TabletDescription>
        <MobileButton onClick = {() => window.location.href ="https://onelink.to/bmp37k"}>
        <MobileButtonText>Download mobile app</MobileButtonText>
        </MobileButton>
        <TabletButton onClick = {() => window.location.href ="https://onelink.to/bmp37k"}>
          <MobileButtonText>
          Download tablet app
          </MobileButtonText>
        </TabletButton>
    </MobileBox>
  )
}

export default MobileAppPoster