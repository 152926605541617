import styled from "styled-components";


export const FlexContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
`;


export const FlexContainerTwo = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;



export const DashBoardContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  background: url("/images/components/Dashboard/DashboardBG.svg") no-repeat
    center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;



export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
`;

export const ICard = styled.div`
  width: 50%;
  margin: 30px;
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 39px;
  padding-top: 10px;

  @media screen and (max-width: 1200px) {
    padding-top: 20px;
  }
`;

export const Text = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  /* Gray / 3 */
  color: #a2aecb;
  &:hover {
    color: #333333;
  }
`;

export const TextActive = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */

  color: #fafafa;
`;

export const ProfileInactiveButton = styled.div`
  /* White Elements/Default */
  width: 72px;
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const ProfileButtonActive = styled.div`
  width: 72px;
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  /* padding-right: 15px;
    padding-left: 15px;
  */

  /* Gradients/Nav Selected */
  background: linear-gradient(
      321.07deg,
      rgba(0, 0, 0, 0.6) 1.82%,
      rgba(255, 255, 255, 0) 98.83%
    ),
    #618cf1;
  background-blend-mode: soft-light, normal;

  /* Desktop/Down - Nav Selected */
  box-shadow: inset 5px 5px 16px #3d6ad3, inset -0.5px -0.5px 9px #658ff2;
  border-radius: 14px;
`;

export const AccountInactiveButton = styled.div`
  /* White Elements/Default */
  width: 85px;
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`;

export const AccountButtonActive = styled.div`
  width: 85px;
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  /* padding-right: 15px;
    padding-left: 15px;
  */

  /* Gradients/Nav Selected */
  background: linear-gradient(
      321.07deg,
      rgba(0, 0, 0, 0.6) 1.82%,
      rgba(255, 255, 255, 0) 98.83%
    ),
    #618cf1;
  background-blend-mode: soft-light, normal;

  /* Desktop/Down - Nav Selected */
  box-shadow: inset 5px 5px 16px #3d6ad3, inset -0.5px -0.5px 9px #658ff2;
  border-radius: 14px;
`;
