import AdjustableSpacer from "../AdjustableSpacer";

import {
  ExerciseCardContainer,
  TopPic,
  BottomBox,
  DetailsStrip,
  Icon,
  ExerciseDetails,
  ExerciseName,
  ExerciseDescription,
} from "./styles";

const ExerciseCard = (props) => {
  let twoLineTitle = false;

  // Checks the length of the title, >25 characters forces a second line in the exercise card.
  const processTitle = (title) => {
    if (title.length >= 25) {
      twoLineTitle = true;
    }
    return title;
  };
  // Checks the description's length, allowing a certain amount of characters depending on the line count of the title
  const processDescr = (descr) => {
    if (twoLineTitle) {
      if (descr.length > 74) {
        // Returns the first 104 characters with ... after
        return descr.substr(0, 73) + "...";
      }
      return descr;
    }

    // Previous if statement was not hit, meaning title is on a single line, allowing more descr characters
    if (descr.length > 106) {
      // Returns the first 136 characters with ... after
      return descr.substr(0, 104) + "...";
    }
    return descr;
  };
  return (
    <ExerciseCardContainer onClick={props.handleOpenExercise}>
      <TopPic bg={props.topPic} />
      <AdjustableSpacer height="12px" />
      <BottomBox>
        <DetailsStrip>
          <Icon icon={props.icon} />
          <ExerciseDetails>{props.exerciseDetails}</ExerciseDetails>
        </DetailsStrip>
        <AdjustableSpacer height="9px" />
        <ExerciseName>{processTitle(props.exerciseName)}</ExerciseName>
        <AdjustableSpacer height="3px" />
        <ExerciseDescription>
          {processDescr(props.exerciseDescription)}
        </ExerciseDescription>
      </BottomBox>
    </ExerciseCardContainer>
  );
};

export default ExerciseCard;
