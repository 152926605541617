import React, { useState, useRef, useEffect } from "react";
import AdjustableSpacer from "../AdjustableSpacer";
import SelectBoxWithLabel from "../SelectBoxWithLabel";
import TextAreaWithLabel from "../TextAreaWithLabel";
import useStore from "../../utils/Zustand/store";
import submitContactMarvin from "../../utils/UserAppApi/submitContactMarvin";
import {
  FirstContentBox,
  ContactMarvinContentBox,
  EmergencyOrContactText,
  EmergencySupportOuterBox,
  EmergencySupportContent,
  FirstOuterBox,
  ContactMarvinOuterBox,
  ContactMarvinText,
  SendMessageBtn,
  EmergencySupportText,
  EmergencyQuestion,
  EmergencyButton,
  EmergencyBottomText,
  PhoneIcon,
  MessageIcon,
} from "./styles";

const SafetyMenu = (props) => {

  const susie = useStore(state => state.profile);
  const updateMsg = useStore(state => state.updateMsg);
  const profileId = useStore(state => state.profileId);

  const [page, setPage] = useState("EmergencyOrContact");
  const [stage, setStage] = useState(1);

  const saveBtnhandleClick = () => {
    //declare a new object with ref values from inputs on page
    const newMsg = {
      topic: topicRef.current.value,
      msg: msgRef.current.value,
    };

    submitContactMarvin(newMsg, susie, profileId, updateMsg);
  };

  const topicRef = useRef();
  const msgRef = useRef();

  // Detects clicks outside of the ref passed.
  // Ref should be attached to the outermost container.
  function useOutsideClickDetector(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.closeMenu();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const EmergencyOrContact = (props) => {
    const wrapperRef = useRef();
    useOutsideClickDetector(wrapperRef);
    return (
      <FirstOuterBox ref={wrapperRef}>
        <FirstContentBox>
          <EmergencyOrContactText onClick={() => setPage("EmergencySupport")}>
            Emergency Support
          </EmergencyOrContactText>
          <AdjustableSpacer height="20px;" />
          <EmergencyOrContactText onClick={() => setPage("Contact")}>
            Contact Marvin
          </EmergencyOrContactText>
        </FirstContentBox>
      </FirstOuterBox>
    );
  };

  const ContactMarvin = (props) => {
    const wrapperRef = useRef();
    useOutsideClickDetector(wrapperRef);
    return (
      <ContactMarvinOuterBox ref={wrapperRef}>
        <ContactMarvinContentBox>
          <AdjustableSpacer height="30px;" />
          <ContactMarvinText> Contact Marvin </ContactMarvinText>
          <AdjustableSpacer height="20px;" />
          <SelectBoxWithLabel
            inputLabel="What is this about?"
            valueArray={[
              "Technical Support",
              "Rescheduling / Cancellations",
              "Therapist Questions",
              "Product Feedback",
              "General Questions",
              "Other",
            ]}
            ref={topicRef}
          />
          <AdjustableSpacer height="20px;" />
          <TextAreaWithLabel
            inputLabel="What would you like to tell us?"
            height="203px;"
            ref={msgRef}
          />
          <AdjustableSpacer height="30px" />
          <SendMessageBtn
            onClick={() => {
              props.closeMenu();
              saveBtnhandleClick();
            }}
          >
            {" "}
            Send Message{" "}
          </SendMessageBtn>
          <AdjustableSpacer height="30px" />
        </ContactMarvinContentBox>
      </ContactMarvinOuterBox>
    );
  };

  const EmergencySupport = (props) => {
    const handleEmergencyClick = () => {
      // call emergency hotline
      window.open("tel:+1 888-404-1163");
    };

    const handlePhoneCall = () => {
      //do something
      window.open("tel:+1 617-249-6854");
    };
    const handleTextMessage = () => {
      //do something
      window.open("sms://+1 617-249-6854");
    };

    const wrapperRef = useRef();
    useOutsideClickDetector(wrapperRef);

    return (
      <EmergencySupportOuterBox ref={wrapperRef}>
        <EmergencySupportContent>
          <AdjustableSpacer height="30px;" />
          <EmergencySupportText> Emergency Support </EmergencySupportText>
          <AdjustableSpacer height={stage === 1 ? "20px;" : "43px;"} />
          <EmergencyQuestion>
            {" "}
            {stage === 1
              ? "Are you or someone you know experiencing an emergency or crisis and need immediate help?"
              : stage === 2
              ? "Do you need to talk to someone right now?"
              : "How would you like to talk?"}
          </EmergencyQuestion>
          <AdjustableSpacer
            height={stage === 1 ? "28px;" : stage === 2 ? "42px;" : "25px;"}
          />

          {stage === 1 ? (
            <EmergencyButton onClick={handleEmergencyClick}>
              {" "}
              <PhoneIcon /> Yes, this is an emergency{" "}
            </EmergencyButton>
          ) : stage === 2 ? (
            <EmergencyButton onClick={() => setStage(3)}>
              {" "}
              Yes, I need to talk to someone now{" "}
            </EmergencyButton>
          ) : (
            <EmergencyButton onClick={handlePhoneCall}>
              {" "}
              <PhoneIcon /> <AdjustableSpacer width="10px;" /> Phone Call{" "}
            </EmergencyButton>
          )}

          <AdjustableSpacer
            height={stage === 1 ? "25px;" : stage === 2 ? "25px;" : "18px;"}
          />

          {stage === 1 ? (
            <EmergencyBottomText onClick={() => setStage(2)}>
              {" "}
              No, this is not an emergency{" "}
            </EmergencyBottomText>
          ) : stage === 2 ? (
            <EmergencyBottomText onClick={() => setPage("Contact")}>
              {" "}
              No, I don't need to talk to someone now{" "}
            </EmergencyBottomText>
          ) : (
            <EmergencyButton onClick={handleTextMessage}>
              {" "}
              <MessageIcon /> <AdjustableSpacer width="10px;" /> Text Messaging{" "}
            </EmergencyButton>
          )}
        </EmergencySupportContent>
      </EmergencySupportOuterBox>
    );
  };
  // return ContactMarvin();
  // return EmergencySupport();
  if (page === "EmergencyOrContact") {
    return EmergencyOrContact(props);
  } else if (page === "Contact") {
    return ContactMarvin(props);
  } else {
    return EmergencySupport(props);
  }
};

export default SafetyMenu;
