import React from "react";
import ReactPlayer from "react-player";
import { BG, VideoWrapper } from "./styles";

export const PlayVideo = (props) => {
  return (
    <>
      {props.showVideo ? (
        <BG onClick={props.hideVideo}>
          <VideoWrapper>
            <ReactPlayer
            onClick={(e) => e.stopPropagation()}
              url={
                props.url
                  ? props.url
                  : "images/pages/VideoSession/tumor_growth.mp4"
              }
              height="100%"
              width="100%"
              controls={props.controls ? true : null}
              autoplay={props.autoplay ? true : null}
              muted={props.muted ? true : null}
              playing={true}
            />
          </VideoWrapper>
        </BG>
      ) : null}
    </>
  );
};

export default PlayVideo;
