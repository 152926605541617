import styled from "styled-components";

export const ExercisesList = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

export const GrowBox = styled.div``;

export const PlayButton = styled.button`
  position: absolute;
  width: 65px;
  height: 65px;
  z-index: 2;
  /* background-image: url("images/components/ExerciseCard/PlayButton.svg");
    background-size: contain;
    border-radius: 50px; */
  /* White Elements/Default */

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-shadow: -1.66667px -1.66667px 5px rgba(36, 79, 145, 0.06),
    2.5px 2.5px 10px rgba(40, 81, 104, 0.22),
    inset 0px 0px 0px 0.333333px rgba(255, 255, 255, 0.2);
  border-radius: 100px;
  border: none;

  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  &:hover {
    /* background-image: url("images/components/ExerciseCard/PlayOnHover.svg"); */
    /* White Elements/Desktop White Hover >50 */

    background: linear-gradient(
        338.32deg,
        rgba(255, 255, 255, 0.12) 9.36%,
        rgba(92, 112, 162, 0.12) 87.33%
      ),
      #e3e8f0;
    box-shadow: 0px 6px 14px rgba(68, 79, 108, 0.44),
      inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    /* border-radius: 100px; */
  }
`;

export const PlayIcon = styled.img`
  position: absolute;
  left: 35%;
  top: 30%;
`;

export const VideoBox = styled.div`
  position: relative;
  width: 531px;
  height: 340px;
`;

export const ExerciseVideo = styled.video`
  z-index: 1;
  position: relative;
  border-radius: 30px;
  object-fit: cover;
`;

export const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const DisplayExerciseBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Icon = styled.div`
  width: 21px;
  height: 20px;
  background-image: url("images/components/ExerciseCard/video.svg");
`;

export const PosterImg = styled.img`
  border-radius: 16px;
`;

export const ExerciseType = styled.div`
  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */
  color: #333333;
`;

export const IconTypeRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const ExerciseTitle = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const ExerciseDescr = styled.div`
  /* Mobile/Body/2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.2%;

  /* or 17px */
  letter-spacing: 0.3px;

  /* Gray / 1 */
  color: #333333;

  width: 264px;
`;

export const ExerciseNarratedBy = styled.div`
  /* Mobile/Body/2 */

  font-family: Raleway;
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
  line-height: 123.2%;
  /* or 17px */

  letter-spacing: 0.3px;

  /* Gray / 1 */

  color: #333333;
`;
