// import AdjustableSpacer from "../AdjustableSpacer";
import { useState, useEffect } from "react";
import { SearchContainer, MagnifyingGlass, InputField } from "./styles";

const SearchBar = (props) => {
  const [magGlass, setMagGlass] = useState(
    "images/components/SearchBar/MagnifiyingGlass.svg"
  );
  // const [clicked, toggleClick] = useState(false);
  const clicked = false;
  const [searchVal, setSearchVal] = useState("");

  const handleSearchBarClick = () => {
    // toggleClick(clicked => !clicked);
    setMagGlass(
      clicked ? "" : "images/components/SearchBar/MagnifiyingGlass.svg"
    );
  };

  // updates search bar when new input is entered
  const handleSearchChange = (e) => {
    setSearchVal(e.target.value);
  };

  // calls handleSearch whenever searchVal is updated
  useEffect(() => {
    props.handleSearch(searchVal);
  }, [searchVal]);

  return (
    <SearchContainer>
      {/* <AdjustableSpacer width = "20px"/> */}
      <InputField
        type="search"
        placeholder="Search"
        onClick={handleSearchBarClick}
        value={searchVal}
        onChange={handleSearchChange}
      ></InputField>
      <MagnifyingGlass icon={magGlass} />
    </SearchContainer>
  );
};

export default SearchBar;
