import styled from "styled-components";




export const SessionTimerContainer = styled.div`
  width: 195px;
  height:  54px;
  position: absolute;
  top: 3.7%;
  // left: 0;
  // right: 0;
  z-index: 2;
  display:flex ;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(0, 0, 0, 0.75);
    /* opacity: 0.5; */
    border-radius: 10px;
  }
  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const SessionTimerText= styled.div`
  position: relative;
  width: 155px;
  height: 45px;
  z-index: 2;
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  text-align: center;
  color: #ffffff;
`;

