// introVideoArray[][0] is poster, videoArray[][1] is video
export const introVideoArray = [
  [
    "/images/components/IntroductoryVideos/Intro1.png",
    "sampleVideos/therapistIntroVids/IntroVid1.mp4",
  ],
  [
    "/images/components/IntroductoryVideos/Intro2.png",
    "sampleVideos/therapistIntroVids/IntroVid2.mp4",
  ],
  [
    "/images/components/IntroductoryVideos/Intro3.png",
    "sampleVideos/therapistIntroVids/IntroVid3.mp4",
  ],
];

export default introVideoArray;
