import { ChatMsgContainer, MsgBox, Time } from "./styles.js";
import React from "react";

import AdjustableSpacer from "../AdjustableSpacer";

const ChatMsgRightWithShadow = (props) => {
  const isValidUrl = urlString =>{
    var inputElement = document.createElement('input');
    inputElement.type = 'url';
    inputElement.value = urlString;

    if (!inputElement.checkValidity()) {
      return false;
    } else {
      return true;
    }
  } 
  console.log(`validURL`, isValidUrl(props.msg))
  
  return (
    <ChatMsgContainer>
      <AdjustableSpacer height="16px" />
      <MsgBox>{props.msg}</MsgBox>
      <AdjustableSpacer height="8px" />
      <Time>
        {props.name} · {props.time}
      </Time>
    </ChatMsgContainer>
  );
};

export default ChatMsgRightWithShadow;
