import styled from "styled-components";

export const OuterBox = styled.div`
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White/Up - Height >111 */

  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;

  width: 369px;
  height: 205px;
  @media (min-width: 1599px) {
    width: 450px;
  }
`;

export const Body = styled.div`
  padding: 25px;
`;

export const Videos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const VideoCard = styled.div`
  width: 85px;
  height: 120px;

  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  /* border: solid red 1px; */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    cursor: pointer;
    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #e3e8f0;
  }
`;

export const CircleContainer = styled.div`
  width: 58px;
  height: 58px;

  /* Gray / Background */

  background: #f6f6f6;
  box-shadow: -6px -5px 10px #ffffff, 3px 5px 12px rgba(202, 208, 223, 0.85);

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
`;
export const ColorfulCircle = styled.img`
  width: 54px;
  height: 54px;
  border-radius: 50%;
`;

export const CardText = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */

  color: #333333;
`;

export const Heading = styled.div`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */

  color: #333333;
`;
