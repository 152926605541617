import AdjustableSpacer from "../AdjustableSpacer";
import { useState } from "react";
import { useRef, useEffect } from "react";
import moment from "moment";

import {
  SessionBox,
  DateCircle,
  Day,
  Details,
  Date,
  Time,
  DateTimeBox,
  SessionSettings,
  SettingsBox,
  SettingsBoxMargins,
  Reschedule,
  Cancel,
  MarginBox,
  Content,
  ThreeDots,
  Box,
} from "./styles";

const Session = (props) => {
  // const Setting
  const [settingsOpen, openSettings] = useState(false);

  const handleSettings = () => {
    openSettings((settingsOpen) => !settingsOpen);
  };
  // Detects clicks outside of the ref passed.
  // Ref should be attached to the outermost container.
  function useOutsideClickDetector(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          openSettings((prev) => !prev);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = useRef();

  useOutsideClickDetector(wrapperRef);



  const day = moment(props.sessionObj.sessionDate).format("D");
  const month = moment(props.sessionObj.sessionDate).format("MMMM");
  const hour = moment(props.sessionObj.sessionDate).format("h");
  const minute = moment(props.sessionObj.sessionDate).format("mm");
  const dayCycle = moment(props.sessionObj.sessionDate).format("A");
  const dayOfWeek = moment(props.sessionObj.sessionDate).format("dddd");

  return (
    <MarginBox>
      <AdjustableSpacer width="20px" />
      <Content>
        <AdjustableSpacer height="15px" />
        <SessionBox>
          <DateTimeBox>
            <DateCircle>
              <Day>{day}</Day>
            </DateCircle>
            <AdjustableSpacer width="23px" />
            <Details>
              <Date>
                {" "}
                {dayOfWeek}, {month} {day}
              </Date>
              <Time>
                {" "}
                {hour}:{minute} {dayCycle}{" "}
              </Time>
            </Details>
          </DateTimeBox>
          {/* <AdjustableSpacer width = "135px"/> */}
          <Box>
            <SessionSettings onClick={handleSettings}>
              <ThreeDots> </ThreeDots>
            </SessionSettings>
            {settingsOpen && (
              <SettingsBox ref={wrapperRef}>
                <AdjustableSpacer width="17px" />
                <SettingsBoxMargins>
                  <Reschedule onClick={props.openRescheduleBox}>
                    Reschedule
                  </Reschedule>
                  <Cancel onClick={props.openCancelBox}>Cancel</Cancel>
                </SettingsBoxMargins>
                <AdjustableSpacer width="17px" />
              </SettingsBox>
            )}
          </Box>
        </SessionBox>
      </Content>
    </MarginBox>
  );
};

export default Session;
