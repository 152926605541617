// export const exerciseCardSample1 = {
//     topPic: "images/components/ExerciseCard/artOfVisualization.svg",
//     icon: "images/components/ExerciseCard/video.svg",
//     exerciseDetails: "Visualization video • 5 min.",
//     exerciseName: "The art of visualization",
//     exerciseDescription: "One of the most important skills of a mindfulness practice, teaching yourself to visualize your peak performance. ",

//     poster: "images/components/ExerciseCard/VidBG.svg",
//     video: "images/pages/VideoSession/tumor_growth.mp4",
//     exerciseType: "Visualization video",
//     exerciseTitle: "The art of visualization",
//     exerciseDescr: "Learn the art of visualization, the most important skill to achieve your peak performance. See yourself competing at the highest level, absorbing the emotions and becoming one with your thoughts, words, and actions.",
//     narrated: "Narrated by: Diana McNab.",

// };

// export const exerciseCardSample2 = {
//     topPic: "images/components/ExerciseCard/powerAnimal.svg",
//     icon: "images/components/ExerciseCard/video.svg",
//     exerciseDetails: "Visualization video • 8 min.",
//     exerciseName: "Find your power animal",
//     exerciseDescription: "Learn to slow down and listen to your inner voice, digging deep into your sub-conscious to find your inner power animal. ",

//     poster: "images/components/ExerciseCard/VidBG.svg",
//     video: "images/pages/VideoSession/centraldogma.mp4",
//     exerciseType: "Visualization video",
//     exerciseTitle: "Find your power animal Test 2",
//     exerciseDescr: "Test 2",
//     narrated: "Narrated by Test 2.",
// };

// export const exerciseCardSample3 = {
//     topPic: "images/components/ExerciseCard/GuidedBeach.svg",
//     icon: "images/components/ExerciseCard/video.svg",
//     exerciseDetails: "Visualization video • 6 min.",
//     exerciseName: "Guided beach",
//     exerciseDescription: "A beach visualization exercise, used in guided imagery and contemplative practices, to teach mental relaxation.",

//     poster: "images/components/ExerciseCard/VidBG.svg",
//     video: "public/images/components/ExerciseVideos/Meditation_Techniques.m4v",
//     exerciseType: "Visualization video",
//     exerciseTitle: "Guided beach Test 3",
//     exerciseDescr: "Test 3",
//     narrated: "Narrated by Test 3.",
// };

export const exercise1 = {
  topPic: "/images/components/ExerciseCard/thumbnails/BeachRelaxation.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 6 min.",
  exerciseName: "Beach relaxation",
  exerciseDescription:
    "Find your beach in this brilliant visualization, creating a picture in your mind of your perfect island paradise.",

  poster: "images/components/ExerciseCard/posters/BeachRelaxationBig.png",
  video: "https://youtu.be/gShJaXsyLck", // Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "Beach relaxation",
  exerciseDescr:
    "Visualization creates a picture in your mind that your body believes. In this stunning exerice, see yourself in an island paradise with glorious views, warm water, and white sand underneath your toes. Feel calm, free, and imagine the great mysteries and treasures that await.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise2 = {
  topPic: "/images/components/ExerciseCard/thumbnails/CabinInTheWoods.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 6 min.",
  exerciseName: "Cabin in the woods visualization",
  exerciseDescription:
    "Enter a beautiful cabin in the woods, picturing your own forest and what it intends to tell you.",

  poster: "images/components/ExerciseCard/posters/CabinInTheWoodsBig.png",
  video: "https://youtu.be/xqdYLv7DSPI",
  exerciseType: "Visualization video",
  exerciseTitle: "Cabin in the woods visualization",
  exerciseDescr:
    "Practice the skill of visualization with this guided exercise, picturing yourself in your favorite cabin on a snowy day in the woods. See yourself talking to a friend or a mentor by the fire, learning about what your inner voice intends to tell you.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise3 = {
  topPic: "/images/components/ExerciseCard/thumbnails/FindYourPowerAnimal.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 8 min.",
  exerciseName: "Finding your inner power animal",
  exerciseDescription:
    "Learn to slow down, relax your mind, and listen to your inner voice, digging deep into your sub-conscious to find your inner power animal. ",

  poster: "images/components/ExerciseCard/posters/PowerAnimalBig.png",
  video: "https://youtu.be/fiTFXizZBCY", // Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "Finding your inner power animal",
  exerciseDescr:
    "In this visualization, you'll follow a deep path into the woods, making your way through a forest and seeking out your inner power animal. Led by Diana McNab, you'll learn to listen to your sub-conscious and find your inner strengths, learning the messages that mind, body, and spirit want to teach you.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise4 = {
  topPic: "/images/components/ExerciseCard/thumbnails/GuidedBeach.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 6 min.",
  exerciseName: "Guided beach visualization",
  exerciseDescription:
    "Enter a beach relaxation, visualizing yourself in your best state, filmed live in Los Angeles.",

  poster: "images/components/ExerciseCard/posters/GuidedBeachBig.png",
  video: "https://www.youtube.com/watch?v=zI5LFJKsonY", // Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "Guided beach visualization",
  exerciseDescr:
    "Visualize yourself on a beautiful beach, soaring amongst the birds and swooping down to the white sand. In this visualization filmed live in Los Angeles, you'll discover the white beaches of the West Coast, guiding yourself to true mental relaxation.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise5 = {
  topPic: "/images/components/ExerciseCard/thumbnails/GuidedForest.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 8 min.",
  exerciseName: "Guided forest visualization",
  exerciseDescription:
    "Learn to visualize at the end of the world, filmed within the beauty of San Francisco. ",

  poster: "images/components/ExerciseCard/posters/GuidedForestBig.png",
  video: "https://www.youtube.com/watch?v=nAvsOvocSAc&t", // Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "Guided forest visualization",
  exerciseDescr:
    "Learn to visualize using guided imagery, teaching yourself mental relaxation. In this beautiful video filmed at Land's End Park in San Francisco, see yourself flying amongst the trees and soaring above the ocean. You'll find your inner strength and enter a world within your own mind. ",
  narrated: "Narrated by John Bracaglia.",
};

export const exercise6 = {
  topPic: "/images/components/ExerciseCard/thumbnails/TheZone.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 5 min.",
  exerciseName: "How to enter 'the zone'",
  exerciseDescription:
    "What is 'the zone' and how do I get there? Learn the secret strategies of peak performance, becoming one with the world in your zone of concentration.",

  poster: "images/components/ExerciseCard/posters/TheZoneBig.png",
  video: "https://youtu.be/Fr3uOhB2ldc", //temp
  exerciseType: "Visualization video",
  exerciseTitle: "How to enter 'the zone'",
  exerciseDescr:
    "What is 'the zone'? It's the elusive feeling that you get when your mind and body come together to feel invincible, calm, cool and grounded, confident in yourself and ready to perform at your full potential. Learn to enter this amazing state, focusing on the here-and-now and optimizing yourself for peak performance.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise7 = {
  topPic: "/images/components/ExerciseCard/thumbnails/OvercomingAnxiety.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 5 min.",
  exerciseName: "Overcoming anxiety",
  exerciseDescription:
    "Learn to beat your anxiety and overcome worries about the future, all through the power of your body, mind, and emotions. ",

  poster: "images/components/ExerciseCard/posters/OvercomingAnxietyBig.png",
  video: "https://www.youtube.com/watch?v=K_Iwrw-RGLY", //Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "Overcoming anxiety",
  exerciseDescr:
    "You feel anxious when your thoughts are clinging and grasping onto future outcomes and end results. In this excellent program, learn to be in the moment and bypass any challenges, overcoming fear with the power of self-love.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise8 = {
  topPic:
    "/images/components/ExerciseCard/thumbnails/RisingUpAfterABigFall.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 5 min.",
  exerciseName: "Rising up after a big fall",
  exerciseDescription:
    "Winning and losing are all parts of the mystery of life. Learn how to thrive after a big fall and re-find your inner champion. ",

  poster: "images/components/ExerciseCard/posters/RisingUpAfterABigFallBig.png",
  video: "https://youtu.be/8DsOMmxAPrU", //temp
  exerciseType: "Visualization video",
  exerciseTitle: "Rising up after a big fall",
  exerciseDescr:
    "You have to feel the pain and humility of failing before you can truly understand and enjoy the joy of winning. Can you face the challenges of your past? In this visualization led by Diana McNab, learn to forgive and let go, moving forward with a new strength and even greater sense of purpose after a big fall.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise9 = {
  topPic:
    "/images/components/ExerciseCard/thumbnails/SecretAtticMeditation.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 7 min.",
  exerciseName: "Secret attic meditation",
  exerciseDescription:
    "Enter your own secret attic that you've designed all for yourself, answering questions about how you can manifest your dreams and goals.",

  poster: "images/components/ExerciseCard/posters/SecretAtticMeditationBig.png",
  video: "https://youtu.be/ULs3yZVVtJc", // Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "Secret attic meditation",
  exerciseDescr:
    "Go on a journey to your own secret attic in this visualization exercise, observing the beauty of its structure and the intricacies of its design. You'll learn to manifest your dreams and goals, observing your inner voice and answering the questions that only you know the answers to.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise10 = {
  topPic: "/images/components/ExerciseCard/thumbnails/ArtOfVisualization.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 6 min.",
  exerciseName: "The art of visualization",
  exerciseDescription:
    "Learn one of the most important skills of a mindfulness-based practice, teaching yourself to visualize your peak performance. ",

  poster: "images/components/ExerciseCard/posters/TheArtOfVisualizationBig.png",
  video: "https://youtu.be/RxLkpA6D_ro", //Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "The art of visualization",
  exerciseDescr:
    "Learn the art of visualization, the most important skill to achieve your peak performance. See yourself competing at the highest level, absorbing the emotions and becoming one with your thoughts, words, and actions.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise11 = {
  topPic: "/images/components/ExerciseCard/thumbnails/ThePowerOfBeliefs.png",
  icon: "/images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 6 min.",
  exerciseName: "The power of beliefs",
  exerciseDescription:
    "Everyone thinks that if you change your thoughts, you change your life. But what gives thoughts power? It's your beliefs!",

  poster: "/images/components/ExerciseCard/posters/ThePowerOfBeliefsBig.png",
  video: "https://www.youtube.com/watch?v=XanDM1OAWgU",
  exerciseType: "Visualization video",
  exerciseTitle: "The power of beliefs",
  exerciseDescr:
    "What drives your beliefs and value system? Learn to work with these systems, optimizing your beliefs to ensure a successful and happy life.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise12 = {
  topPic:
    "/images/components/ExerciseCard/thumbnails/ThePowerOfGoalSetting.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 6 min.",
  exerciseName: "The power of goal setting",
  exerciseDescription:
    "Can you see yourself winning? Learn to use goal-setting to manifest your dreams and achieve your life's greatest accomplishments. ",

  poster: "images/components/ExerciseCard/posters/ThePowerOfGoalSettingBig.png",
  video: "https://youtu.be/zgtMW0o11HI", //Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "The power of goal setting",
  exerciseDescr:
    "Your brain loves a job, and goal setting is all about giving yourself something to achieve. In this visualization by Diana McNab, learn to see your desired result, feel good about how you will achieve it, and visualize the steps you need to get it done. Can you see yourself winning?",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise13 = {
  topPic: "/images/components/ExerciseCard/thumbnails/MeditationTechniques.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 7 min.",
  exerciseName: "The top 5 meditation techniques",
  exerciseDescription:
    "Introduce yourself to a sample of short meditations, quieting your mind, and emptying your thoughts of disturbances and anxieties.",

  poster: "images/components/ExerciseCard/posters/MeditationTechniquesBig.png",
  video: "https://youtu.be/M_WToiTev00", // Correct link
  exerciseType: "Visualization video",
  exerciseTitle: "The top 5 meditation techniques",
  exerciseDescr:
    "Meditation is when you empty your mind of all thoughts, disturbances, anxieties, and frustrations. Learn the process of quieting your mind through a few simple techniques, including the Blue sky visualization, Watching the waves, the Counting technique, Vipassana, and the Mantra.",
  narrated: "Narrated by: Diana McNab.",
};

export const exercise14 = {
  topPic: "/images/components/ExerciseCard/thumbnails/ThePowerOfSelfCare.png",
  icon: "images/components/ExerciseCard/video.svg",
  exerciseDetails: "Visualization video • 9 min.",
  exerciseName: "The power of self-care",
  exerciseDescription:
    "The most important relationship that you will have in your life is your relationship with yourself.",

  poster: "/images/components/ExerciseCard/posters/ThePowerOfSelfCareBig.png",
  video: "https://www.youtube.com/watch?v=xEEI8YBO9Tk",
  exerciseType: "Visualization Video",
  exerciseTitle: "The power of self-care",
  exerciseDescr: `Learn to ask questions like "who am I?", "what do I want?", and "how do I get there?" Take a moment to focus on your perceptions and priorities, shifting to look deeply into your own personal philosophies.`,
  narrated: "Narrated by: Diana McNab.",
};

// export const exercise = {
//     topPic: "",
//     icon: "",
//     exerciseDetails: "",
//     exerciseName: "",
//     exerciseDescription: "",

//     poster: "",
//     video: "",
//     exerciseType: "",
//     exerciseTitle: "",
//     exerciseDescr: "",
//     narrated: "",
// };
// export const exercise = {
//     topPic: "",
//     icon: "",
//     exerciseDetails: "",
//     exerciseName: "",
//     exerciseDescription: "",

//     poster: "",
//     video: "",
//     exerciseType: "",
//     exerciseTitle: "",
//     exerciseDescr: "",
//     narrated: "",
// };
export const exerciseCardArray = [
  exercise14,
  exercise13,
  exercise12,
  exercise11,
  exercise10,
  exercise9,
  exercise8,
  exercise7,
  exercise6,
  exercise5,
  exercise4,
  exercise3,
  exercise2,
  exercise1,
];

export default exerciseCardArray;
