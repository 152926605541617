import styled from "styled-components";

export const FirstOuterBox = styled.div`
  position: absolute;
  right: 0px;
  top: 50px;
  width: 117px;
  height: 92px;
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  box-shadow: -7px -7px 14px rgba(255, 255, 255, 0.1),
    5px 5px 25px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FirstContentBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

export const EmergencyOrContactText = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-left: 18px;

  color: #a2aecb;
  opacity: 0.9;

  &:hover {
    color: #333333;
    cursor: pointer;
  }
`;
