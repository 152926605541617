const lookupDescription = (type) => {
    let lowerType = type.toLowerCase();
    let correspondingDesc = {
        "cbt" : "Cognitive behavioral therapy is a psycho-social intervention that aims to improve mental health. CBT focuses on challenging and changing cognitive distortions and behaviors, improving emotional regulation, and the development of personal coping strategies that target solving current problems.",
        "tf-cbt": "Trauma-focused cognitive behavioral therapy is an evidence-based treatment for people impacted by trauma. Research shows that TF-CBT successfully resolves a broad array of emotional and behavioral difficulties associated with single, multiple and complex trauma experiences.",
        "act": "ACT develops psychological flexibility and is a form of behavioral therapy that combines mindfulness skills with the practice of self-acceptance. When aiming to be more accepting of your thoughts and feelings, commitment plays a key role. In the case of ACT, you commit to facing the problem head-on rather than avoiding your stresses.",
        "affirmative psychotherapy": "Affirmative psychotherapy is an approach to therapy that embraces a positive view of Lesbian, Gay, Bisexual, Transgender, and Queer (LGBTQ) identities and relationships and addresses the negative influences that homophobia, transphobia, and heterosexism have on the lives of LGBTQ clients.",
        "couples therapy": "Couples therapy is a short-term counseling program designed to help couples understand and resolve problems, dissatisfaction, and conflict in their relationship.",
        "dbt": "DBT is a form of Cognitive Behavioral Therapy that combines standard cognitive-behavioral techniques for emotion regulation and reality-testing with concepts of distress tolerance, acceptance, and mindful awareness largely derived from contemplative meditative practice",
        "emdr": "Eye Movement Desensitization and Reprocessing (EMDR) is a psychotherapy treatment that was originally designed to alleviate the distress associated with traumatic memories. It facilitates the accessing and processing of traumatic memories and other adverse life experience to bring these to an adaptive resolution.",
        "emotion-focused therapy": "Emotion-focused therapy (EFT) is a therapeutic approach based on the premise that emotions are key to identity. According to EFT, emotions are also a guide for individual choice and decision making. This type of therapy assumes that lacking emotional awareness or avoiding unpleasant emotions can cause harm. It may render us unable to use the important information emotions provide.",
        "executive coaching": "Executive Coaching is an inquiry-based approach to personal and professional development that is aimed at creating awareness, generating action, and facilitating learning and growth. It focuses on improving performance by helping individuals to develop and sustain new perspectives, attitudes, skills and behaviors.",
        "existential therapy": "Existential therapy tries to help people find meaning and purpose in their lives. It seeks to end the fear of the unknown. A therapist actively encourages patients to use their capacity to make choices and to develop their lives as a way to maximize their existence, or their reason for being.",
        "family therapy": "Family therapy is a type of psychotherapy designed to identify family patterns that contribute to a behavior disorder or mental illness and help family members break those habits. Family therapy involves discussion and problem-solving sessions with the family. Some of these sessions may be as a group, in couples, or one on one. In family therapy, the web of interpersonal relationships is examined and, ideally, communication is strengthened within the family.",
        "feminist therapy": "Feminist therapy is an integrative approach to psychotherapy that focuses on gender and the particular challenges and stressors that women face as a result of bias, stereotyping, oppression, discrimination, and other factors that threaten their mental health. The therapeutic relationship, based on an authentic connection and equality between the therapist and the client, helps empower clients to understand the social factors that contribute to their issues, discover and claim their unique identity, and build on personal strengths to better their own lives and those of others.",
        "gestalt therapy": "Gestalt therapy is a humanistic, holistic, person-centered form of psychotherapy that is focused on a person's present life and challenges rather than delving into past experiences. This approach stresses the importance of understanding the context of a person’s life and taking responsibility rather than placing blame.",
        "grief counseling": "Grief counseling, or bereavement counseling, is designed to help people cope with the loss of a loved one. A grief counselor can help you develop methods and strategies for coping with your loss and grief. Grief counseling provides bereaved people with an avenue to discuss their feelings and emotions, helping them discover ways to ease the grieving process.",
        "group therapy": "Group psychotherapy is a form of therapy in which a small number of people meet together to help themselves and one another. Many groups are designed to target a specific problem, such as depression, obesity, panic disorder, social anxiety, chronic pain or substance abuse. Other groups focus more generally on improving social skills, helping people deal with a range of issues such as anger, shyness, loneliness and low self-esteem. ",
        "cbt-i": "CBT-I focuses on exploring the connection between the way we think, the things we do, and how we sleep. Thoughts and feelings about sleep are examined and tested to see if they’re accurate, while behaviors are examined to determine if they promote sleep",
        "istdp": "Intensive Short-Term Dynamic Psychotherapy (ISTDP) is a form of therapy that focuses on enabling the patient to overcome his or her own internal struggle with his or her own feelings and emotions about past and present experiences that overwhelm him or her due to their frightening, threatening, or painful nature. This form of therapy is categorized as intensive therapy in that it addresses these hidden emotions and exposes the client to them to the fullest extent in the shortest time possible. This therapy is seen as dynamic because it employs the unconscious as an ally in the internal struggle and the transference of emotional conflict and upheaval.",
        "mbsr": "Mindfulness-based stress reduction (MBSR) is an eight-week evidence-based program that offers secular, intensive mindfulness training to assist people with stress, anxiety, depression and pain. MBSR uses a combination of mindfulness meditation, body awareness, yoga and exploration of patterns of behaviour, thinking, feeling and action.",
        "mindfulness-based cognitive therapy": "Mindfulness-based cognitive therapy builds upon the principles of cognitive therapy by using techniques such as mindfulness meditation to teach people to consciously pay attention to their thoughts and feelings without placing any judgments upon them.",
        "motivational interviewing": "Motivational interviewing is a counseling method that helps people resolve ambivalent feelings and insecurities to find the internal motivation they need to change their behavior. It is a practical, empathetic, and short-term process that takes into consideration how difficult it is to make life changes.",
        "multicultural counseling": "Multicultural counseling is a specific type of counseling practice that acknowledges how various aspects of a patient’s cultural identity influence their mental health. Relevant factors can include issues of race, ethnicity, immigration status, religion, socioeconomic background, and gender identity. ",
        "narrative therapy": "Narrative therapy is a style of therapy that helps people become—and embrace being—an expert in their own lives. In narrative therapy, there is an emphasis on the stories that you develop and carry with you through your life. As you experience events and interactions, you give meaning to those experiences and they, in turn, influence how you see yourself and the world. ",
        "person-centered": "Person-centered therapy is a nondirective, empathic approach that empowers and motivates the client in the therapeutic process. The therapy is based on the belief that every human being strives for and has the capacity to fulfill his or her own potentia",
        "psychodynamic therapy": "Psychodynamic therapy is a talk therapy approach that embraces the multifaceted aspects of an individual’s life. It strives to help people understand the sometimes unknown or unconscious motivations behind difficult feelings and behaviors. In turn, having this insight can lead to symptom relief, help people feel better, and allow them to make better choices.",
        "religious therapy": "Religious therapy is a type of therapy often supplied by a pastor, minister, or other religious leader which includes scriptural study, counseling, or community activities sponsored by their religious institution.",
        "schema therapy": "Schema therapy can help individuals identify the thought and behavior patterns underlying and perpetuating mental health conditions. The treatment approach integrates elements from cognitive behavioral therapy, attachment theory, and a number of other approaches, expanding on CBT through exploration of emotions, maladaptive coping methods, and the origin of mental health concerns. ",
        "solution-focused therapy": "Solution-focused therapy places focus on a person's present and future circumstances and goals rather than past experiences. In this goal-oriented therapy, the symptoms or issues bringing a person to therapy are typically not targeted.",
        "substance abuse counseling": "Substance abuse counseling is a support system for people with drug and alcohol problems, eating disorders and other behavioral issues. It teaches individuals how to modify their behavior with the intention of full recovery.",
        "trauma-focused cbt": "Trauma-focused cognitive behavioral therapy is an evidence-based treatment for people impacted by trauma. Research shows that TF-CBT successfully resolves a broad array of emotional and behavioral difficulties associated with single, multiple and complex trauma experiences",
        "": "",
    }

    return correspondingDesc[lowerType];
}

export default lookupDescription;