import React, { useState, useRef } from "react";


//Components
import AdjustableSpacer from "../AdjustableSpacer";
import InputBoxWithLabel from "../InputBoxWithLabel";
import SelectBoxWithLabel from "../SelectBoxWithLabel";
import ChipInput from "../ChipInput";


//Data
import raceAndEthnicCategoryList from "../../data/people/RacialEthnicCategories";
import genderIdentitiesList from "../../data/people/GenderIdentities";
import pronounList from "../../data/people/Pronouns";

import encodeBase64String from "../../utils/MiscHelpers/encodeBase64String";

import {
  OuterBox,
  Body,
  ProfileText,
  ProfileHeader,
  Photo,
  ChangePhotoInput,
  ChangePhotoBtn,
  RemovePhotoBtn,
  Content,
  Row,
  InputContainer,
  RaceContainer,
  SaveChangesBtn,
} from "./styles.js";



const SettingsCard = (props) => {
  const defaultProfilePicture = "/images/components/Settings/Blank_Profile_Picture.svg"

  const raceRef = useRef();
  const [changesMade, setChangesMade] = useState(false);
  const [profileInfo, setProfileInfo] = useState({
    profilePicture: props.profilePicture,
    firstName: props.firstName,
    lastName: props.lastName,
    genderIdentity: props.genderIdentity,
    pronouns: props.pronouns,
    race: props.race,
    jobTitle: props.jobTitle,
    department: props.department,
  });

  const handleChange = (event) => {
    setChangesMade(true);
    const { name, value } = event.target;
    setProfileInfo((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  // Accepts a new photo (base64 string), sets the profileInfo's profilePicture to it
  const updateProfilePicture = (base64Photo) => {
    setProfileInfo((prevState) => {
      return {
        ...prevState,
        profilePicture: base64Photo,
      };
    });
  }

  // Saves the changes to profile via props
  const saveChanges = () => {
    profileInfo.race = raceRef.current.state.value;
    props.handleChangesMade(profileInfo);
    setChangesMade(false);
  };

  // Workaround to allow upload button to work
  const fileElemRef = useRef();
  const uploadPhoto = () => {
    if (fileElemRef) {
      fileElemRef.current.click();
    }
  };

  // Anytime a file is updated: encode it, set the profile picture, then enable the save button
  const onFileChange = (e) => {
    // encodeBase64String accepts a callback because it's asynchronous, callback performs operations with the image
    encodeBase64String(e.target.files[0], (base64String) => {
      updateProfilePicture(base64String);
      setChangesMade(true);
    })
  };
  
  // Removes profilepicture, enables the save button
  const removePhoto = () => {
    setChangesMade(true);
    updateProfilePicture("");
  };
  
  // Extracts variables to avoid using profileInfo.var
  const {
    profilePicture,
    firstName,
    lastName,
    genderIdentity,
    pronouns,
    race,
    jobTitle,
    department,
  } = profileInfo;
  
  return (
    <OuterBox>
      <Content>
        <ProfileText> Profile </ProfileText>
        <Body>
          <ProfileHeader>
            <Photo
              src={profilePicture ? profilePicture : defaultProfilePicture}
            />
            <AdjustableSpacer width="30px" />
            <ChangePhotoBtn
              onClick={() => {
                uploadPhoto();
              }}
            >
              Change photo
            </ChangePhotoBtn>
            <ChangePhotoInput
              type="file"
              accept="image/*"
              ref={fileElemRef}
              onChange={onFileChange}
            />
            <AdjustableSpacer width="20px" />
            <RemovePhotoBtn
              onClick={() => {
                removePhoto();
              }}
            >
              Remove photo
            </RemovePhotoBtn>
          </ProfileHeader>

          <AdjustableSpacer height="20px" />
          <Row>
            <InputContainer>
              <InputBoxWithLabel
                inputLabel="First name"
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
            </InputContainer>
            <InputContainer>
              <InputBoxWithLabel
                inputLabel="Last name"
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
            </InputContainer>
          </Row>
          <AdjustableSpacer height="30px" />
          <Row>
            <InputContainer>
              <SelectBoxWithLabel
                inputLabel="What is your gender identity?"
                valueArray={genderIdentitiesList}
                name="genderIdentity"
                value={genderIdentity}
                onChange={handleChange}
              />
            </InputContainer>
            <InputContainer>
              <SelectBoxWithLabel
                inputLabel="What are your pronouns?"
                name="pronouns"
                valueArray={pronounList}
                value={pronouns}
                onChange={handleChange}
              />
            </InputContainer>
          </Row>
          <AdjustableSpacer height="30px" />
          <Row>
            <RaceContainer>
              <ChipInput
                labelText="What is your race or ethnic identity?"
                optionList={raceAndEthnicCategoryList}
                defaultValue={race} // race = [{name: "Asian", label: "Asian"}, {name: "White", label: "White"}]
                ref={raceRef}
                onChange={() => setChangesMade(true)}
              />
            </RaceContainer>
          </Row>
          <AdjustableSpacer height="30px" />
          <Row>
            <InputContainer>
              <InputBoxWithLabel
                inputLabel="Job title"
                name="jobTitle"
                value={jobTitle}
                onChange={handleChange}
              />
            </InputContainer>
            <InputContainer>
              <InputBoxWithLabel
                inputLabel="Department"
                name="department"
                value={department}
                onChange={handleChange}
              />
            </InputContainer>
          </Row>
          <AdjustableSpacer height="20px" />
          <SaveChangesBtn
            changesMade={changesMade}
            onClick={saveChanges}
            disabled={!changesMade}
          >
            {" "}
            Save changes{" "}
          </SaveChangesBtn>
        </Body>
      </Content>
    </OuterBox>
  );
};

export default SettingsCard;
