import styled from "styled-components";

export const DeactivateBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 450px;
  height: 218px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Up - Height >111 */
  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;
`;

export const DeactTitle = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const DeactBody = styled.div`
  /* Desktop / Body / 2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 1 */
  color: #333333;
  width: 360px;
  word-wrap: break-word;
`;

export const DeactBtn = styled.div`
  /* Red Button / Default */

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 14px 49px; */
  width: 168px;
  height: 39px;
  /* padding: 8px 24px; */

  background: linear-gradient(218.09deg, #fa9b94 -20.54%, #e15160 113.32%);
  box-shadow: -5px -5px 10px #ffffff, 3px 3px 12px rgba(233, 121, 121, 0.7);
  border-radius: 75px;
  &:hover {
    cursor: pointer;
    background: linear-gradient(218.09deg, #fa9b94 -20.54%, #e15160 63.57%);
    box-shadow: -5px -5px 10px #ffffff, 3px 3px 12px rgba(233, 121, 121, 0.78);
  }
`;

export const DeactBtnTxt = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;
  color: #fefefe;
  mix-blend-mode: normal;
`;
