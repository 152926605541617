import styled from "styled-components";

export const SearchContainer = styled.div`
  position: relative;
  /* Gray / Background */
  background: #f6f6f6;

  /* Stroke / White Element */
  border: 0.5px solid rgba(202, 208, 223, 0.25);
  box-sizing: border-box;

  /* Desktop White/Down - Input Field */
  box-shadow: inset -2.5px -2.5px 6px 1px #ffffff,
    inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 290px;
  height: 40px;
`;

export const MagnifyingGlass = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${(props) => props.icon});
  background-size: cover;
  background-repeat: no-repeat;
  /* border: 0.5px solid rgba(202, 208, 223, 0.25);
    box-sizing: border-box;     
    box-shadow: inset -2.5px -2.5px 6px 1px #FFFFFF, inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5); 
     */
  position: absolute;
  left: 20px;
  z-index: 1;
`;

export const InputField = styled.input`
  /* Gray / Background */
  background: #f6f6f6;

  /* Stroke / White Element*/
  border: 0.5px solid rgba(202, 208, 223, 0.25);
  box-sizing: border-box;

  /* Desktop White/Down - Input Field */
  box-shadow: inset -2.5px -2.5px 6px 1px #ffffff,
    inset 2.5px 2.5px 8px 1px rgba(202, 208, 223, 0.5);
  border-radius: 12px;
  width: 290px;
  height: 40px;
  padding: 12px 12px 12px 48px;
  position: relative;

  /* Desktop / Body / Input Field */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  &:focus {
    outline: none;
    border: solid #618cf1 1px;
  }
`;
