import styled from "styled-components";

export const Spacer = styled.div`
  @media screen and (max-width: 768px){
      
    height: ${(props) => (props.mobileHeight ? props.mobileHeight : props.height ? props.height : "0px")};
        
    }
  
  height: ${(props) => (props.height ? props.height : "0px")};
  width: ${(props) => (props.width ? props.width : "0px")};
`;
