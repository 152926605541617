import {
  ChatBoxContainer,
  CBTitle,
  CloseImg,
  TypeBox,
  SendBtn,
  Header,
  Body,
  TypeBoxContainer,
  Footer,
} from "./styles.js";
import { useEffect } from "react";

import AdjustableSpacer from "../AdjustableSpacer";
import ChatMsgRight from "../ChatMsgRight";
import ChatMsgLeft from "../ChatMsgLeft";
import { useRef } from "react";
import moment from "moment";

const ChatBox = (props) => {
  useEffect(() => {
    const chatWindow = document.getElementById("chat-window");
    if (chatWindow) chatWindow.scrollTop = chatWindow.scrollHeight;
  }, [props.chatArray, props.showChatBox]);
  const typeBoxRef = useRef();
  const btnRef = useRef();
  const handleSendMessage = () => {
    let msg = JSON.stringify({
      msgTxt: typeBoxRef.current.value,
      meetingName: props.meetingName,
      time: moment(),
    });

    props.sendMessage(msg);
    typeBoxRef.current.value = "";
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // prevents 'enter' keypress from creating a newline
      btnRef.current.click();
      console.log(`here is the current value in the ref on enter`);
      console.log(typeBoxRef.current.value);
    }
  };

  console.log(`props.chatArray`, props.chatArray)
  return (
    <>
      {props.showChatBox ? (
        <ChatBoxContainer>
          <Header>
            <AdjustableSpacer height="18px" />
            <CBTitle>Chat</CBTitle>
            <CloseImg
              onClick={props.closeChatBox}
              src="/images/components/ChatBox/Close Chat.svg"
              alt="CloseImg"
            />
            <AdjustableSpacer height="25px" />
          </Header>
          <Body id="chat-window">
            {props.chatArray.map((val, index) =>
            
              val[3] === props.myUserId ? (
                <ChatMsgRight
                  
                  name = {val[0]}
                  msg={val[1]}
                  time={moment(val[2]).format("LT")}
                  key={index}
                />
              ) : (
                <ChatMsgLeft
                  name = {val[0]}
                  msg={val[1]}
                  time={moment(val[2]).format("LT")}
                  key={index}
                />
              )
            )}
          </Body>
          <Footer>
            <AdjustableSpacer height="16px" />
            <TypeBoxContainer>
              <TypeBox
                rows={2}
                maxlength="4"
                cols="40"
                wrap="off"
                placeholder="Type a message..."
                ref={typeBoxRef}
                id="txtArea"
                onKeyPress={handleKeyPress}
              />
              <SendBtn
                src="/images/components/ChatBox/Send Button.svg"
                onClick={handleSendMessage}
                id="sendBtn"
                ref={btnRef}
              ></SendBtn>
            </TypeBoxContainer>
            <AdjustableSpacer height="16px" />
          </Footer>
        </ChatBoxContainer>
      ) : null}
    </>
  );
};

export default ChatBox;
