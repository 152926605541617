import React from 'react';

import {
    ButtonContainer,
    ButtonText
} from './styles.js';

const PrimaryButton = ( props ) => {
    return (
        <ButtonContainer onClick={props.onClick} disabled={!props.active}>
        {/* Disabled prop disables the button as well as passing props.disabled to the stylesheet for style differences */}
            <ButtonText> {props.children} </ButtonText>
        </ButtonContainer>
    )
}

export default PrimaryButton;