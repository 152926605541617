import React, { useRef, useEffect, useState } from "react";
import AdjustableSpacer from "../AdjustableSpacer";
import { useHistory } from "react-router-dom";
import {
  FirstContentBox,
  EmergencyOrContactText,
  FirstOuterBox,
} from "./styles";



import { signOut } from "supertokens-auth-react/recipe/session";
import { useSessionContext } from 'supertokens-auth-react/recipe/session';


const ProfileMenu = (props) => {
  const history = useHistory();
  let { doesSessionExist } = useSessionContext();
  console.log(`does ST session exist: `, doesSessionExist);

    // Detects clicks outside of the ref passed.
    // Ref should be attached to the outermost container.
    function useOutsideClickDetector(ref) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            props.closeMenu();
          }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [ref]);
    }

    const wrapperRef = useRef();

    useOutsideClickDetector(wrapperRef);

    const [logoutStatus, changeLogoutStatus] = useState(false);

    const  logout = async ()  => {
      let res = await signOut(); 
      console.log(`ST resFrom signout`, res)
      history.push(`/login`)
      // changeLogoutStatus(prev => !prev);

    }
    

    // const doesSessionExist = async () => {
    //     if (useSessionContext()) {
    //         console.log(`sessionExists!!`)
    //     } else {
    //       console.log(`session does not exist`)   
    //       history.push('/');  
    //     }
    // }

    // useEffect(() => {
    //   console.log(`logoutStatus fired`, logoutStatus)
    //     doesSessionExist();
    // }, [logoutStatus])


  return (
    <FirstOuterBox ref={wrapperRef}>
      <FirstContentBox>
        <EmergencyOrContactText onClick={() => history.push("/settings")}>
          Settings
        </EmergencyOrContactText>
        <AdjustableSpacer height="20px;" />
        <EmergencyOrContactText onClick={() => logout()}>
          Logout
        </EmergencyOrContactText>
      </FirstContentBox>
    </FirstOuterBox>
  );
};

export default ProfileMenu;
