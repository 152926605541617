import styled from "styled-components";

export const MarginBox = styled.div`

  width: 369px;
  height: ${(props) => (props.height ? props.height : "218px")};

  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Up - Height >111 */
  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;

  @media (min-width: 1599px) {
    width: 450px;
  }
    /* padding: 5%; */
    /* border: 1px solid red; */
    display: flex;
`;

export const Title = styled.span`
  /* Desktop/Header/3 */
  font-family: Syne;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
  

  margin-bottom: 5%;
`;

export const Content = styled.div`
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5%;
  flex-grow: 1;
`;

export const ProfilePic = styled.div`
  background-image: url(${(props) => props.pic});
  width: 68px;
  height: 68px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin-right: 5%;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: row;
  display: ${(props) => props.display};
  margin-bottom: 6%;
  margin-top: 3%;

`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Name = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  color: #333333;
`;

export const Pronoun = styled.span`
  font-family: Syne;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  color: #333333;

`;

export const Location = styled.span`
  /* Mobile/Body/2 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 123.2%;

  /* or 17px */
  letter-spacing: 0.3px;

  color: #333333;
`;

export const ButtonStrip = styled.div`
  display: flex;
  flex-direction: row;
  display: ${(props) => props.display};
  align-self: center;
  /* justify-content: space-evenly; */
`;

export const ContactButton = styled.div`
  /* Buttons/Primary */
  background: linear-gradient(
      303.64deg,
      rgba(255, 255, 255, 0.4) 16.13%,
      rgba(0, 0, 0, 0.4) 132.9%
    ),
    linear-gradient(326.75deg, #6adcb3 -14.42%, #6accdc 99.59%);
  background-blend-mode: soft-light, normal;

  /* Buttons/Primary Small /Default */
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(202, 223, 219, 0.7);
  border-radius: 75px;
  width: 130px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;

  opacity: 0.33; // For disabled appearance

  /* &:hover{
        cursor:pointer;

        background: linear-gradient(342.94deg, rgba(255, 255, 255, 0.4) -182.89%, rgba(0, 0, 0, 0.4) 132.93%), linear-gradient(323.71deg, #6ADCB3 -41.08%, #6ACCDC 96.31%);
        background-blend-mode: soft-light, normal;

        box-shadow: -4px -4px 10px #FFFFFF, 0px 8px 14px rgba(106, 170, 163, 0.35), inset 5px 5px 14px 8px rgba(96, 183, 198, 0.5);
        border-radius: 75px;
    } */
`;

export const ViewDetailsButton = styled.div`
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Buttons/Secondary& Icon Small/Default */
  box-shadow: -2px -3px 10px #ffffff, 0px 3px 10px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 75px;
  width: 130px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
    /* White Elements/Desktop White Hover <50 */
    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #e3e8f0;

    /* Buttons/Secondary& Icon Small/Hover */
    box-shadow: -2px -8px 10px #ffffff, 0px 6px 14px rgba(202, 208, 223, 0.85),
      inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    border-radius: 75px;
  }
`;

export const ContactText = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  color: #fefefe;

  mix-blend-mode: normal;
  &:hover {
    cursor: default;
  }
`;

export const DetailsText = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  /* Gray / 1 */
  color: #333333;
`;

export const Working = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: 0.3px;
  width: 306px;

  margin-left: 4px;

  color: #333333;
  display: ${(props) => props.display};
  margin-bottom: 10%;
`;
