import styled from "styled-components";

export const ChatMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  padding-right: 16px;
  box-sizing: border-box;
`;

export const MsgBox = styled.div`
  /* Rectangle 105 */
  min-width: 50px;
  max-width: 245px;
  min-height: 40px;
  padding: 12px 10px;
  box-sizing: border-box;
  word-break: break-all;

  /* Gradients/Light Blue Purple */

  background: linear-gradient(180deg, #598ef5 0%, #79a4fb 100%);
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 14px rgba(161, 169, 199, 0.55);
  border-radius: 15px 15px 0px 15px;

  /* Hi Dr. Smith! See yo */

  /* Mobile/Body/3 */

  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;
  /* identical to box height, or 16px */

  letter-spacing: 0.3px;

  color: #fbfbfb;
`;

export const Time = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  text-align: right;

  /* Gray / 3 */

  color: #a2aecb;
`;
