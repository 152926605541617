import styled from "styled-components";

export const OuterBox = styled.div`
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Desktop White/Up - Height >111 */

  box-shadow: -11px -11px 22px #ffffff, 8px 8px 26px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
  border-radius: 16px;

  width: 369px;
  height: ${(props) =>
    props.stage === 1 ? "157px;" : props.stage === 2 ? "304px;" : "133px;"};

  display: flex;
  justify-content: center;
  @media (min-width: 1599px) {
    width: 450px;
  }
`;

export const Body = styled.div`
  padding: 25px;
`;

export const Stage2Body = styled.div``;

export const Looking = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  /* Gray / 1 */
  color: #333333;
`;

export const WouldYouLike = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  /* Gray / 1 */
  color: #333333;
`;

export const RequestBtn = styled.div`
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;
  /* Buttons/Secondary& Icon Small/Default */
  padding: 8px 24px;
  box-shadow: -2px -3px 10px #ffffff, 0px 3px 10px rgba(202, 208, 223, 0.85),
    inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
  border-radius: 75px;

  text-align: center;
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Gray / 1 */

  color: #333333;
  &:hover {
    background: linear-gradient(
        329.58deg,
        rgba(255, 255, 255, 0.05) 9.56%,
        rgba(92, 112, 162, 0.05) 87.95%
      ),
      #e3e8f0;
    /* Buttons/Secondary& Icon Small/Hover */

    box-shadow: -2px -8px 10px #ffffff, 0px 6px 14px rgba(202, 208, 223, 0.85),
      inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    border-radius: 75px;
    cursor: pointer;
  }
`;

export const RecievedRequest = styled.div`
  background: linear-gradient(
      321.07deg,
      rgba(0, 0, 0, 0.6) 1.82%,
      rgba(255, 255, 255, 0) 98.83%
    ),
    #618cf1;
  background-blend-mode: soft-light, normal;
  box-shadow: -12px -12px 13px #fafbff, 6px 6px 18px rgba(117, 146, 220, 0.44);
  border-radius: 8px;

  width: 319px;
  height: 54px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  /* Flat / White */

  color: #fafafa;
`;
