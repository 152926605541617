import styled from "styled-components";
// import dropdownArrow from '/images/selectBoxWithLabel/Dropdown Arrow.png'

export const LabelText = styled.div`
  /* Desktop/Body/Label */

  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;

  /* Gray / 1 */

  color: #333333;

  mix-blend-mode: normal;

  margin-bottom: 10px;
`;

export const LabelAndInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

export const SelectBox = styled.select`
  /* Rectangle 42 */
  height: 40px;
  width: 100%;
  // width: ${(props) => props.width || "204px"};

  padding-left: 14px;
  width: 100%;


`;

export const Option = styled.option`

`;
