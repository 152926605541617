import styled from "styled-components";
export const DateTimeBox = styled.div`
  display: flex;
  flex-direction: row;
`;

export const MarginBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 441px;
  height: 204px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White Card Stroke */
  border: 1px solid #f1f1f1;
  box-sizing: border-box;

  /* Cards/Cool White Card 45-85 */
  box-shadow: -5px -5px 10px #fafbff, 5px 5px 10px rgba(202, 208, 223, 0.85);
  border-radius: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const DayCircle = styled.div`
  width: 38px;
  height: 38px;
  /* White Elements/Default */
  background: linear-gradient(
      329.58deg,
      rgba(255, 255, 255, 0.05) 9.56%,
      rgba(92, 112, 162, 0.05) 87.95%
    ),
    #f6f6f6;

  /* Desktop White/Down - White Element 35-60 */
  box-shadow: inset -2.5px -2.5px 5px #fafbff,
    inset 2.5px 2.5px 8px rgba(202, 208, 223, 0.5);
  border-radius: 52px;
  transform: rotate(-0.2deg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Day = styled.div`
  font-family: Syne;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  /* identical to box height */
  display: flex;
  align-items: flex-end;

  /* Flat/Red */
  color: #e73144;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  /* width : 220px; */
`;

export const Date = styled.span`
  /* Mobile/Button/1 */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* display: flex;
    align-items: center; */

  /* Flat/Red */
  color: #e73144;
  white-space: nowrap;
  /* width: 212px; */
`;

export const Time = styled.span`
  /* Mobile/Body/3 */
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 123.2%;

  letter-spacing: 0.3px;

  /* Flat/Red */
  color: #e73144;
`;

export const X = styled.div`
  width: 26px;
  height: 26px;
  background-image: url("images/components/Meet/X.svg");
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BackButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 38px;
  &:hover {
    /* Buttons/Secondary& Icon Small/Default */
    box-shadow: inset 0px 0px 2px 1px rgba(255, 255, 255, 0.5);
    filter: drop-shadow(-2px -3px 10px #ffffff)
      drop-shadow(0px 3px 10px rgba(202, 208, 223, 0.85));
    cursor: pointer;
    border-radius: 16px;
  }
`;
export const Warning = styled.div`
  /* Desktop/Body/Label */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 398px;

  /* Gray / 1 */
  color: #333333;
`;

export const CancelButton = styled.div`
  width: 238px;
  height: 30px;

  background: linear-gradient(218.09deg, #fa9b94 -20.54%, #e15160 113.32%);
  box-shadow: 0px 3px 10px rgba(106, 204, 220, 0.3), -5px -5px 10px #ffffff,
    3px 3px 12px rgba(239, 125, 125, 0.7);
  border-radius: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;

  &:hover {
    background: linear-gradient(
      191.37deg,
      #fa9b94 -31.78%,
      #e25563 47.49%,
      #e15160 101.05%
    );
    box-shadow: 0px 3px 10px rgba(213, 80, 94, 0.33), -5px -5px 10px #ffffff,
      -3px -3px 12px #f5f5f5;
    border-radius: 75px;
    cursor: pointer;
  }
`;

export const CancelText = styled.div`
  /* Desktop / Button / Secondary */
  font-family: Syne;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  text-align: center;

  color: #fefefe;
`;
