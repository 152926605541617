import React, { useState } from "react";
import PageWrapper from "../PageWrapper";
import {
  FlexContainer,
  ICard,
  BG
} from "./styles";

import { useHistory } from "react-router-dom";

import MyTherapist from "../../components/MyTherapist";
import RequestNewTherapist from "../../components/RequestNewTherapist";
import IntroductoryVideos from "../../components/IntroductoryVideos";
import AdjustableSpacer from "../../components/AdjustableSpacer";


import introVideoArray from "../../data/introVideoArray";
import useStore from "../../utils/Zustand/store";
import { sendEmail } from "../../utils/UserAppApi/emailSenders";
const MeetMT = () => {
  let history = useHistory();

  const susie = useStore((state) => state.profile);

  const profileId = useStore((state) => state.profileId);

  const therapist = useStore((state) => state.therapist);

  const meetOptionHandler = () => history.push("/dashboard");

  const meetCTOptionHandler = () => history.push("/meetCT");

  const tab = "meetMT";

  //add change therapist request
  const requestNewTherapist = useStore((state) => state.requestNewTherapist);

  //const to check if new therapist has alreayd been requested
  const newTherapistRequested = useStore(
    (state) => state.newTherapistRequested
  );

  const sendRequestMessage = (message) => {
    requestNewTherapist(message);

    const emailContent = {
      subject: `New Therapist Request`,
      userID: profileId,
      fullName: susie.firstName + " " + susie.lastName,
      topic: "New therapist request",
      message: message,
      userEmail: susie.email,
      userPhone: susie.phoneNumber,
    };

    sendEmail(emailContent);
    console.log("Done");
  };

  // Description modal handling
  const [modalShown, setModalShown] = useState(false);
  const [currentType, setCurrentType] = useState("");

  const openModal = (type) => {
    console.log("Clicked")
    setCurrentType(type);
    setModalShown(true);
  }

  const closeModal = () => {
    setCurrentType("")
    setModalShown(false)
  }

  return (
        <>
        {modalShown && <BG onClick={closeModal} />}

        <PageWrapper tab = {tab} meetOptionHandler = {meetOptionHandler}>
          <FlexContainer>
            <ICard>
              <MyTherapist
                {...therapist}
                currentType={currentType}
                openModal={openModal}
                modalShown={modalShown}
                closeModal={closeModal}
                handleContactButton={meetCTOptionHandler}
              />
            </ICard>
            <ICard>
              <IntroductoryVideos
                introVideoArray={introVideoArray}
                aboutMeURL={therapist.aboutMeURL}
                myInterestsURL={therapist.myInterestsURL}
                myFunFactURL={therapist.myFunFactURL}
              />
              <AdjustableSpacer height="34px" />
              <RequestNewTherapist
                sendRequestMessage={sendRequestMessage}
                newTherapistRequested={newTherapistRequested}
              />
            </ICard>
          </FlexContainer>
        </PageWrapper>
        </>
  );
};

export default MeetMT;
